<template>
  <div class="content">
    <b-breadcrumb>
      <b-breadcrumb-item active>Staff</b-breadcrumb-item>
    </b-breadcrumb>

    <div class="container-fluid">
      <div class="row">
        <div class="col-4">
          <base-input v-model="searchStaff" placeholder="Buscar miembro de staff"></base-input>
        </div>
        <div class="col-4">
          <select @change="getStaffByRol($event)" v-model="rolSelect" class="custom-select">
            <option value="0">Todos los roles</option>
            <option value="1">Staff Administrador</option>
            <option value="2">Staff Usuario</option>
            <!-- <option value="externo">Staff Usuario Externo</option> -->
          </select>
        </div>
        <div class="col-4">
          <router-link :to="{ path: '/dashboard/staff/addstaff/' }">
            <b-button type="submit" class="btn-fill float-right orange-btn">
              Nuevo miembro
            </b-button>
          </router-link>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <card class="strpied-tabled-with-hover" body-classes="table-full-width table-responsive">

            <b-modal no-close-on-backdrop centered ref="modal-confirm-dialog" hide-footer id="modal-confirm-dialog">
              <h4 class="text-center">¿Seguro que quiere eliminar al staff?</h4>
              <h5 class="text-center">Se eliminarán todos los datos.</h5>
              <b-button class="mt-3" variant="danger" @click="$bvModal.hide('modal-confirm-dialog')">Cancelar</b-button>
              <b-button class="mt-3 float-right" variant="primary" @click="deleteStaff()">OK</b-button>
            </b-modal>

            <div class="text-center">
              <b-table :fields="fields" :items="staff" hover>
                <template #cell(index)="{ index }">
                  {{ getIndex(index + 1) }}
                </template>
                <template #cell(acciones)="{ item }">
                  <router-link :to="{ path: '/dashboard/staff/editstaff/' + item.id }">
                    <b-button variant="warning" size="sm" v-b-tooltip.hover title="Editar" class="editBtn">
                      <b-icon-pencil />
                    </b-button>
                  </router-link>

                  <b-button variant="danger" @click="predeleteStaff(item.id)" size="sm" v-b-tooltip.hover
                    title="Eliminar">
                    <b-icon-trash />
                  </b-button>
                </template>
              </b-table>
            </div>

            <div class="d-flex justify-content-between pages">
              <b-pagination v-model="currentPage" :per-page="perPage" :total-rows="totalStaff" />
              <span>Mostrando {{ staff.length }} registros de {{ totalStaff }}</span>
            </div>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Card from "src/components/Cards/Card.vue";

import { StaffApi, RolesApi } from "@/api";
import Storage from "@/utils/storage";

const storage = new Storage();

export default {
  name: "Staff",

  data: () => ({
    loading: false,
    currentPage: 1,
    perPage: 10,
    // totalItems: 0,
    totalStaff: 0,
    searchStaff: null,
    rolSearch: null,
    rolSelect: null,
    // externoSearch: null,

    roles: [],
    staff: [],

    currentStaffId: null
  }),

  computed: {
    fields() {
      return [
        { key: "index", label: "", class: "text-center" },
        { key: "nombre", label: "Nombre", class: "text-center" },
        { key: "paterno", label: "Paterno", class: "text-center" },
        { key: "materno", label: "Materno", class: "text-center" },
        { key: "email", label: "Email", class: "text-center" },
        { key: "rol", label: "Rol", class: "text-center" },
        { key: "acciones", label: "Acciones", class: "text-center" }
      ];
    }
  },

  watch: {
    currentPage: "getStaff",
    searchStaff: "getStaff"
  },

  components: {
    Card
  },

  created() {
    // this.getRoles(); ESTO ES PARA cuando se tenga división de admin y users
    this.getStaff();
  },

  methods: {
    getIndex(index) {
      return index + (this.currentPage - 1) * this.perPage;
    },

    async getStaff() {
      try {
        this.loading = true;

        const corredurias_id = storage.getItem("corredurias_id");
        const {
          odata_page: page,
          odata_total: total,
          odata_perpage: perPage,
          value
        } = await StaffApi.list({
          page: this.currentPage,
          data: this.searchStaff,
          registersPerPage: this.perPage,
          rol: this.rolSearch,
          corredurias_id: corredurias_id
          // usuario: this.usuario,
        });
        // this.clientes = value;
        this.currentPage = page;
        this.totalStaff = total;

        await this.getRoles();

        for (let i = 0; i < value.length; i++) {
          var role_id = value[i].roles_id;
          var obj = this.roles.find(o => o.id === role_id);

          // if (value[i].externo == 1) {
          //   value[i].rol = "Staff Usuario Externo";
          // } else {
          //   value[i].rol = obj.rol;
          // }
        }

        this.staff = value;
      } catch (error) {
      } finally {
        this.loading = false;
      }
    },

    async getStaffByRol(event) {
      let value = event.target.value;
      this.rolSearch = value;

      if (value == 0) {
        this.rolSearch = null;
        // this.externoSearch = null;
      } else if (value == 1) {
        // this.externoSearch = null;
      } else if (value == 2) {
        // this.externoSearch = 0;
      }
      //  else if (value === "externo") {
      //   this.rolSearch = 2;
      //   // this.externoSearch = 1;
      // }
      this.getStaff();
    },

    async getRoles() {
      try {
        const { value } = await RolesApi.list({
          page: 0,
          registersPerPage: 0
        });
        this.roles = value;
      } catch (error) {
      }
    },

    predeleteStaff(id) {
      this.currentStaffId = id;
      this.$refs["modal-confirm-dialog"].show();
    },

    async deleteStaff() {
      try {
        this.loading = true;

        const id = this.currentStaffId;
        await StaffApi.delete(id);
        const index = this.staff.findIndex(o => o.id === id);
        this.staff.splice(index, 1);
        this.totalStaff--;

        this.$toast.success("Staff eliminado", {
          timeout: 4000, //false,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true
        });
      } catch (error) {
        this.$toast.error("Sucedió un error, intente más tarde", {
          timeout: 4000, //false,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true
        });
      } finally {
        this.loading = false;

        this.$refs["modal-confirm-dialog"].hide();
      }
    }
  }
};
</script>
