<template>
  <div>
    <TopNavbar />
    <DescriptionServices :datos="datos" />
    <Footer />
  </div>
</template>

<script>
import Datos from "@/data/information.json";
import DescriptionServices from "./DescriptionServices.vue";
import Footer from "src/layout/ContentFooter.vue";
import TopNavbar from "src/layout/TopNavbar.vue";

import config from "@/config";

export default {
  name: "Information",

  components: {
    TopNavbar,
    Footer,
    DescriptionServices
  },

  data() {
    return {
      datos: {}
    }
  },

  mounted() {
    this.loadData();
  },

  watch: {
    '$route.params.type': 'loadData'
  },

  methods: {
    loadData() {
      const type = this.$route.params.type;
      this.datos = Datos[type] || {};
      this.datos.video = config.STG_BASE_URL + this.datos.video + config.STG_ACCESS_KEY;
    }
  }
} 
</script>