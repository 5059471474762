<template>
  <div class="container">
    <h4 class="cardD">Estatus de Documentos</h4>
    
    <div class="mt-4">
      <ValidationProvider name="estatus" rules="required" v-slot="{ errors }"
        :custom-messages="{ required: 'El estatus de documentos es obligatorio' }">
        <label>Estatus</label>
        <select v-model="cotejo.estatusDocumentos" class="custom-select">
          <option value="ok">OK. Se puede continuar con el proceso.</option>
          <option value="no ok">No OK. Ponerse en contacto con el cliente.</option>
          <option value="pago">OK. Requiere pago complementario.</option>
        </select>
        <span class="cardErrors">{{ errors[0] }}</span>
      </ValidationProvider>
    </div>

    <div class="mt-4">
      <div class="">
        <b-button type="submit" class="btn-fill orange-btn bottom2" size="m"
          :disabled="[18, 19, 20, 21, 74, 75, 76].includes(cotejo.id_estatus)">Continuar</b-button>
      </div>
    </div>
  </div>
</template>

<script>
export default ({
  name: "EstatusDocumentos",

  props: {
    cotejo: { type: Object }
  },
})
</script>

<style scoped>
.bottom1 {
  float: left;
}

.green-btn {
  background-color: green;
}

.container>>>.validacionDatosLabel {
  position: absolute;
  margin-left: 30px;
  top: 25%;
  max-width: 1500px;
}
</style>
