<template>
  <div class="container">
    <h4 class="cardD">Generación de documentos</h4>
    <card class="blue">
      <i class="fa fa-info"></i>
      Aquí van instrucciones.
      <p></p>
      <i class="fa fa-play"></i>
      Links a videos.
      <p></p>
      <i class="fa fa-question"></i>
      Datos de contacta a un asesor.
    </card>

    <div class="container">
      <div v-if="loading">
        <div id="loader">
          <font-awesome-icon icon="spinner" spin />
        </div>
      </div>
    </div>

    <b-modal no-close-on-backdrop centered ref="modal-confirm-dialog" hide-footer id="modal-confirm-dialog">
      <h4 class="text-center">¿Seguro que quiere eliminar el documento?</h4>
      <h5 class="text-center"></h5>
      <b-button class="mt-3" variant="danger" @click="$bvModal.hide('modal-confirm-dialog')">Cancelar</b-button>
      <b-button class="mt-3 float-right" variant="primary" @click="deleteDocumento">OK</b-button>
    </b-modal>

    <b-modal no-close-on-backdrop centered ref="documento-modal" title="" hide-footer id="modalC" size="xl">
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(postDocumento)">
          <ModalDocumentos :currentDocumento="currentDocumento" :options="options" @submit.prevent />
        </form>
      </ValidationObserver>
    </b-modal>

    <b-modal no-close-on-backdrop centered ref="documento-modal-preview-img" title="" hide-footer id="modaldi" size="xl">
      <ModalPreviewImg :currentDocumento="currentDocumento" />
    </b-modal>
    <b-modal no-close-on-backdrop centered ref="documento-modal-preview-pdf" title="" hide-footer id="modaldf" size="xl">
      <ModalPreviewPDF :currentDocumento="currentDocumento" />
    </b-modal>
    <b-modal no-close-on-backdrop centered ref="documento-modal-preview-docx" title="" hide-footer id="modaldd" size="xl">
      <ModalPreviewDocx :currentDocumento="currentDocumento" />
    </b-modal>

    <h4 class="cardD mt-1">Póliza</h4>
    <div class="row">
      <div class="col-6">
        <b-button block variant="primary" class="btn-fill" size="m" @click="generaProyecto()"
          :disabled="!listoGenerarProyecto">
          Generar proyecto
        </b-button>
      </div>
      <div class="col-6">
        <b-button block variant="info" class="btn-fill" size="m" @click="descargaProyecto()">
          <!-- :disabled="formalizacion.proyecto_generado === null" -->
          <i class="fa fa-download"></i>
          Descargar proyecto
        </b-button>
      </div>
    </div>

    <div class="row mt-5">
      <div class="col-md-4">
        <b-button class="btn-fill" @click="addDocumento" variant="success"
          :hidden="formalizacion.documentos_proyecto.length >= 2">
          + Cargar Proyecto
        </b-button>
      </div>
    </div>

    <b-table hover :fields="fieldsProyecto" :items="formalizacion.documentos_proyecto">
      <template #cell(acciones)="{ item }">
        <b-button variant="warning" size="sm" v-b-tooltip.hover title="Editar" @click="editDocumento(item)"
          class="editBtn">
          <b-icon-pencil />
        </b-button>
        <b-button variant="primary" size="sm" v-b-tooltip.hover title="Descargar" @click="descargaDocumento(item)"
          class="editBtn">
          <b-icon-download />
        </b-button>
        <b-button variant="danger" size="sm" v-b-tooltip.hover title="Editar" @click="predeleteDocumento(item)"
          class="editBtn">
          <!-- :disabled="constitutiva.disabledDocumentos" -->

          <b-icon-trash />
        </b-button>
      </template>
    </b-table>

    <div class="mt-4">
      <div class="">
        <b-button type="submit" class="btn-fill orange-btn bottom2 float-right" size="m">Guardar
          <!-- :disabled="constitutiva.disabledDocumentos" -->
        </b-button>
      </div>
    </div>

    <h4 class="cardD mt-1 bottom3">
      Enviar Póliza a firma digital
    </h4>
    <label class="leyenda mt-1" v-if="!listoEnvioProyecto">
      Debe estar cargado el proyecto en Word y en PDF para poder enviarlo a los firmantes para su firma digital.
    </label>
    <div class="mt-3">
      <div v-if="listoEnvioProyecto">
        <b-button block variant="success" class="btn-fill" size="m" :disabled="disabledSendProyectoFad"
          @click="enviarProyectoFad()">
          Enviar Póliza a firma digital
        </b-button>
        <label class="leyenda mt-1" v-if="disabledSendKycsFad">
          Si la opción está deshabilitada, es porque ya se enviaron a Firma
        </label>
      </div>
    </div>

    <h4 class="cardD bottom3">Generar KYCs</h4>
    <b-button block variant="success" class="btn-fill mb-3" size="m" @click="generaKycs()">
      <!-- :disabled="constitutiva.disbaledGeneraDocs" -->
      Generar
    </b-button>
    <b-table hover :fields="fields" :items="formalizacion.documentos_kycs">
      <template #cell(acciones)="{ item }">
        <b-button variant="primary" size="sm" v-b-tooltip.hover title="Vista previa" @click="previewDocumento(item)"
          class="editBtn">
          <!-- :disabled="constitutiva.disbaledGeneraDocs" -->

          <b-icon-eye />
        </b-button>
      </template>
    </b-table>

    <h4 class="cardD bottom3">Enviar KYCs a firma digital</h4>
    <label class="leyenda mt-1" v-if="!listoGenerar">
      Todos los formularios deberán estar validados y se deberá tener cargada la Póliza en Word y en PDF para poder
      enviarlos a los firmantes para su firma digital.
    </label>

    <div class="mt-3">
      <div v-if="listoGenerar">
        <b-button block variant="success" class="btn-fill" size="m" :disabled="disabledSendKycsFad"
          @click="enviarKycsFad()">
          Enviar KYCs a firma digital
        </b-button>
        <label class="leyenda mt-1" v-if="disabledSendKycsFad">
          Si la opción está deshabilitada, es porque ya se enviaron a Firma
        </label>
      </div>
      <div v-else>
        <div class="bg-danger text-light" id="aviso">
          Contacte a cliente para obtener más datos
        </div>
      </div>
    </div>

    <div>
      <div hidden v-for="item in formalizacion.firmantes" :key="item.id">
        <KycTemplate :id="item.id" :currentCompareciente="item" :beneficiario_pld="2" :beneficiario_cff="4" />
      </div>
    </div>
  </div>
</template>

<script>
import ModalDocumentos from "@/views/clients/procesos/constitucion/entregadocumentos/ModalDocumentos.vue";
import ModalPreviewImg from "@/views/clients/procesos/constitucion/entregadocumentos/ModalPreviewImg.vue";
import ModalPreviewPDF from "@/views/clients/procesos/constitucion/entregadocumentos/ModalPreviewPDF.vue";
import ModalPreviewDocx from "@/views/clients/procesos/constitucion/entregadocumentos/ModalPreviewDocx.vue";

import KycTemplate from "../constitucionsociedades/KycTemplate.vue";

import { FuncionesApi } from "@/api";

export default {
  name: "TabGeneraDocumento",

  props: {
    formalizacion: { type: Object }
  },

  created() {
    // this.checkValidaciones();
    // this.colocaEstatusKYCs();
    // this.checkProyectoGenera();
  },

  data() {
    return {
      listoGenerarProyecto: false,
      listoGenerar: false,
      listoEnvioProyecto: false,

      currentDocumento: null,
      currentDocumentoId: null,

      currentCompareciente: null,
      disabledSendKycsFad: false,
      disabledSendProyectoFad: false,

      loading: false,

      style_pdf:
        `<style>
        .new-page {
            page-break-before: always;
          }
          .logo {
            width: auto;
            height: 100px;
            float: right;
          }
          .tabla {
            width: 100%;
            font-size: 15px;
            font-family: Arial, Helvetica, sans-serif;
            border-collapse: separate;
            border-spacing: 0 1em;
          }
          .section {
            background-color: #4BACC6;
            color: white;
            text-align: center;
            height: 40px;
            font-weight: bolder;
          }
          .firstline {
            border: 5px solid white;
          }
          .title {
            font-size: 12px;
            text-align: left;
            width: 20%;
            font-weight: bold;
          }
          .select {
            text-align: left;
            width: 5%;
          }
          .circle {
            border-radius: 100%;
            height: 15px;
            width: 15px;
            border: 2px solid #4BACC6;
            margin-bottom: 0px;
          }
          .filledcircle {
            border-radius: 100%;
            height: 15px;
            width: 15px;
            border: 2px solid #4BACC6;
            background-color: #4BACC6;
            margin-bottom: 0px;
          }
          .verifycheck {
            border: 2px solid #4BACC6;
            background-color: rgb(231, 238, 245);
            text-align: center;
          }
          .right-title {
            font-size: 12px;
            text-align: right;
            font-weight: bold;
          }
          .user {
            text-align: left;
            font-size: 12px;
            font-weight: lighter;
            border-bottom: solid 1px black;
            background-color: rgb(231, 238, 245);
          }
          .leyenda {
            font-size: 9px;
          }
      </style>`,

      options: [
        { text: "Póliza PDF", value: "Póliza PDF" },
        { text: "Póliza Word", value: "Póliza Word" }
      ],

      stgAccessKey: "?sv=2023-01-03&st=2024-02-09T17%3A04%3A42Z&se=2034-02-11T17%3A04%3A00Z&sr=c&sp=rl&sig=rq9SDEcZMPv%2FbgiTq9FRE66lHZvEAeeiMqytpyiH2sk%3D",
    };
  },

  components: {
    ModalDocumentos,
    ModalPreviewImg,
    ModalPreviewPDF,
    ModalPreviewDocx,
    KycTemplate,
  },

  computed: {
    fields() {
      let fields = [
        // { key: "index", label: "", class: "text-center" },
        { key: "nombreFile", label: "Nombre", class: "text-center" },
        { key: "tipo", label: "Tipo", class: "text-center" },
        { key: "estatus", label: "Estatus", class: "text-center" },
        { key: "acciones", label: "Acciones", class: "text-center" }
      ];
      return fields;
    },
    fieldsProyecto() {
      let fields = [
        // { key: "index", label: "", class: "text-center" },
        { key: "nombreFile", label: "Nombre", class: "text-center" },
        { key: "tipo", label: "Tipo", class: "text-center" },
        { key: "estatus", label: "Estatus", class: "text-center" },
        { key: "acciones", label: "Acciones", class: "text-center" }
      ];
      return fields;
    }
  },

  methods: {
    checkProyectoGenera() {
      //checar si ya se puede generar el proyecto
      //si ya todos forma estan en valido =1 y si denominacion ya tiene  numero_permiso
      // const index = this.constitutiva.denominaciones_sociales.findIndex(o => o.autorizada === 1);
      if (
        // index !== -1 &&
        this.constitutiva.validoSociedad &&
        this.constitutiva.validoAsamblea &&
        this.constitutiva.validoDelegado &&
        this.constitutiva.validoEntidad &&
        this.constitutiva.validoCotizacion &&
        this.constitutiva.validoComplementa &&
        this.constitutiva.validoDenominacion &&
        this.constitutiva.validoPagoComplementario &&
        this.constitutiva.validoGenera &&
        this.constitutiva.validoCita &&
        this.constitutiva.validoTerminado
      ) {
        this.listoGenerarProyecto = true;
      }
    },

    async checkValidaciones() {
      //si ya están en uno valido todos los anteriores - boton generear docs y enviar a firam
      //ademas de que ya hayan 2 docs en consitutivas.documentos_proyecto
      let count = 0;
      for (let i = 0; i < this.constitutiva.formularios.length; i++) {
        if (
          this.constitutiva.formularios[i].formulario != "EntregaDocumentos" &&
          this.constitutiva.formularios[i].formulario != "CitaFirma" &&
          this.constitutiva.formularios[i].formulario != "DocListo" &&
          this.constitutiva.formularios[i].formulario != "DenominacionSocial" &&
          this.constitutiva.formularios[i].formulario != "Expediente" &&
          this.constitutiva.formularios[i].formulario != "FirmaDigital"
        ) {
          if (this.constitutiva.formularios[i].valido != 1) {
            count++;
          }
        }
      }

      if (this.constitutiva.documentos_proyecto.length != 2) {
        count++;
      }
      if (count == 0) {
        this.listoGenerar = true;
      }

      //check validación de envío kycs a fad
      //buscar en constitutiva.fad_responses si algun objeto tiene el campo comparecientes_id != null
      if (this.constitutiva.fad_procesos.length > 0) {
        for (let r = 0; r < this.constitutiva.fad_procesos.length; r++) {
          if (this.constitutiva.fad_procesos[r].comparecientes_id != null) {
            this.disabledSendKycsFad = true;
          }
        }
      }

      //check validación de envío proyecto a fad
      if (this.constitutiva.documentos_proyecto.length == 2) {
        this.listoEnvioProyecto = true;
      }
    },

    async colocaEstatusKYCs() {
      //poner estatus en cada docs_kycs
      for (let i = 0; i < this.constitutiva.documentos_kycs.length; i++) {
        let nombreFile = this.constitutiva.documentos_kycs[i].nombreFile;
        //de nombreFile obtiene la string que está entre el guion bajo y el punto
        let nombre_completo = nombreFile.substring(
          nombreFile.lastIndexOf("_") + 1,
          nombreFile.lastIndexOf(".")
        );
        let index = await this.findIndexAccio(nombre_completo);
        let compar_id = this.constitutiva.accionistas[index].id;
        let indexFad = this.constitutiva.fad_procesos.findIndex(o => o.comparecientes_id === compar_id);

        if (indexFad != -1) {
          let estatus_id = this.constitutiva.fad_procesos[indexFad].cat_fad_responses_id;

          this.constitutiva.documentos_kycs[i].cat_fad_responses_id = estatus_id;

          //luego ya teniendo el cat_fad_responses_id, llamo a request que traiga name de este estatus y se lo pongo en .estatus
          let estatus_name = await FuncionesApi.getCatFadResponseRespuesta(estatus_id);
          this.constitutiva.documentos_kycs[i].estatus = estatus_name;

          var a = Object.assign({}, this.constitutiva.documentos_kycs[i]);
          this.constitutiva.documentos_kycs.splice([i], 1, a);
        } else {
          this.constitutiva.documentos_kycs[i].cat_fad_responses_id = null;
        }
      }

      //PONER ESTATUS EN PROYECYTO CONSTITUTIVA TAMBIEN
      let indexFad_proyecto = this.constitutiva.fad_procesos.findIndex(
        o => o.comparecientes_id === 0
      );
      let indexEstatusFadProyecto = this.constitutiva.fad_procesos[
        indexFad_proyecto
      ].cat_fad_responses_id;
      let estatus_name = await FuncionesApi.getCatFadResponseRespuesta(
        indexEstatusFadProyecto
      );
      let index_doc_proyecto = this.constitutiva.documentos_proyecto.findIndex(
        o => o.tipo === "Proyecto constitutiva PDF"
      );
      this.constitutiva.documentos_proyecto[
        index_doc_proyecto
      ].estatus = estatus_name;

      var a = Object.assign({}, this.constitutiva.documentos_proyecto[index_doc_proyecto]);
      this.constitutiva.documentos_proyecto.splice([index_doc_proyecto], 1, a);
    },

    async findIndexAccio(nombre_completo) {
      let index;
      let nombre_completo_nospace = nombre_completo.replaceAll(" ", "");
      this.constitutiva.accionistas.forEach(accio => {
        let nombre_accio = accio.nombreCompleto.replaceAll(" ", "");
        if (nombre_accio === nombre_completo_nospace) {
          index = this.constitutiva.accionistas.findIndex(
            o => o.id === accio.id
          );
        }
      });
      return index;
    },

    async generaProyecto() {
      this.loading = true;
      try {
        let data2 = {
          cat_servicios_id: 3,
          proceso_id: this.formalizacion.id
          // tipo_documento: this.constitutiva.tipo_societario
        };
        // const url_proyecto = await FuncionesApi.generaProyecto(data2);
        // this.formalizacion.proyecto_generado = {};
        // this.formalizacion.proyecto_generado.documento = url_proyecto;

        this.$toast.success("Proyecto generado, puede descargarlo", {
          timeout: 4000, //false,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true
        });
      } catch (error) {
        console.log(error);
        this.$toast.error("Sucedió un error, intente más tarde" + error, {
          timeout: 4000, //false,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true
        });
      }
      this.loading = false;
    },

    descargaProyecto() {
      const link = document.createElement("a");
      link.href =
        // this.constitutiva.proyecto_generado.documento +
        this.stgAccessKey;
      document.body.appendChild(link);
      link.click();
    },

    previewDocumento(item) {
      this.currentDocumento = item;

      let pretipoDocumento = item.nombreFile.split(".");
      let tipoDocumento = pretipoDocumento[pretipoDocumento.length - 1];

      if (tipoDocumento === "pdf") {
        this.$refs["documento-modal-preview-pdf"].show();
      }
      if (
        tipoDocumento === "jpg" ||
        tipoDocumento === "JPG" ||
        tipoDocumento === "jpeg" ||
        tipoDocumento === "png" ||
        tipoDocumento === "gif" ||
        tipoDocumento === "bmp" ||
        tipoDocumento === "webp" ||
        tipoDocumento === "svg" ||
        tipoDocumento === "tiff" ||
        tipoDocumento === "avif"
      ) {
        this.$refs["documento-modal-preview-img"].show();
      }

      if (tipoDocumento === "docx") {
        this.$refs["documento-modal-preview-docx"].show();
      }
    },

    async generaKycs() {
      try {
        this.loading = true;
        const comparecientes_d = [];

        for (let i = 0; i < this.formalizacion.firmantes.length; i++) {
          const id_div = this.formalizacion.firmantes[i].id;
          const html = document.getElementById(id_div).innerHTML;
          const html_full = this.style_pdf + html;
          const html_total =
            `<!DOCTYPE html>
            <html>
            <head>
            <meta charset="utf-8">
            </head>
            <body>
            ${html_full}
            </body>
            </html>`;

          // console.log(html_total);
          const buffer = Buffer.from(html_total);
          const buffer_str = buffer.toString("base64");
          // console.log(buffer_str);

          const obj = {
            comparecientes_id: this.formalizacion.accionistas[i].id,
            html: buffer_str
          };

          comparecientes_d.push(obj);
        }

        const data2 = {
          proceso_id: this.formalizacion.id,
          cat_servicios_id: 3,
          comparecientes: comparecientes_d
        };

        const documentos_kycs = await FuncionesApi.generaKyc(data2);
        // let documentos_kycs = [];
        for (let r = 0; r < documentos_kycs.length; r++) {
          documentos_kycs[r].nombreFile = documentos_kycs[r].documento.substring(documentos_kycs[r].documento.lastIndexOf("/") + 1);
          documentos_kycs[r].tipo = "KYC";
        }

        this.formalizacion.documentos_kycs = documentos_kycs;

        this.$toast.success("KYCs generados", {
          timeout: 4000, //false,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true
        });
      } catch (error) {
        console.log(error);
        this.$toast.error("Sucedió un error, intente más tarde", {
          timeout: 4000, //false,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true
        });
      } finally {
        this.loading = false;
      }
    },

    async enviarKycsFad() {
      this.loading = true;
      try {
        let data2 = {
          proceso_id: this.formalizacion.id,
          cat_servicios_id: 3
        };
        // let resut = await FuncionesApi.sendKycsFad(data2);

        this.$toast.success("KYCs enviados a firma", {
          timeout: 4000, //false,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true
        });

        //deshabilita btn para no hacer 2 veces la request
        this.disabledSendKycsFad = true;

        // const { fad_procesos } = await ConstitucionSociedadesApi.detail(
        //   this.constitutiva.id
        // );
        // this.constitutiva.fad_procesos = fad_procesos;

        this.formalizacion.documentos_kycs.forEach(element => {
          element.estatus = "STARTED";
        });
      } catch (error) {
        console.log(error);
        this.$toast.error("Sucedió un error, intente más tarde", {
          timeout: 4000, //false,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true
        });
      }
      this.loading = false;
    },

    async enviarProyectoFad() {
      this.loading = true;
      try {
        let data2 = {
          proceso_id: this.formalizacion.id,
          cat_servicios_id: 3
        };
        // let result = await FuncionesApi.sendProyectoConstitutivaFad(data2);

        this.$toast.success("Proyecto enviado a firma digital", {
          timeout: 4000, //false,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true
        });

        //deshabilita btn para no hacer 2 veces la request
        this.disabledSendProyectoFad = true;

        // let doc = this.formalizacion.documentos_proyecto.find(
        //   o => o.tipo === "Proyecto constitutiva PDF"
        // );
        // doc.estatus = "STARTED";
      } catch (error) {
        console.log(error);
        this.$toast.error("Sucedió un error, intente más tarde", {
          timeout: 4000, //false,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true
        });
      }
      this.loading = false;
    },

    addDocumento() {
      this.$refs["documento-modal"].show();
      this.currentDocumento = {};
    },

    descargaDocumento(item) {
      const link = document.createElement("a");
      link.href = item.documento + this.stgAccessKey;
      document.body.appendChild(link);
      link.click();
    },

    async postDocumento() {
      this.loading = true;
      await this.addFile();

      let cat_documentos_id = 0;
      if (this.currentDocumento.tipo == "Póliza PDF") {
        cat_documentos_id = 89;
      } else if (this.currentDocumento.tipo == "Póliza Word") {
        cat_documentos_id = 90;
      }

      if (this.currentDocumento.id === undefined) {
        let id = this.formalizacion.documentos_proyecto.length + 1;
        this.currentDocumento.id = id;
        this.currentDocumento.cat_documentos_id = cat_documentos_id;
        // this.currentDocumento.tipo="Proyecto";
        var a = Object.assign({}, this.currentDocumento);
        this.formalizacion.documentos_proyecto.push(a);
      } else if (this.currentDocumento.id != undefined) {
        const index = this.formalizacion.documentos_proyecto.findIndex(
          o => o.id === this.currentDocumento.id
        );
        this.currentDocumento.cat_documentos_id = cat_documentos_id;
        var a = Object.assign({}, this.currentDocumento);
        this.formalizacion.documentos_proyecto.splice([index], 1, a);
      }

      //quita opción de dropdown
      let index = this.options.findIndex(
        o => o.value === this.currentDocumento.tipo
      );
      this.options.splice(index, 1);

      this.$refs["documento-modal"].hide();
      this.loading = false;
    },

    async addFile() {
      this.loading = true;
      try {
        const formData = new FormData();
        this.currentDocumento.nombreFile = this.currentDocumento.file.name;
        formData.append("file", this.currentDocumento.file);
        const value = await FuncionesApi.file(
          this.formalizacion.id,
          "documentosFormalizaciones",
          formData,
          {}
        );
        this.currentDocumento.documento = value;
      } catch (error) {
        //
      }
      this.loading = false;
    },

    // previewDocumento(item) {
    //   this.currentDocumento = item;

    //   let pretipoDocumento = item.nombreFile.split(".");
    //   let tipoDocumento = pretipoDocumento[pretipoDocumento.length - 1];

    //   if (tipoDocumento === "pdf") {
    //     this.$refs["documento-modal-preview-pdf"].show();
    //   }
    //   if (
    //     tipoDocumento === "jpg" ||
    //     tipoDocumento === "jpeg" ||
    //     tipoDocumento === "png" ||
    //     tipoDocumento === "gif" ||
    //     tipoDocumento === "bmp" ||
    //     tipoDocumento === "webp" ||
    //     tipoDocumento === "svg" ||
    //     tipoDocumento === "tiff" ||
    //     tipoDocumento === "avif"
    //   ) {
    //     this.$refs["documento-modal-preview-img"].show();
    //   }
    // },

    editDocumento(documento) {
      this.$refs["documento-modal"].show();
      this.currentDocumento = documento;
    },

    predeleteDocumento(item) {
      this.currentDocumento = item;
      this.$refs["modal-confirm-dialog"].show();
    },

    async deleteDocumento() {
      // await this.deleteFile(this.currentDocumento.id);
      await this.deleteFile(this.formalizacion.id);

      let index2 = this.formalizacion.documentos_proyecto.findIndex(
        o => o.id === this.currentDocumentoId
      );
      this.formalizacion.documentos_proyecto.splice(index2, 1);

      //poner la opcion de options
      this.options.push({
        text: this.currentDocumento.tipo,
        value: this.currentDocumento.tipo
      });

      this.$refs["modal-confirm-dialog"].hide();
    },

    async deleteFile(id) {
      this.loading = true;
      var data = {
        url: this.currentDocumento.documento,
        id: this.formalizacion.id
      };
      try {
        await FuncionesApi.deleteFile("documentosCFormalizaciones", data, {});
      } catch (error) {
        console.log(error);
      }
      this.loading = false;
    }
  }
};
</script>

<style scoped>
.blue {
  background-color: #effbff;
  border-color: #effbff !important;
  text-align: justify;
}

.bottom3 {
  margin-top: 10% !important;
}

#aviso {
  text-align: center;
  padding: 8px 16px;
  border-radius: 0.25rem;
}
</style>
