<template>
  <div class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-3">
          <b-card class="cardServicio">
            <template slot="header">
              <h4 class="card-title">Constitución de sociedades</h4>
            </template>
            <div class="text-center long">
              <img src="/img/designed/constSocie.PNG" alt="" class="imgCard" />
            </div>
            <template slot="footer">
              <hr />
              <div class="stats">
                <router-link :to="{ name: 'Information', params: { type: 'constitucion-sociedades' } }">
                  Más información
                </router-link>
              </div>
            </template>
          </b-card>
        </div>
        <div class="col-md-3">
          <card class="cardServicio">
            <template slot="header">
              <h4 class="card-title">Formalización de actas</h4>
            </template>
            <div class="text-center long">
              <img src="/img/designed/formActas.PNG" alt="" class="imgCard" />
            </div>
            <template slot="footer">
              <hr />
              <div class="stats">
                <router-link :to="{ name: 'Information', params: { type: 'formalizacion-actas' } }">
                  Más información
                </router-link>
              </div>
            </template>
          </card>
        </div>
        <div class="col-md-3">
          <card class="cardServicio">
            <template slot="header">
              <h4 class="card-title">Notificaciones</h4>
            </template>
            <div class="text-center long">
              <img src="/img/designed/notifs.PNG" alt="" class="imgCard" />
            </div>
            <template slot="footer">
              <hr />
              <div class="stats">
                <router-link :to="{ name: 'Information', params: { type: 'notificaciones' } }">
                  Más información
                </router-link>
              </div>
            </template>
          </card>
        </div>
        <div class="col-md-3">
          <card class="cardServicio">
            <template slot="header">
              <h4 class="card-title">Copias certificadas</h4>
            </template>
            <div class="text-center long">
              <img src="/img/designed/cotejoDocs.PNG" alt="" class="imgCard" />
            </div>
            <template slot="footer">
              <hr />
              <div class="stats">
                <router-link :to="{ name: 'Information', params: { type: 'copias-certificadas' } }">
                  Más información
                </router-link>
              </div>
            </template>
          </card>
        </div>
      </div>

      <div class="row mb-5"></div>

      <!-- Segunda fila de servicios -->
      <div class="row">
        <div class="col-md-3">
          <card class="cardServicio">
            <template slot="header">
              <h4 class="card-title">Fes de hechos</h4>
            </template>
            <div class="text-center long">
              <img src="/img/designed/fesHechos.PNG" alt="" class="imgCard" />
            </div>
            <template slot="footer">
              <hr />
              <div class="stats">
                <!-- <router-link :to="{ name: 'Information', params: { type: 'fe-hechos' } }"> -->
                <router-link :to="{ path: '/dashboard/proximamente' }" class="route">
                  Más información
                </router-link>
              </div>
            </template>
          </card>
        </div>
        <div class="col-md-3">
          <card class="cardServicio">
            <template slot="header">
              <h4 class="card-title">Ratificación de firmas</h4>
            </template>
            <div class="text-center long">
              <img src="/img/designed/ratifFirmas.PNG" alt="" class="imgCard" />
            </div>
            <template slot="footer">
              <hr />
              <div class="stats">
                <router-link :to="{ name: 'Information', params: { type: 'ratificacion-firmas' } }">
                  Más información
                </router-link>
              </div>
            </template>
          </card>
        </div>
        <div class="col-md-3">
          <card class="cardServicio">
            <template slot="header">
              <h4 class="card-title">Firma Digital de Documentos</h4>
            </template>
            <div class="text-center long">
              <img src="/img/designed/ratifFirmasDigital.png" alt="" class="imgCard" />
            </div>
            <template slot="footer">
              <hr />
              <div class="stats">
                <router-link :to="{ name: 'Information', params: { type: 'ratificacion-firmas-digital' } }">
                  Más información
                </router-link>
              </div>
            </template>
          </card>
        </div>
        <div class="col-md-3">
          <card class="cardServicio">
            <template slot="header">
              <h4 class="card-title">Avalúos de mejoras</h4>
            </template>
            <div class="text-center long">
              <img src="/img/designed/avaluosMej.PNG" alt="" class="imgCard" />
            </div>
            <template slot="footer">
              <hr />
              <div class="stats">
                <!-- <router-link :to="{ name: 'Information', params: { type: 'avaluos' } }"> -->
                <router-link :to="{ path: '/dashboard/proximamente' }" class="route">
                  Más información
                </router-link>
              </div>
            </template>
          </card>
        </div>
        <div class="col-md-1"></div>
      </div>
    </div>
  </div>
</template>

<script>
import Card from "src/components/Cards/Card.vue";

export default {
  components: {
    Card
  }
};
</script>

<style>
.imgCard {
  max-width: 100%;
  max-height: 100%;
}

.cardServicio:hover {
  box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px,
    rgba(17, 17, 26, 0.05) 0px 8px 32px;
}
</style>
