<template>
  <div class="container">
    <h4 class="cardD">
      Información relacionada con la sociedad y el acta que se va a protocolarizar o formalizar
    </h4>

    <div class="row">
      <div class="col-6">
        <ValidationProvider name="tipo_acta" v-slot="{ errors }" :custom-messages="{
          required: 'El tipo de acta es obligatorio'
        }">
          <label for="">Tipo de acta</label>
          <select class="custom-select" v-model="formalizacion.tipo_acta" :disabled="formalizacion.disabledSociedad">
            <option value="Asamblea de Accionistas">Asamblea de Accionistas</option>
            <option value="Resoluciones Unánimes adoptadas fuera de asamblea (RUAs)">Resoluciones Unánimes adoptadas
              fuera de asamblea (RUAs)</option>
            <option value="Acta de sesión del Consejo de Administración">Acta de sesión del Consejo de Administración
            </option>
          </select>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>

      <div class="col-6">
        <div v-if="formalizacion.tipo_acta == 'Asamblea de Accionistas'">
          <ValidationProvider name="subtipo_acta" v-slot="{ errors }" :custom-messages="{
            required: 'La especificación de acta es obligatorio'
          }">
            <label for="">Especificación</label>
            <select class="custom-select" v-model="formalizacion.subtipo_acta"
              :disabled="formalizacion.disabledSociedad">
              <option value="General Ordinaria">General Ordinaria</option>
              <option value="General Extraordinaria">General Extraordinaria</option>
              <option value="Especial">Especial</option>
            </select>
            <span class="cardErrors">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>

        <div v-else-if="
          formalizacion.tipo_acta ==
          'Resoluciones Unánimes adoptadas fuera de asamblea (RUAs)'
        ">
          <ValidationProvider name="subtipo_acta" v-slot="{ errors }" :custom-messages="{
            required: 'La especificación de acta es obligatorio'
          }">
            <label for="">Especificación</label>
            <select class="custom-select" v-model="formalizacion.subtipo_acta"
              :disabled="formalizacion.disabledSociedad">
              <option value="Firmadas autógrafamente">Firmadas autógrafamente</option>
              <option value="Firmadas de forma electrónica">Firmadas de forma electrónica</option>
            </select>
            <span class="cardErrors">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
      </div>
    </div>

    <label class="mt-2 leyendaBlock" v-if="block" :disabled="formalizacion.disabledSociedad">
      <b>
        Para que esta opción pueda llevarse acabo, es necesario que se comunique con el personal de Correduría Digital
        para ser atendido de forma personalizada
      </b>
    </label>

    <div class="row mt-4">
      <div class="col-md-9">
        <ValidationProvider name="nombre_sociedad" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'El nombre de la sociedad es obligatorio'
        }">
          <base-input :disabled="formalizacion.disabledSociedad" type="text" label="Nombre de la sociedad"
            placeholder="Nombre de la sociedad" v-model="formalizacion.nombre_sociedad">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="col-3">
        <ValidationProvider name="tipo_societario" v-slot="{ errors }" :custom-messages="{
          required: 'El tipo de societario es obligatorio'
        }">
          <label for="">Tipo de societario</label>
          <select class="custom-select" v-model="formalizacion.tipo_societario"
            :disabled="formalizacion.disabledSociedad">
            <option value="S.A.">S.A.</option>
            <option value="S.A. de C.V.">S.A. de C.V.</option>
            <option value="S. de R.L.">S. de R.L.</option>
            <option value="S. de R.L. de C.V.">S. de R.L. de C.V.</option>
            <option value="S.A.P.I.">S.A.P.I.</option>
            <option value="S.A.P.I. de C.V.">S.A.P.I. de C.V.</option>
          </select>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-md-12">
        <label>Domicilio social y corporativo de la sociedad</label>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <form @change="getAddressInMap(null)">
          <ModalDomicilio :currentDomicilio="formalizacion.domicilio" :hide-save-button="true" />
        </form>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-md-12">
        <label>La sociedad está debidamente inscrita en el Registro Público de Comercio</label>
      </div>
    </div>
    <div class="row">
      <div class="col-md-3">
        <b-form-radio v-model="sociedadInscrita" name="radios" value="Sí" @change="selectInscrita('Sí')">
          Sí
        </b-form-radio>
      </div>
      <div class="col-md-3">
        <b-form-radio v-model="sociedadInscrita" name="radios" value="No" @change="selectInscrita('No')">
          No
        </b-form-radio>
      </div>
    </div>

    <label class="mt-2 leyendaBlock" v-if="blockInscrita" :disabled="formalizacion.disabledSociedad">
      <b>
        Para poder continuar con el proceso es necesario que la sociedad esté
        debidamente registrada.
      </b>
    </label>

    <div class="mt-4">
      <div class="row">
        <div class="col-10">
          <b-button type="submit" class="btn-fill orange-btn bottom2" size="m"
            :disabled="formalizacion.disabledSociedad">Guardar</b-button>
        </div>
        <div class="col-2">
          <b-button variant="success" class="btn-fill bottom2" size="m" :disabled="formalizacion.disabledSociedad"
            @click="validar()">Validar</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getGoogleMapUrl } from "@/utils/google-maps-utils";
import ModalDomicilio from "@/views/clients/comparecientes/ModalDomicilio.vue";

export default {
  name: "DatosSociedad",

  props: {
    formalizacion: { type: Object }
  },

  components: {
    ModalDomicilio
  },

  created() {
    if (this.formalizacion.sociedad_inscrita_rpc == 0) {
      this.sociedadInscrita = "No";
    } else if (this.formalizacion.sociedad_inscrita_rpc == 1) {
      this.sociedadInscrita = "Sí";
    }
  },

  watch: {
    "formalizacion.subtipo_acta": "blockProceso",
    sociedadInscrita: "blockProcesoNoInscrita"
  },

  data() {
    return {
      block: false,
      sociedadInscrita: "Sí",
      blockInscrita: false
    };
  },

  methods: {
    validar() {
      this.$emit("validaForm", "DatosSociedad");
    },

    blockProceso() {
      if (this.formalizacion.subtipo_acta == "Especial") {
        this.formalizacion.disabled = true;
        this.block = true;
      } else if (this.formalizacion.subtipo_acta != "Especial") {
        this.formalizacion.disabled = false;
        this.block = false;
      }
    },

    blockProcesoNoInscrita() {
      if (this.sociedadInscrita == "No") {
        this.formalizacion.disabled = true;
        this.blockInscrita = true;
      } else if (this.sociedadInscrita == "Sí") {
        this.formalizacion.disabled = false;
        this.blockInscrita = false;
      }
    },

    selectInscrita(value) {
      if (value === "Sí") {
        this.formalizacion.sociedad_inscrita_rpc = 1;
      } else if (value === "No") {
        this.formalizacion.sociedad_inscrita_rpc = 0;
      }
    },

    async getAddressInMap() {
      const address = `${this.formalizacion.domicilio.calle},
                      ${this.formalizacion.domicilio.num_exterior},
                      ${this.formalizacion.domicilio.colonia},
                      ${this.formalizacion.domicilio.delegacion_municipio},
                      ${this.formalizacion.domicilio.codigo_postal},
                      ${this.formalizacion.domicilio.ciudad},
                      ${this.formalizacion.domicilio.entidad}
                      `;
      const mapUrl = await getGoogleMapUrl(address);
      this.formalizacion.domicilio.lat = mapUrl.lat;
      this.formalizacion.domicilio.lng = mapUrl.lng;
    },
  }
};
</script>
