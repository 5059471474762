import Vue from "vue";
import Vuex from "vuex";
import { RegionsApi } from "@/api";

Vue.use(Vuex);

const state = {
  countries: [],
  states: [],
  cities: [],
  isEmptyStates: false,
  isEmptyCities: false,
};

const mutations = {
  setCountries(state, payload) {
    state.countries = payload;
  },
  setStates(state, payload) {
    state.states = payload;
  },
  setCities(state, payload) {
    state.cities = payload;
  },
  isEmptyStates(state, payload) {
    state.isEmptyStates = payload;
  },
  isEmptyCities(state, payload) {
    state.isEmptyCities = payload;
  },
};

const actions = {
  async fetchCountries({ commit }) {
    try {
      const response = await RegionsApi.getCountries();
      const data = response.data;

      commit("setCountries", data);
    } catch (error) {
      console.error(error);
    }
  },

  async fetchStates({ commit }, countryId) {
    try {
      const response = await RegionsApi.getStates(countryId);
      const data = response.data;

      if (data.length === 0) {
        commit("isEmptyStates", true);
        commit("isEmptyCities", true);
        commit("setStates", []);
        commit("setCities", []);
      } else {
        commit("isEmptyStates", false);
        commit("isEmptyCities", false);
        commit("setStates", data);
      }
    } catch (error) {
      console.error(error);
    }
  },

  async fetchCities({ commit }, stateId) {
    try {
      const response = await RegionsApi.getCities(stateId);
      const data = response.data;

      if (data.length === 0) {
        commit("isEmptyCities", true);
        commit("setCities", []);
      } else {
        commit("isEmptyCities", false);
        commit("setCities", data);
      }
    } catch (error) {
      console.error(error);
    }
  },
};

const getters = {
  getCountries: (state) => state.countries,
  getStatesByCountryId: (state) => (countryId) => {
    return state.states.filter((state) => state.country_id === countryId);
  },
  getCitiesByStateId: (state) => (stateId) => {
    return state.cities.filter((city) => city.state_id === stateId);
  },
  isEmptyStates: (state) => state.isEmptyStates,
  isEmptyCities: (state) => state.isEmptyCities,
};

export default new Vuex.Store({
  state,
  mutations,
  actions,
  getters,
});
