<template>
  <div class="container" id="container">

    <b-modal no-close-on-backdrop centered ref="compareciente-modal" title="" hide-footer id="modalC" size="xl">
      <AgregaRatificante :current-solicitante="currentCompareciente" :max-number-of-representantes="1"
        @submit-form="putCompareciente" />
    </b-modal>

    <!-- <b-modal no-close-on-backdrop centered ref="compareciente-modal" title="" hide-footer id="modalC" size="xl">
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(putCompareciente)">
          <ModalCompareciente :currentCompareciente="currentCompareciente" :ratificantes="ratificacion.ratificantes"
            @submit.prevent />
        </form>
      </ValidationObserver>
    </b-modal> -->

    <b-modal no-close-on-backdrop centered ref="modal-confirm-dialog" hide-footer id="modal-confirm-dialog">
      <h4 class="text-center">¿Seguro que quiere remover al ratificante?</h4>
      <h5 class="text-center"></h5>
      <b-button class="mt-3" variant="danger" @click="$bvModal.hide('modal-confirm-dialog')">Cancelar</b-button>
      <b-button class="mt-3 float-right" variant="primary" @click="deleteCompareciente">OK</b-button>
    </b-modal>

    <h4 class="cardD">Información de los ratificantes</h4>
    <label class="fs-6 text-black-50 text-uppercase">Agregar ratificantes</label>

    <div class="mt-2">
      <label for="btn-add">Nuevo</label>
      <b-button variant="success" class="btn-fill spaceL" size="sm" @click="addCompareciente" :disabled="!true"
        id="btn-add">
        +
      </b-button>
    </div>

    <div class="mt-2">
      <label>Ó</label>
    </div>

    <div class="mt-2">
      <div>
        <label for="select-comparecientes">Existente (solo aparecerán ratificantes de procesos concluídos)</label>
      </div>
      <div class="col-12">
        <select id="select-comparecientes" :disabled="false" class="custom-select" v-model="selectedRatificante"
          @change="selectedRatificantes">
          <option v-for="option in comparecientes" :key="option.id" :value="option.id">
            {{ displaytext(option) }}
          </option>
        </select>
      </div>
    </div>

    <!-- <div class="row mt-3">
      <div class="col-12">
        <label class="control-label" for="">Seleccionar Comparecientes</label>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-12">
        <select class="custom-select" v-model="selectedRatificante" @change="selectedRatificantes">
          <option v-for="option in comparecientes" :key="option.id" :value="option.id">
            {{ displaytext(option) }}
          </option>
        </select>
      </div>
    </div> -->

    <div v-if="this.ratificacion.ratificantes.length > 0" class="mt-5">
      <b-table ref="table" hover :fields="fieldsCuadro" :items="ratificacion.ratificantes">
        <template #cell(acciones)="{ item }">
          <b-button variant="warning" size="sm" v-b-tooltip.hover title="Editar" class="editBtn"
            v-if="(item.persona !== 'Moral') || (item.persona === 'Moral' && !item.existente)"
            @click="editCompareciente(item)">
            <b-icon-pencil />
          </b-button>
          <b-button variant="danger" size="sm" v-b-tooltip.hover title="Eliminar"
            @click="predeleteComparreciente(item.id)">
            <b-icon-trash />
          </b-button>
        </template>
      </b-table>
    </div>

    <div class="mt-4">
      <div>
        <b-button class="btn-fill orange-btn bottom2" size="m" @click="checkComparecientes()">Continuar</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import AgregaRatificante from "../../ratificaciones/infoBase/modales/AgregaRatificante.vue";

import { ClientesApi, ComparecientesApi } from "@/api";

import Storage from "@/utils/storage";

const storage = new Storage();

export default {
  name: "Ratificantes",

  components: {
    AgregaRatificante
  },

  props: {
    ratificacion: { type: Object }
  },

  computed: {
    fieldsCuadro() {
      let fieldsCuadro = [
        { key: "index", label: "", class: "text-center" },
        { key: "nombreCompleto", label: "Nombre", class: "text-center" },
        { key: "persona", label: "Persona", class: "text-center" },
        { key: "representadanombre", label: "Representante De", class: "text-center" },
        { key: "acciones", label: "Acciones", class: "text-center" },
      ];

      return fieldsCuadro;
    },
  },

  created() {
    this.loading = true;
    this.getClienteId()
      .then((cliente_id) => this.getComboComparecientes(cliente_id))
      .then((comparecientes) => this.pushToComparecientes(comparecientes))
      .catch((error) => {
        console.log(error);
        this.showErrorToast('No se pudieron agregar los comparecientes. Intente más tarde.')
      })
      .finally(() => { this.loading = false; })
  },

  data() {
    return {
      comparecientes: [],

      selectedRatificante: null,
      currentCompareciente: null,
      currentComparecienteId: null
    }
  },

  methods: {
    showErrorToast(msg) {
      this.$toast.error(msg, {
        timeout: 4000, //false,
        hideProgressBar: false,
        position: "top-right",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        closeButton: "button",
        icon: true,
      });
    },

    async getClienteId() {
      var clienteEmail = storage.getItem("user");
      const { id } = await ClientesApi.clienteId(clienteEmail);
      const cliente_id = id;
      this.cliente_id = cliente_id;
      return cliente_id;
    },

    async getComboComparecientes(cliente_id) {
      const { value } = await ComparecientesApi.combo({
        clientes_id: cliente_id,
      });
      return value;
    },

    async pushToComparecientes(comparecientes) {
      comparecientes.forEach(commpareciente => {
        this.comparecientes.push(commpareciente)
      });
      return;
    },

    displaytext(item) {
      if (item.persona === "Moral") {
        return item.denominacion_social + " (Persona Moral)"
      } else {
        let nombreCompleto = item.nombre + " " + item.paterno;

        if (item.materno) {
          nombreCompleto += " " + item.materno;
        }

        return nombreCompleto + " (Persona Física)";
      }
    },

    selectedRatificantes() {
      const indexExistente = this.ratificacion.ratificantes.findIndex(obj => obj.id === this.selectedRatificante);

      if (indexExistente != -1) {
        this.$toast.warning("No se puede seleccionar 2 veces al mismo compareciente", {
          timeout: 4000, //false,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true,
        });

        return;
      }

      const index = this.comparecientes.findIndex(o => o.id === this.selectedRatificante);

      if (this.comparecientes[index].persona === 'Física') {
        this.comparecientes[index].nombreCompleto = this.comparecientes[index].nombre + ' ' + this.comparecientes[index].paterno;

        if (this.comparecientes[index].materno) {
          this.comparecientes[index].nombreCompleto += ' ' + this.comparecientes[index].materno;
        }

        if (this.comparecientes[index].principal) {
          this.comparecientes[index].representadanombre = "Por su propio derecho";
        } else {
          this.comparecientes[index].representadanombre = "";
        }
      } else {
        this.comparecientes[index].nombreCompleto = this.comparecientes[index].denominacion_social;
      }

      this.comparecientes[index].principal = true;
      this.comparecientes[index].existente = true;

      const a = Object.assign({}, this.comparecientes[index]);

      this.ratificacion.ratificantes.push(a);

      if (this.comparecientes[index].persona === "Moral" && this.comparecientes[index].representantes.length > 0) {
        const representante = this.comparecientes[index].representantes[this.comparecientes[index].representantes.length - 1];

        representante.nombreCompleto = representante.nombre + ' ' + representante.paterno + ' ' + (representante.materno || '');
        representante.representadanombre = this.comparecientes[index].denominacion_social;
        representante.persona = "Física";
        representante.representante = true;

        const r = Object.assign({}, representante);

        this.ratificacion.ratificantes.push(r);
      }

      this.selectedRatificante = null;
    },

    addCompareciente() {
      this.$refs["compareciente-modal"].show();
      this.currentCompareciente = {};
    },

    editCompareciente(item) {
      this.$refs["compareciente-modal"].show();
      this.currentCompareciente = item;
    },

    putCompareciente(_solicitante) {
      this.currentCompareciente = Object.assign({}, _solicitante);
      let nombreCompleto = "";

      if (this.currentCompareciente.persona === 'Física') {
        nombreCompleto = this.currentCompareciente.nombre + ' ' + this.currentCompareciente.paterno;

        if (this.currentCompareciente.materno) {
          nombreCompleto += ' ' + this.currentCompareciente.materno;
        }

        if (this.currentCompareciente.principal) {
          this.currentCompareciente.representadanombre = "Por su propio derecho";
        }
      } else {
        nombreCompleto = this.currentCompareciente.denominacion_social;
      }

      this.currentCompareciente.nombreCompleto = nombreCompleto;

      if (!this.currentCompareciente.id) {
        const id = this.ratificacion.ratificantes.length + 1;
        this.currentCompareciente.id = id;
        this.ratificacion.ratificantes.push(this.currentCompareciente);
      }

      // this.$refs['table'].refresh();
      this.$refs["compareciente-modal"].hide();
    },

    predeleteComparreciente(id) {
      this.currentComparecienteId = id;
      this.$refs["modal-confirm-dialog"].show();
    },

    deleteCompareciente() {
      const index2 = this.ratificacion.ratificantes.findIndex(o => o.id === this.currentComparecienteId);

      this.ratificacion.ratificantes.splice(index2, 1);

      this.$refs["modal-confirm-dialog"].hide();
      this.$refs.table.refresh();
    },

    checkComparecientes() {
      this.loading = true;
      this.isAnyCompareciente()
        .then((continuar) => {
          if (continuar) {
            return this.everyMoralHasRepresentante();
          }
        })
        .then((continuar) => {
          if (continuar) {
            return this.everyFisicaHasRepresentante();
          }
        })
        .then((continuar) => {
          if (continuar) {
            this.$emit('allChecksPassed');
          }
        })
        .catch((error) => {
          console.log(error);
          this.showErrorToast('Sucedió un error. Intente más tarde')
        })
        .finally(() => {
          this.loading = false;
        })
    },

    isAnyCompareciente() {
      return new Promise((resolve, reject) => {
        if (this.ratificacion.ratificantes.length > 0) {
          resolve(true);
        } else {
          this.showErrorToast('Debe ingresar al menos 1 compareciente.');
          resolve(false);
        }
      });
    },

    everyMoralHasRepresentante() {
      return new Promise((resolve, reject) => {
        let moralAreCompleted = true;
        this.ratificacion.ratificantes.forEach(ratificante => {
          if (ratificante.persona === 'Moral') {
            const representantes = this.countRepresentantes(ratificante.id);
            if (representantes == 0) {
              moralAreCompleted = false;
            }
          }
        });
        if (moralAreCompleted) {
          resolve(true);
        } else {
          this.showErrorToast('Cada persona moral debe tener al menos 1 representante.');
          resolve(false);
        }
      });
    },

    everyFisicaHasRepresentante() {
      return new Promise((resolve, reject) => {
        let moralAreCompleted = true;
        this.ratificacion.ratificantes.forEach(ratificante => {
          if (ratificante.persona === 'Física' && !ratificante.principal && !ratificante.representada) {
            const representantes = this.countRepresentantes(ratificante.id);
            if (representantes == 0) {
              moralAreCompleted = false;
            }
          }
        });
        if (moralAreCompleted) {
          resolve(true);
        } else {
          this.showErrorToast('Cada persona física debe actuar por su propio derecho o tener 1 apoderado.');
          resolve(false);
        }
      });
    },

    countRepresentantes(ratificanteId) {
      let representantes = 0;

      this.ratificacion.ratificantes.forEach(ratificante => {
        if (parseInt(ratificante.representada) == parseInt(ratificanteId)) {
          representantes += 1;
        }
      });

      return representantes;
    },
  }
}
</script>

<style scoped>
.bottom2 {
  /* position: fixed;
  right: 2%;
  bottom: 5%;
  overflow: visible;
  z-index: 100; */
  float: right;
}

.container>>>.control-label {
  width: 350px;
  position: absolute;
  vertical-align: middle;
}

.container>>>.custom-control-label {
  margin-left: 50px;
  position: absolute;
  margin-top: 40px;
}

.container>>>.control-label-top {
  margin-top: 0px !important;
  margin-left: 50px;
  text-align: left;
  width: 200px;
}

.container>>>.form-control {
  width: 80px;
  margin-left: 400px;
  position: absolute;
  vertical-align: middle;
}


.container>>>.form-check-sign {
  margin-top: 15px;
  position: absolute;
}

.check-label-pers {
  margin-left: 45px;
  margin-top: 20px;
  position: absolute;
}

.btn-fill {
  margin-left: 20px;
}
</style>
