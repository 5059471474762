<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <ValidationProvider name="catDocumentos_id" rules="required" v-slot="{ errors }"
          :custom-messages="{ required: 'El tipo de documento es obligatorio' }">
          <label for="">Tipo de documento</label>
          <select class="form-control" v-model="currentDocumento.catDocumentos_id">
            <option v-for="option in options_principal_documento" :key="option.value" :value="option.value">
              {{ option.text }}
            </option>
          </select>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>

    <div class="row mt-4" v-if="currentDocumento.catDocumentos_id == id_otro">
      <div class="col-md-12">
        <ValidationProvider name="descripcion" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'La descripción del documento es obligatoria',
        }">
          <base-input type="text" label="Descripción" placeholder="Descripción" v-model="currentDocumento.descripcion">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-md-12">
        <ValidationProvider name="descripcion" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'La cantidad de páginas es obligatoria',
        }">
          <div>
            <label for="">Número de páginas del documento</label>
            <input class="form-control" type="number" required placeholder="1" min="1"
              v-model="currentDocumento.paginas">
            <!-- <base-input
                        class="custom-number"
                        type="number"
                        required
                        placeholder="1"
                        :min="1"
                        v-model="currentDocumento.paginas"
                    >
                    </base-input> -->
          </div>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>

    <!-- <div class="row mt-4">
      <div class="col-md-12">
        <ValidationProvider name="digital" v-slot="{ errors }">
          <b-form-checkbox v-model="currentDocumento.digital">
            El documento es contenido digital
          </b-form-checkbox>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div> -->

    <div class="row mt-4">
      <div class="col-md-12">
        <ValidationProvider name="anexo" v-slot="{ errors }">
          <b-form-checkbox @change="resetAnexo" v-model="currentDocumento.tiene_anexo">
            El documento contiene anexo
          </b-form-checkbox>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>

    <div class="row mt-4" v-if="currentDocumento.tiene_anexo">
      <div class="col-md-12">
        <ValidationProvider name="descripcion" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'La cantidad de páginas es obligatoria',
        }">
          <div>
            <label for="">Número de páginas del anexo</label>
            <!-- <base-input
                        class="form-control"
                        type="number"
                        required
                        placeholder="1"
                        :min="1"
                        v-model="currentDocumento.paginas_anexo"
                    >
                    </base-input> -->
            <input class="form-control" type="number" required placeholder="1" min="1"
              v-model="currentDocumento.paginas_anexo">
          </div>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="col-md-12">
        <div class="mt-4">
          <ValidationProvider name="digital" v-slot="{ errors }">
            <b-form-checkbox v-model="currentDocumento.digital">
              Alguno de los anexos incluye algún dispositivo digital (USB Pendrive, CD)
            </b-form-checkbox>
            <span class="cardErrors">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
      </div>
    </div>

    <div class="mt-4 text-center">
      <button type="submit" class="btn btn-fill float-right orange-btn">
        Guardar
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    currentDocumento: { type: Object },
    optionsDocumentos: { type: Array },
  },

  created() {
    this.getIdOtro();
    this.deleteAnexoFromDocuments();
  },

  data() {
    return {
      id_otro: null,
      id_anexo: null,
      options_principal_documento: [],
      is_anexo: false
    };
  },

  methods: {
    getIdOtro() {
      this.optionsDocumentos.forEach(element => {
        if (element.text === 'Otro') {
          this.id_otro = element.value;
        }
      });
    },

    deleteAnexoFromDocuments() {
      if (this.currentDocumento.anexoDe) {
        this.options_principal_documento = this.optionsDocumentos;
        this.is_anexo = true;
      } else {
        const documentosToExclude = [
          'Anexo',
          'Carta de Instruccion',
          'Poder del Representante',
          'Identificación oficial del Solicitante principal',
          'Identificación oficial del Representante',
          'FAD',
          'Acta Diligencia generada',
          'Acta Diligencia Word',
          'Acta Diligencia PDF',
          'Documento a notificar con FAD',
          'KYC',
          'Carta de Instrucción con FAD'
        ];

        this.optionsDocumentos.forEach(element => {
          if (!documentosToExclude.includes(element.text)) {
            this.options_principal_documento.push(element);
          }
        });
      }
    },

    resetAnexo() {
      if (!this.currentDocumento.tiene_anexo) {
        this.currentDocumento.paginas_anexo = null;
      }
    }
  }
}
</script>

<style scoped>
.control-label {
  position: absolute;
  display: inline-block;
  margin-left: 30px;
}
</style>
