<template>
  <div class="container">
    <!-- Modales -->
    <!-- Template -->
    <h4 class="cardD">Pago</h4>
    <div v-if="isPayed">
      <p>
        Su pago será procesado a través de <a href="https://www.openpay.mx" target="_blank">Openpay</a>, una plataforma
        segura y confiable para realizar transacciones electrónicas.
        <a href="https://www.openpay.mx" target="_blank">Openpay</a> garantiza la protección de sus datos y la
        integridad de la transacción. Para más información sobre Openpay y sus servicios, puede visitar su
        <a href="https://www.openpay.mx/servicios" target="_blank">página de servicios</a> o leer más sobre su
        <a href="https://www.openpay.mx/nosotros" target="_blank">empresa</a>.
      </p>
      <div>
        <div class="">
          <b-button type="submit" class="btn-fill orange-btn bottom2" size="m"
            :disabled="notificacion.id_estatus != 7">Continuar al pago</b-button>
        </div>
      </div>
    </div>

    <div v-else>
      <div v-if="forma_pago == 2">
        <p>
          Se envío un correo con la información de pago a la dirección de correo electrónico registrada en el sistema.
          Por favor revise su bandeja de entrada y siga las instrucciones para completar el pago.
        </p>
      </div>
    </div>
  </div>
</template>

<script>

// Funciones

export default {
  name: "Pago",

  props: {
    notificacion: { type: Object },
    pagado: { type: Number, default: null },
    forma_pago: { type: Number, default: null },
  },

  computed: {
    isPayed() {
      return this.pagado == null
    }
  },

  data() {
    return {
    }
  },

  methods: {
  }
}
</script>

<style scoped>
.bottom1 {
  float: left;
}

.green-btn {
  background-color: green;
}

.container>>>.validacionDatosLabel {
  position: absolute;
  margin-left: 30px;
  top: 25%;
  max-width: 1500px;
}
</style>
