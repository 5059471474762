<template>
  <div class="container">
    <h4 class="cardD">Denominación social</h4>
    <!-- <card class="blue">
      <i class="fa fa-info"></i>
      Aquí van instrucciones.
      <p></p>
      <i class="fa fa-play"></i>
      Links a videos.
      <p></p>
      <i class="fa fa-question"></i>
      Datos de contacta a un asesor.
    </card> -->

    <div class="container">
      <div v-if="loading">
        <div id="loader">
          <font-awesome-icon icon="spinner" spin />
        </div>
      </div>
    </div>

    <h4 class="cardD">
      Denominación social
    </h4>

    <div class="row">
      <div class="col-6">
        <label for="">Denominacion solicitada</label>
        <b-form-checkbox v-model="formalizacion.denominacion_solicitada" :disabled="formalizacion.disabledDenominacion">
        </b-form-checkbox>
      </div>
      <div class="col-6">
        <label for="">Formalizacío de Denominación Social</label>
        <b-form-checkbox v-model="formalizacion.cp_formaliza_denominacion"
          :disabled="formalizacion.disabledDenominacion">
        </b-form-checkbox>
      </div>
    </div>

    <div class="mt-4">
      <div class="row">
        <div class="col-10">
          <b-button type="submit" class="btn-fill orange-btn bottom2" size="m"
            :disabled="formalizacion.disabledDenominacion">Guardar</b-button>
        </div>
        <div class="col-2">
          <b-button variant="success" class="btn-fill bottom2" size="m" :disabled="formalizacion.disabledDenominacion"
            @click="validar()">Validar</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TabDenominacionSocial",

  props: {
    formalizacion: { type: Object }
  },

  data() {
    return {
      loading: false
    };
  },

  methods: {
    validar() {
      this.$emit("validaForm", "DenominacionSocial");
    }
  }
};
</script>

<style scoped>
.blue {
  background-color: #effbff;
  border-color: #effbff !important;
  text-align: justify;
}

.bottom2 {
  /* position: fixed;
  right: 2%;
  bottom: 5%;
  overflow: visible;
  z-index: 100; */
  float: right;
}
</style>
