<template>
  <div>
    <b-breadcrumb>
      <b-breadcrumb-item :to="{ path: '/dashboard' }">Inicio</b-breadcrumb-item>
      <b-breadcrumb-item active>Más información</b-breadcrumb-item>
    </b-breadcrumb>

    <div class="container my-4">
      <!-- Descripción del servicio -->
      <section id="descripcion-servicio" class="mb-5">
        <h2 class="title">{{ datos.title }}</h2>
        <!-- <div>
          <p v-html="sanitizedHtmlContentDescription" />
        </div> -->
      </section>
      <!-- Video -->
      <section id="video" class="mb-4 text-center">
        <div class="embed-responsive embed-responsive-16by9">
          <video class="embed-responsive-item" controls>
            <source :src="video" type="video/mp4" />
            Tu navegador no soporta la reproducción de video.
          </video>
        </div>
      </section>
      <!-- Información requerida -->
      <!-- <section id="informacion-requerida" class="mb-4">
        <h2 class="title">Información requerida</h2>
        <p v-html="sanitizedHtmlContentInfo" />
      </section> -->
      <!-- Resultado o producto esperado -->
      <!-- <section id="resultado-esperado" class="mb-4">
        <h2 class="title">Resultado o producto esperado</h2>
        <p v-html="sanitizedHtmlContentResult" />
      </section> -->
    </div>
  </div>
</template>

<script>
import DOMPurify from 'dompurify';

export default {
  name: "DescriptionServices",
  props: {
    datos: {
      type: Object,
      default: () => ({}),
      required: true,
    },
  },
  computed: {
    video() {
      return this.datos.video || "";
    },
    sanitizedHtmlContentDescription() {
      return DOMPurify.sanitize(this.datos.description) || "";
    },
    sanitizedHtmlContentInfo() {
      return DOMPurify.sanitize(this.datos.information) || "";
    },
    sanitizedHtmlContentResult() {
      return DOMPurify.sanitize(this.datos.result) || "";
    },
  },
};
</script>

<style>
.title {
  font-size: 2rem;
  font-weight: bold;
  color: #ea640b;
}
</style>
