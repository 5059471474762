<template>
  <div class="container">
    <h4 class="cardD">Capítulos especiales</h4>

    <div class="row mt-3">
      <div class="col-6" v-if="constitutiva.tipo_societario!='S de RL'">
        <label for="">Reglas de control corporativo</label>

        <div v-if="constitutiva.roleStaff">
          <b-form-checkbox
            v-model="constitutiva.reglas_control"
            :disabled="constitutiva.disabledEspeciales"
          >
          </b-form-checkbox>
        </div>
        <div v-else>
          <b-form-checkbox
            v-model="constitutiva.reglas_control"
            :disabled="constitutiva.disabled"
          >
          </b-form-checkbox>
        </div>

        <label class="mt-2 leyenda" v-if="constitutiva.reglas_control">
          La cotización básica no incluye esta opción.
        </label>
      </div>
      <div class="col-6">
        <label for="">Reglas para asambleas telemáticas</label>

        <div v-if="constitutiva.roleStaff">
          <b-form-checkbox
            v-model="constitutiva.reglas_asambleas"
            :disabled="constitutiva.disabledEspeciales"
          >
          </b-form-checkbox>
        </div>
        <div v-else>
          <b-form-checkbox
            v-model="constitutiva.reglas_asambleas"
            :disabled="constitutiva.disabled"
          >
          </b-form-checkbox>
        </div>

        <label class="mt-2 leyenda" v-if="constitutiva.reglas_asambleas">
          La cotización básica no incluye esta opción.
        </label>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-6">
        <label for="">Exclusión de accionistas</label>

        <div v-if="constitutiva.roleStaff">
          <b-form-checkbox
            v-model="constitutiva.exclusion_accionistas"
            :disabled="constitutiva.disabledEspeciales"
          >
          </b-form-checkbox>
        </div>
        <div v-else>
          <b-form-checkbox
            v-model="constitutiva.exclusion_accionistas"
            :disabled="constitutiva.disabled"
          >
          </b-form-checkbox>
        </div>

        <label class="mt-2 leyenda" v-if="constitutiva.exclusion_accionistas">
          La cotización básica no incluye esta opción.
        </label>
      </div>
    </div>

    <div class="mt-4">
      <div class="row">
        <div class="col-10">
          <b-button
            type="submit"
            class="btn-fill orange-btn bottom2"
            size="m"
            :disabled="constitutiva.disabledEspeciales"
            >Guardar</b-button
          >
        </div>
        <div class="col-2">
          <b-button
            variant="success"
            class="btn-fill bottom2"
            size="m"
            :disabled="constitutiva.disabledEspeciales"
            @click="validar()"
            >Validar</b-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TabCapitulosEspeciales",

  props: {
    constitutiva: { type: Object }
  },

  data() {
    return {
      form: {
        reglas_control: null,
        reglas_asambleas: null
      }
    };
  },

  methods: {
    validar() {
      this.$emit("validaForm", "CapitulosEspeciales");
    }
  }
};
</script>
