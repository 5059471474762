<template>
  <div>
    <div class="row">
      <div class="col-md-4">
        <ValidationProvider name="nombre" rules="required" v-slot="{ errors }"
          :custom-messages="{ required: 'El nombre es obligatorio' }">
          <base-input type="text" label="Nombre(s)" placeholder="Nombre(s)" v-model="currentAdministrador.nombre">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="col-md-4">
        <ValidationProvider name="paterno" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'El apellido paterno es obligatorio',
        }">
          <base-input type="text" label="Apellido Paterno" placeholder="Apellido Paterno"
            v-model="currentAdministrador.paterno">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="col-md-4">
        <!-- <ValidationProvider
          name="lastNameM"
          rules="required"
          v-slot="{ errors }"
          :custom-messages="{
            required: 'El apellido materno es obligatorio',
          }"
        > -->
        <base-input type="text" label="Apellido Materno" placeholder="Apellido Materno"
          v-model="currentAdministrador.materno">
        </base-input>
        <!-- <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider> -->
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <ValidationProvider name="cargo" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'El Cargo es obligatorio',
        }">
          <base-input type="text" label="Cargo" placeholder="Cargo" v-model="currentAdministrador.cargo" v-if="
            currentAdministrador.cargo === 'Administrador único' ||
            currentAdministrador.cargo === 'Gerente General'
          " disabled>
          </base-input>
          <div v-else>
            <label for="">Cargo</label>
            <select class="custom-select" v-model="currentAdministrador.cargo">
              <option v-for="option in options" :key="option.value">
                {{ option.text }}
              </option>
            </select>
          </div>
          <!-- <base-input
            v-else
            type="text"
            label="Cargo"
            placeholder="Cargo"
            v-model="currentAdministrador.cargo"
          >
          </base-input> -->
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="col-md-6" v-if="complete">
        <ValidationProvider name="rfc" rules="required|length:13" v-slot="{ errors }" :custom-messages="{
          required: 'El RFC es obligatorio',
          length: 'Debe tener 13 caracteres',
        }">
          <base-input type="text" label="RFC" placeholder="RFC" v-model="currentAdministrador.rfc">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>

    <div class="text-center">
      <button type="submit" class="btn btn-fill float-right orange-btn">
        Guardar
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    currentAdministrador: { type: Object },
    complete: { type: Boolean },
  },

  watch: {
    "currentAdministrador.rfc": function (value) {
      if (!value) {
        this.currentAdministrador.rfc = "";

        return;
      }

      this.currentAdministrador.rfc = this.currentAdministrador.rfc.toUpperCase();
    },
  },

  data() {
    return {
      options: [
        { value: "Presidente", text: "Presidente" },
        { value: "Secretario", text: "Secretario" },
        { value: "Tesorero", text: "Tesorero" },
        { value: "Miembro o Vocal", text: "Miembro o Vocal" },
      ],

      form: {
        nombre: null,
        paterno: null,
        materno: null,
        rfc: null,
        cargo: null,
      },
    };
  },

  methods: {
    // calcularCapitales() {
    //   this.$emit("calcular", "");
    // },
  },
};
</script>
