<template>
  <div class="content">
    <b-breadcrumb>
      <b-breadcrumb-item active>Corredurías</b-breadcrumb-item>
    </b-breadcrumb>

    <div class="container-fluid">
      <div class="row">
        <div class="col-6">
          <base-input v-model="searchCorreduria" placeholder="Buscar Correduría"></base-input>
        </div>
        <div class="col-1"></div>
        <div class="col-5">
          <router-link :to="{ path: '/dashboard/corredurias/addcorreduria/' }">
            <b-button type="submit" class="btn-fill float-right orange-btn">
              Nueva Correduría
            </b-button>
          </router-link>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <card class="strpied-tabled-with-hover" body-classes="table-full-width table-responsive">
            <b-modal no-close-on-backdrop centered ref="modal-confirm-dialog" hide-footer id="modal-confirm-dialog">
              <h4 class="text-center">
                ¿Seguro que quiere eliminar a la correduría?
              </h4>
              <h5 class="text-center">Se eliminarán todos los datos.</h5>
              <b-button class="mt-3" variant="danger" @click="$bvModal.hide('modal-confirm-dialog')">Cancelar</b-button>
              <b-button class="mt-3 float-right" variant="primary" @click="deleteCorreduria()">OK</b-button>
            </b-modal>

            <div class="text-center">
              <b-table :fields="fields" :items="corredurias" hover>
                <template #cell(index)="{ index }">
                  {{ getIndex(index + 1) }}
                </template>
                <template #cell(acciones)="{ item }">
                  <router-link :to="{
                    path: '/dashboard/corredurias/editcorreduria/' + item.id
                  }">
                    <b-button variant="warning" size="sm" v-b-tooltip.hover title="Editar" class="editBtn">
                      <b-icon-pencil />
                    </b-button>
                  </router-link>

                  <b-button variant="danger" @click="predeleteCorreduria(item.id)" size="sm" v-b-tooltip.hover
                    title="Eliminar">
                    <b-icon-trash />
                  </b-button>
                </template>
              </b-table>
            </div>

            <div class="d-flex justify-content-between pages">
              <b-pagination v-model="currentPage" :per-page="perPage" :total-rows="totalCorredurias" />

              <span>Mostrando {{ corredurias.length }} registros de
                {{ totalCorredurias }}</span>
            </div>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { CorreduriasApi } from "@/api";

export default {
  name: "Corredurias",

  data: () => ({
    loading: false,
    currentPage: 1,
    perPage: 10,
    totalCorredurias: 0,
    searchCorreduria: null,
    corredurias: [],
    currentCorreduriaId: null
  }),

  computed: {
    fields() {
      let fields = [
        { key: "index", label: "", class: "text-center" },
        { key: "nombre", label: "Nombre", class: "text-center" },
        { key: "acciones", label: "Acciones", class: "text-center" }
      ];
      return fields;
    }
  },

  watch: {
    currentPage: "getCorredurias",
    searchCorreduria: "getCorredurias"
  },

  created() {
    this.getCorredurias();
  },

  methods: {
    getIndex(index) {
      return index + (this.currentPage - 1) * this.perPage;
    },

    async getCorredurias() {
      this.loading = true;
      try {
        const {
          odata_page: page,
          odata_total: total,
          odata_perpage: perPage,
          value
        } = await CorreduriasApi.list({
          page: this.currentPage,
          data: this.searchCorreduria,
          registersPerPage: this.perPage
        });
        this.currentPage = page;
        this.totalCorredurias = total;

        this.corredurias = value;
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },

    predeleteCorreduria(id) {
      this.currentCorreduriaId = id;
      this.$refs["modal-confirm-dialog"].show();
    },

    async deleteCorreduria() {
      this.loading = true;
      try {
        let id = this.currentCorreduriaId;

        await CorreduriasApi.delete(id);

        var index = this.corredurias.findIndex(function (o) {
          return o.id === id;
        });
        this.corredurias.splice(index, 1);
        this.totalCorredurias--;
        this.$toast.success("Correduría eliminada", {
          timeout: 4000, //false,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true
        });
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.$toast.error("No fue posible eliminar a la correduría porque tiene al menos un proceso vinculado", {
          timeout: 4000, //false,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true
        });
      }
      this.$refs["modal-confirm-dialog"].hide();
    }
  }
};
</script>
