<template>
  <div>
    <div class="row">
      <div class="col-md-4">
        <ValidationProvider name="firstName" rules="required" v-slot="{ errors }"
          :custom-messages="{ required: 'El nombre es obligatorio' }">
          <base-input type="text" label="Nombre(s)" placeholder="Nombre(s)" v-model="user.firstName">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="col-md-4">
        <ValidationProvider name="lastNameP" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'El apellido paterno es obligatorio'
        }">
          <base-input type="text" label="Apellido Paterno" placeholder="Apellido Paterno" v-model="user.lastNameP">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="col-md-4">
        <base-input type="text" label="Apellido Materno" placeholder="Apellido Materno"
          v-model="user.lastNameM"></base-input>
        <!-- <ValidationProvider name="lastNameM" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'El apellido materno es obligatorio'
        }">
          <base-input type="text" label="Apellido Materno" placeholder="Apellido Materno" v-model="user.lastNameM">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider> -->
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <ValidationProvider name="email" rules="required|email" v-slot="{ errors }" :custom-messages="{
          required: 'El email es obligatorio',
          email: 'Debe ser un email válido'
        }">
          <base-input type="email" label="Email" placeholder="Email" v-model="user.email">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="col-md-6">
        <ValidationProvider name="rol" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'El rol es obligatorio'
        }">
          <label for="">Rol</label>
          <select v-model="user.rol" class="custom-select">
            <option value="Staff Administrador">Staff Administrador</option>
            <option value="Staff Usuario">Staff Usuario</option>
          </select>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>
    <!--
    <div class="row">
      <div class="col-md-4">
        <label for="">Externo</label>
        <b-form-checkbox v-model="user.externo"> </b-form-checkbox>
      </div>
    </div> -->

    <b-button type="submit" class="btn-fill float-right orange-btn">Guardar</b-button>

  </div>
</template>
<script>
export default {
  name: "Form",

  props: {
    user: { type: Object }
  },

  data() {
    return {
      form: {
        // firstName: this.user.firstName,
        // lastNameP: this.user.lastNameP,
        // lastNameM: this.user.lastNameM,
        // email: this.user.email,
        // password: this.user.password,
        // rol: this.user.rol,
        // telefono: this.user.telefono,
        // persona: this.user.persona,
        // rfc: this.user.rfc,

        firstName: null,
        lastNameP: null,
        lastNameM: null,
        email: null,
        password: null,
        rol: null,
        telefono: null,
        persona: null,
        rfc: null,
        // externo: null
      }
    };
  }
};
</script>

<style>
#card {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  /* color: #9a9a9a; */
  color: #ea640b;
  font-size: 13px;
}
</style>
