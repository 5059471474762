var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4"},[_c('ValidationProvider',{attrs:{"name":"firstName","rules":"required","custom-messages":{ required: 'El nombre es obligatorio' }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('base-input',{attrs:{"type":"text","label":"Nombre(s)","placeholder":"Nombre(s)"},model:{value:(_vm.user.nombre),callback:function ($$v) {_vm.$set(_vm.user, "nombre", $$v)},expression:"user.nombre"}}),_c('span',{staticClass:"cardErrors"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"col-md-5"},[_c('ValidationProvider',{attrs:{"name":"lastNameP","rules":"required","custom-messages":{
        required: 'El apellido paterno es obligatorio'
      }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('base-input',{attrs:{"type":"text","label":"Apellido Paterno","placeholder":"Apellido Paterno"},model:{value:(_vm.user.paterno),callback:function ($$v) {_vm.$set(_vm.user, "paterno", $$v)},expression:"user.paterno"}}),_c('span',{staticClass:"cardErrors"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"col-md-3"},[_c('ValidationProvider',{attrs:{"name":"lastNameM","rules":"required","custom-messages":{
        required: 'El apellido materno es obligatorio'
      }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('base-input',{attrs:{"type":"text","label":"Apellido Materno","placeholder":"Apellido Materno"},model:{value:(_vm.user.materno),callback:function ($$v) {_vm.$set(_vm.user, "materno", $$v)},expression:"user.materno"}}),_c('span',{staticClass:"cardErrors"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"email","rules":"required|email","custom-messages":{
        required: 'El email es obligatorio',
        email: 'Debe ser un email válido'
      }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('base-input',{attrs:{"type":"email","label":"Email","placeholder":"Email"},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}}),_c('span',{staticClass:"cardErrors"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"rol","rules":"required","custom-messages":{
        required: 'El rol es obligatorio'
      }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('label',{attrs:{"for":""}},[_vm._v("Rol")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.rol),expression:"user.rol"}],staticClass:"custom-select",on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.user, "rol", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"Staff Administrador"}},[_vm._v("Staff Administrador")]),_c('option',{attrs:{"value":"Staff Usuario"}},[_vm._v("Staff Usuario")])]),_c('span',{staticClass:"cardErrors"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('b-button',{staticClass:"btn-fill float-right orange-btn",on:{"click":_vm.saveStaff}},[_vm._v("Guardar")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }