<template>
  <div>
    <!-- <card>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit($emit('onSubmit'))"> -->
    <div class="row">
      <div class="col-md-6">
        <ValidationProvider name="email" rules="required|email" v-slot="{ errors }" :custom-messages="{
          required: 'El email es obligatorio',
          email: 'Debe ser un email válido',
        }">
          <base-input type="email" label="Email" placeholder="Email" v-model="user.email" autocomplete="username">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="col-md-6">
        <ValidationProvider name="password" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'La contraseña es obligatoria',
        }">
          <base-input type="password" label="Contraseña" placeholder="Contraseña" v-model="user.password"
            autocomplete="current-password">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>

    <button type="submit" class="btn orange-btn float-right">Continuar</button>
    <!-- </form>
    </ValidationObserver>
  </card> -->
  </div>
</template>
<script>
// import Card from "src/components/Cards/Card.vue";

export default {
  name: "Form",

  // components: {
  //   Card,
  // },

  props: {
    user: { type: Object },
  },

  data() {
    return {
      form: {
        email: null,
        password: null,
      },
    };
  },
};
</script>

<style>
.msg {
  color: red !important;
}

/* .card {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  /* color: #9a9a9a;
  color: #ea640b;
  font-size: 13px;
} */
</style>
