<template>
  <div class="content">
    <b-breadcrumb>
      <b-breadcrumb-item active>Comparecientes</b-breadcrumb-item>
    </b-breadcrumb>

    <div class="container">
      <div v-if="loading">
        <div id="loader">
          <font-awesome-icon icon="spinner" spin />
        </div>
      </div>
    </div>

    <div class="container-fluid">
      <div class="row">
        <div class="col-4">
          <base-input v-model="searchCompareciente" placeholder="Buscar compareciente"></base-input>
        </div>

        <div class="col-4"></div>
        <!-- solo cuando son clientes y no staff -->
        <!-- <div class="col-4" v-if="role == 'Cliente Administrador' || role == 'Cliente Usuario'">
          <router-link :to="{ path: '/dashboard/comparecientes/addcompareciente/' }">
            <b-button type="submit" class="btn-fill float-right orange-btn">
              Nuevo compareciente
            </b-button>
          </router-link>
        </div> -->

        <div class="col-12">
          <card class="strpied-tabled-with-hover" body-classes="table-full-width table-responsive">
            <!-- <vue-confirm-dialog></vue-confirm-dialog> -->
            <b-modal no-close-on-backdrop centered ref="modal-confirm-dialog" hide-footer id="modal-confirm-dialog">
              <h4 class="text-center">
                ¿Seguro que quiere eliminar al compareciente?
              </h4>
              <h5 class="text-center">Se eliminarán todos los datos.</h5>
              <b-button class="mt-3" variant="danger" @click="$bvModal.hide('modal-confirm-dialog')">Cancelar</b-button>
              <b-button class="mt-3 float-right" variant="primary" @click="deleteCompareciente()">OK</b-button>
            </b-modal>

            <div class="text-center">
              <!-- <ve-table
                rowKeyFieldName="rowKey"
                :columns="columns"
                :table-data="comparecientes"
                style="width: 100%"
              /> -->

              <b-table :fields="fields" :items="comparecientes" hover>
                <template #cell(index)="{ index }">
                  {{ getIndex(index + 1) }}
                </template>
                <template #cell(acciones)="{ item }">
                  <!-- <router-link
                    :to="{
                      path:
                        '/dashboard/comparecientes/editcomparecientes/' +
                        item.id
                    }"
                  > -->
                  <b-button variant="warning" size="sm" v-b-tooltip.hover @click="clonaCompareciente(item.id)"
                    title="Editar" class="editBtn">
                    <b-icon-pencil />
                  </b-button>
                  <!-- </router-link> -->

                  <b-button variant="danger" @click="predeleteCompareciente(item.id)" size="sm" v-b-tooltip.hover
                    title="Eliminar">
                    <b-icon-trash />
                  </b-button>
                </template>
              </b-table>
            </div>

            <div class="d-flex justify-content-between pages">
              <b-pagination v-model="currentPage" :per-page="perPage" :total-rows="totalComparecientes" />
              <span>Mostrando {{ comparecientes.length }} registros de {{ totalComparecientes }}</span>
            </div>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Card from "src/components/Cards/Card.vue";

import { ClientesApi, ComparecientesApi, FuncionesApi } from "@/api";
import Storage from "@/utils/storage";

const storage = new Storage();

export default {
  name: "Comparecientes",

  data() {
    return {
      role: null,
      loading: false,
      currentPage: 1,
      perPage: 10,
      totalComparecientes: 0,
      searchCompareciente: null,
      // comercialSearch: null,
      // option sComerciales: [],
      // rol: null,

      comparecientes: [],
      currentComparecienteId: null
    };
  },

  computed: {
    fields() {
      return [
        { key: "index", label: "", class: "text-center" },
        { key: "compareciente", label: "Nombre", class: "text-center" },
        { key: "rfc", label: "RFC", class: "text-center" },
        { key: "persona", label: "Persona", class: "text-center" },
        { key: "nombre_comercial", label: "Representa", class: "text-center" },
        // { key: "nombre_comercial", label: "Nombre comercial", class: "text-center" },
        { key: "acciones", label: "Acciones", class: "text-center" }
      ];
    }
  },

  watch: {
    currentPage: "getComparecientes",
    searchCompareciente: "getComparecientes"
  },

  components: {
    Card
  },

  created() {
    this.getRole();
    this.getComparecientes();
  },

  methods: {
    getRole() {
      this.role = storage.getItem("role");
    },

    getIndex(index) {
      return index + (this.currentPage - 1) * this.perPage;
    },

    async getComparecientes() {
      try {
        this.loading = true;

        const clienteEmail = storage.getItem("user");
        const { id } = await ClientesApi.clienteId(clienteEmail);

        let clientes_id = 0;

        if (id != 0) {
          //se supone que esto estaría en 0 cuando el staff admin pide los comparecientes y ellos no van a tener cliente_id
          clientes_id = id;
        }

        const {
          odata_page: page,
          odata_total: total,
          odata_perpage: perPage,
          // clientes_id: clientes_id,
          value
        } = await ComparecientesApi.myList(clientes_id, {
          page: this.currentPage,
          data: this.searchCompareciente,
          registersPerPage: this.perPage,
          visible: 1
        });

        this.currentPage = page;
        this.totalComparecientes = total;

        for (let i = 0; i < value.length; i++) {
          if (value[i].persona === "Física") {
            if (value[i].moral_representada_id) {
              const moral_representada = value.find(c => c.id === value[i].moral_representada_id);

              if (moral_representada) {
                value[i].nombre_comercial = moral_representada.nombre_comercial;
              }
            } else {
              value[i].compareciente = `${value[i].nombre} ${value[i].paterno} ${value[i].materno || ""}`;
              value[i].nombre_comercial = "Por derecho propio";
            }

            continue;
          }

          value[i].compareciente = value[i].denominacion_social;

          const { nombre_comercial } = await ClientesApi.clienteNombreComercial(value[i].cliente_id);

          value[i].nombre_comercial = nombre_comercial;
        }

        this.comparecientes = value;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    async getComparecientesPorComercial(nombreComercial) {
      this.loading = true;

      try {
        const {
          odata_page: page,
          odata_total: total,
          odata_perpage: perPage,
          value
        } = await ComparecientesApi.compPorComercial(nombreComercial, {
          //this.comercialSearch
          page: this.currentPage,
          data: this.searchCompareciente,
          registersPerPage: this.perPage
        });
        this.currentPage = page;
        this.totalComparecientes = total;

        for (let i = 0; i < value.length; i++) {
          var { nombre_comercial } = await ClientesApi.clienteNombreComercial(value[i].cliente_id);
          value[i].nombre_comercial = nombre_comercial;

          if (value[i].persona == "Moral" || value[i].persona == "Fideicomiso") {
            value[i].compareciente = value[i].denominacion_social;
          } else if (value[i].persona == "Física") {
            const nombreTotal = `${value[i].nombre} ${value[i].paterno} ${value[i].materno}`;
            value[i].compareciente = nombreTotal;
          }
        }

        this.comparecientes = value;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    predeleteCompareciente(id) {
      this.currentComparecienteId = id;
      this.$refs["modal-confirm-dialog"].show();

      // this.$confirm({
      //   title: "¿Seguro que quiere eliminar al compareciente?",
      //   message: "Se eliminarán todos los datos.",
      //   button: {
      //     yes: "OK",
      //     no: "Cancelar",
      //   },
      //   callback: (confirm) => {
      //     if (confirm) {
      //       this.deleteCompareciente(id);
      //     }
      //   },
      // });
    },

    async deleteCompareciente() {
      //id
      this.loading = true;
      try {
        let id = this.currentComparecienteId;
        await ComparecientesApi.delete(id);
        var index = this.comparecientes.findIndex(function (o) {
          return o.id === id;
        });
        this.comparecientes.splice(index, 1);
        this.totalComparecientes--;

        this.$toast.success("Compareciente eliminado", {
          timeout: 4000, //false,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true
        });
        this.$refs["modal-confirm-dialog"].hide();
        this.loading = false;
      } catch (error) {
        this.loading = false;

        if (error.status === 409) {
          this.$toast.warning(
            "No se puede eliminar al compareciente porque es parte de un proceso",
            {
              timeout: 4000, //false,
              hideProgressBar: false,
              position: "top-right",
              closeOnClick: true,
              pauseOnHover: true,
              draggable: false,
              closeButton: "button",
              icon: true
            }
          );
        } else {
          this.$toast.error("Sucedió un error, intente más tarde", {
            timeout: 4000, //false,
            hideProgressBar: false,
            position: "top-right",
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            closeButton: "button",
            icon: true
          });
        }
        this.$refs["modal-confirm-dialog"].hide();
      }
    },

    async clonaCompareciente(comparecientes_id) {
      try {
        // this.$router.push(`/dashboard/comparecientes/editcomparecientes/${comparecientes_id}`);
        // return;

        const value = await FuncionesApi.clonDecision(comparecientes_id, {});

        if (value == 1) {
          this.clonaComparecienteId = comparecientes_id;

          this.$refs["modal-confirm-clon"].show();
        } else {
          //continua a ruta normal
          this.$router.push(`/dashboard/comparecientes/editcomparecientes/${comparecientes_id}`);
        }
      } catch (error) {
        this.$toast.error("Sucedió un error, intente más tarde", {
          timeout: 4000, //false,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true
        });
      }
    },

    async postClonarCompareciente() {
      try {
        //clono compareciente y cacho id
        const { id } = await ComparecientesApi.clonaCompareciente(this.clonaComparecienteId, {
          comparecientes_id: this.clonaComparecienteId
        });

        // console.log(id);
        if (id) {
          this.$toast.success("Compareciente clonado", {
            timeout: 4000, //false,
            hideProgressBar: false,
            position: "top-right",
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            closeButton: "button",
            icon: true
          });

          this.$router.push(`/dashboard/comparecientes/editcomparecientes/${id}`);

          this.$refs["modal-confirm-clon"].close();
        } else {
          this.$toast.error("Sucedió un error, intente más tarde", {
            timeout: 4000, //false,
            hideProgressBar: false,
            position: "top-right",
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            closeButton: "button",
            icon: true
          });
        }
      } catch (error) {
        console.log(error);
      }
    }
  }
};
</script>

<style scoped>
/*
.th {
  font-size: 12px !important;
  text-transform: uppercase !important;
  color: white !important;
  font-weight: 400 !important; */
/* } */
/* ::ve-table-deep tr.ve-table-header-tr {
  background-color: red !important;
} */
/* ::table-deep th {
  background-color: red !important;
} */
</style>
