<template>
  <div class="content">
    <b-breadcrumb>
      <b-breadcrumb-item :to="{ path: '/dashboard' }">Inicio</b-breadcrumb-item>
      <b-breadcrumb-item active>Nueva Notificación</b-breadcrumb-item>
    </b-breadcrumb>

    <div class="container">
      <div v-if="loading">
        <div id="loader">
          <font-awesome-icon icon="spinner" spin />
        </div>
      </div>
    </div>

    <b-modal no-close-on-backdrop centered ref="modal-confirm-dialog-route" hide-footer id="modal-confirm-dialog-route">
      <h4 class="text-center">
        ¿Seguro que quiere salir de la página?
      </h4>
      <h5 class="text-center"></h5>
      <b-button class="mt-3" variant="danger" @click="$bvModal.hide('modal-confirm-dialog-route')">Cancelar</b-button>
      <b-button class="mt-3 float-right" variant="primary" @click="beforeLeave(true)">OK</b-button>
    </b-modal>

    <b-modal no-close-on-backdrop centered ref="modal-confirm-dialog" hide-footer id="modal-confirm-dialog">
      <h4 class="text-center">
        ¿Seguro que quiere salir sin guardar los cambios?
      </h4>
      <h5 class="text-center"></h5>
      <b-button class="mt-3" variant="danger" @click="$bvModal.hide('modal-confirm-dialog')">Cancelar</b-button>
      <b-button class="mt-3 float-right" variant="primary">OK</b-button>
    </b-modal>

    <b-modal no-close-on-backdrop centered ref="modal-confirm-cotizacion" hide-footer id="modal-confirm-cotizacion">
      <h4 class="text-center">
        ¿Seguro que quiere continuar al pago?
      </h4>
      <h5 class="text-center"></h5>
      <b-button class="mt-3" variant="danger" @click="$bvModal.hide('modal-confirm-cotizacion')">Cancelar</b-button>
      <b-button class="mt-3 float-right" variant="primary" @click="generaCotizacion()">OK</b-button>
    </b-modal>

    <b-modal no-close-on-backdrop centered ref="pago-modal" title="" hide-footer id="modalC" size="xl">
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(payCotizacion)">
          <Openpay :cotizacion_id="notificacion.id_cotizaciones" :precio_total="notificacion.precio_total"
            @submit.prevent @payCotizacion="payCotizacion($event)" />
        </form>
      </ValidationObserver>
    </b-modal>

    <b-modal no-close-on-backdrop id="modal-help-descripcion-documentos" size="xl" centered hide-footer>
      <div>
        <b-carousel id="carousel-notificaciones-1" :interval="0" indicators background="#ababab">
          <b-carousel-slide
            :img-src="require('@/assets/img/notificaciones/help/modal_1_notificaciones_descripcion_documentos.webp')">
          </b-carousel-slide>
        </b-carousel>
      </div>
    </b-modal>

    <div class="container-fluid">
      <b-row>
        <b-col class="col-3 ">
          <b-card>
            <TreeNotificaciones :notificacion="notificacion" :user="'Client'" @node="selected"
              :selectedComponent="currentComponentBody" />
          </b-card>
          <div class="row justify-content-center">
            <b-card no-body v-if="currentComponentBody === 'Solicitantes'"
              :img-src="require('@/assets/img/notificaciones/help/2_solicitantes_1.webp')"
              img-alt="Descripción de solicitantes" tag="article" style="max-width: 20rem;">
            </b-card>
            <b-card no-body v-if="currentComponentBody === 'Ubicacion'"
              :img-src="require('@/assets/img/notificaciones/help/3_domicilio_a_notificar.webp')"
              img-alt="Descripción de la ubicación a notificar" tag="article" style="max-width: 20rem;">
            </b-card>
            <b-card no-body v-if="currentComponentBody === 'Documentos'"
              :img-src="require('@/assets/img/notificaciones/help/1_documentos_abre_modal_1.webp')"
              img-alt="Descripción de los documentos a notificar" tag="article" style="max-width: 20rem;">
              <b-button class="orange-btn" @click="$bvModal.show('modal-help-descripcion-documentos')">Más
                información</b-button>
            </b-card>
            <b-card no-body v-if="currentComponentBody === 'Cotizacion'"
              :img-src="require('@/assets/img/notificaciones/help/4_cotizacion.webp')"
              img-alt="Descripción de la cotización" tag="article" style="max-width: 20rem;">
            </b-card>
          </div>
        </b-col>
        <b-col class="col-9">
          <card>
            <b-row>
              <b-col class="col-12">
                <div v-if="currentComponentBody === 'Solicitantes'">
                  <ValidationObserver v-slot="{ handleSubmit }">
                    <form @submit.prevent="handleSubmit(saveSolicitantes)">
                      <Solicitantes :notificacion="notificacion" :comparecientes="comparecientes" @submit.prevent />
                    </form>
                  </ValidationObserver>
                </div>
                <div v-if="currentComponentBody === 'Ubicacion'">
                  <ValidationObserver v-slot="{ handleSubmit }">
                    <form @submit.prevent="handleSubmit(postDomicilio)" @change="getAddressInMap(null)">
                      <ModalDomicilio :currentDomicilio="currentDomicilio" :disableFields="disableDomicilioFields"
                        @submit.prevent @node="selected" />
                    </form>
                  </ValidationObserver>
                </div>
                <div v-if="currentComponentBody === 'Documentos'">
                  <ValidationObserver v-slot="{ handleSubmit }">
                    <form @submit.prevent="handleSubmit(saveDocumentos)">
                      <Documentos :notificacion="notificacion" @submit.prevent @node="selected" />
                    </form>
                  </ValidationObserver>
                </div>
                <div v-if="currentComponentBody === 'Cotizacion'">
                  <ValidationObserver v-slot="{ handleSubmit }">
                    <form @submit.prevent="handleSubmit(confirmaCotizacion)">
                      <Cotizacion :notificacion="notificacion" @submit.prevent @node="selected" />
                    </form>
                  </ValidationObserver>
                </div>
              </b-col>
            </b-row>
          </card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import Card from "@/components/Cards/Card.vue";
import ModalDomicilio from "@/views/clients/comparecientes/ModalDomicilio.vue";
import TreeNotificaciones from "@/views/clients/procesos/notificaciones/TreeNotificaciones.vue";
import Cotizacion from "@/views/clients/procesos/notificaciones/infoBase/Cotizacion.vue";
import Documentos from "@/views/clients/procesos/notificaciones/infoBase/Documentos.vue";
import Solicitantes from "@/views/clients/procesos/notificaciones/infoBase/Solicitantes.vue";
import Openpay from "@/views/openpay/Openpay.vue";

import { ClientesApi, ComparecientesApi, CotizacionesApi, DocumentosApi, DocumentosXServiciosApi, DomiciliosApi, FuncionesApi, NotificacionesApi, NotificadosApi, SolicitantesApi } from "@/api";
import { getGoogleMapUrl } from "@/utils/google-maps-utils";
import Storage from "@/utils/storage";

const storage = new Storage();

export default {
  name: "AddNotificaciones",

  components: {
    Card,
    ModalDomicilio,
    Solicitantes,
    Documentos,
    TreeNotificaciones,
    Cotizacion,
    Openpay
  },

  async created() {
    await this.getComparecientes();
  },

  beforeRouteLeave(to, from, next) {
    if (!to.name === 'EditNotificaciones') {
      this.$refs["modal-confirm-dialog-route"].show();
      this.next = next;
    }
    else {
      this.next = next;
      this.beforeLeave(true);
    }
  },

  data: () => ({
    next: null,
    prenode: null,

    loading: false,
    currentComponentBody: "Solicitantes",
    cliente_id: 0,

    currentDomicilio: {
      tieneExtranjero: false,
      tieneTipo: false,
      compareciente: false,
      extranjero: null,
      notificaciones: true,
      lat: 19.4320605,
      lng: -99.1335938,
      pais: "México"
    },

    notificacion: {
      // Objeto creado en BD
      id: null,
      estatus: null,
      id_cotizaciones: null,
      corredurias_id: null,

      // Valores de Tree
      currentComponentValues: {
        Solicitantes: false,
        Ubicacion: false,
        Documentos: false,
        Cotizacion: false,
        ComplementaNotificado: false,
        ComplementaSolicitantes: false,
        EntregaDocumentos: false,
        Diligencia: false,
        Actas: false,
        Expediente: false,
        Terminado: false,
      },

      // Solicitantes
      solicitantes: [],
      tipo_solicitante: null,

      // Documentos
      documentos: [],
      fad: false,
      contenido_digital: false,

      // Extras
      acta_express: false,
      envio_domicilio: false,

      // Totales cotizacion
      costo_zona: 0,
      costo_total: 0,

      // Cotizacion (sirve para aplicar cupones en componenete cotizacion)
      cotizacion: {
        id: 0,
        cupones_id: 0,
        cupones_referencia_id: 0,
      },
    },

    // Objeto creado en BD
    notificado: {
      id: null,
      nombre: null,
      paterno: null,
      materno: null,
      razon_social: null,
      persona: null,
      nombre_notificado: null,
      id_notificaciones: null,
      id_domicilios: null,
    },

    comparecientes: [],

    // Objeto para desactivar o poner campos como readonly
    disableDomicilioFields: {
      disablePais: true,
    },
  }),

  methods: {
    async getComparecientes() {
      try {
        this.loading = true;
        var clienteEmail = storage.getItem("user");
        const { id } = await ClientesApi.clienteId(clienteEmail);
        const cliente_id = id;
        this.cliente_id = cliente_id;
        const { value } = await ComparecientesApi.combo({
          clientes_id: cliente_id,
        });

        value.forEach(element => this.comparecientes.push(element));
      } catch (error) {
        console.log(error);
        this.$toast.error("Sucedió un error al obtener los comparecientes, intente más tarde", {
          timeout: 4000, //false,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true,
        });
      } finally {
        this.loading = false;
      }
    },

    async beforeLeave(answer) {
      if (answer) {
        this.next();
      }
    },

    async selected(node) {
      const keys = Object.keys(this.notificacion.currentComponentValues);
      const values = Object.values(this.notificacion.currentComponentValues);
      const dest = keys.find(value => value == node);
      const currentIndex = keys.findIndex(value => value == this.currentComponentBody);
      const destIndex = keys.findIndex(value => value == node);
      const lastCompletedIndex = values.lastIndexOf(true);
      // Ir mas adelante
      if (destIndex > currentIndex) {
        // Dest verde
        if (this.notificacion.currentComponentValues[dest]) {
          this.currentComponentBody = dest;
        }
        // Dest amarillo
        else {
          var lastCompleted = keys[lastCompletedIndex + 1];
          if ((destIndex - lastCompletedIndex) === 1) {
            this.currentComponentBody = dest;
          }
          else {
            if (lastCompletedIndex === -1) {
              lastCompleted = keys[0]
            }
            this.$toast.warning("Completa la sección " + lastCompleted + " para poder continuar", {
              timeout: 3000, //false,
              hideProgressBar: false,
              position: "top-right",
              closeOnClick: true,
              pauseOnHover: true,
              draggable: false,
              closeButton: "button",
              icon: true,
            });
          }
        }
      }
      // Ir mas atras
      else {
        this.currentComponentBody = dest;
      }
    },

    async getAddressInMap(direcciones_id) {
      if (direcciones_id === null) {
        const address = `${this.currentDomicilio.calle},
                      ${this.currentDomicilio.num_exterior},
                      ${this.currentDomicilio.colonia},
                      ${this.currentDomicilio.delegacion_municipio},
                      ${this.currentDomicilio.codigo_postal},
                      ${this.currentDomicilio.ciudad},
                      ${this.currentDomicilio.entidad}
                      `;
        const mapUrl = await getGoogleMapUrl(address);
        if (mapUrl === null) {
          return
        }
        // this.currentDomicilio.mapUrl = mapUrl;
        this.currentDomicilio.lat = mapUrl.lat.toString(); //parseFloat(mapUrl.lat);
        this.currentDomicilio.lng = mapUrl.lng.toString(); //parseFloat(mapUrl.lng);
        var a = Object.assign({}, this.currentDomicilio);
        this.currentDomicilio = a;
      }
    },

    async postDomicilio() {
      this.loading = true;
      var data = {
        lat: this.currentDomicilio.lat,
        lng: this.currentDomicilio.lng
      };
      try {
        const { nombre_zona, costo } = await FuncionesApi.mapIntersect(data);
        if (nombre_zona === 'c' || nombre_zona === 'd') {
          this.$toast.warning("Para realizar una Notificación en esta zona, por favor contáctate con un asesor vía telefónica", {
            timeout: 20000, //false,
            hideProgressBar: false,
            position: "top-right",
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            closeButton: "button",
            icon: true,
          });
        } else if (nombre_zona === 'e') {
          this.$toast.error("Por el momento no podemos realizar una Notificación en la zona indicada", {
            timeout: 20000, //false,
            hideProgressBar: false,
            position: "top-right",
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            closeButton: "button",
            icon: true,
          });
        } else if (nombre_zona === 'a' || nombre_zona === 'b') {
          this.notificacion.currentComponentValues.Ubicacion = true;
          this.notificacion.costo_zona = costo;
          this.currentComponentBody = 'Documentos';
        }
      } catch (error) {
        this.$toast.error("Sucedió un error, intente más tarde", {
          timeout: 4000, //false,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true,
        });
      }
      this.loading = false;
    },

    saveSolicitantes() {
      if (this.notificacion.solicitantes.length === 0) {
        this.$toast.warning("Debe ingresar al menos 1 solicitante.", {
          timeout: 4000, //false,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true,
        });

        return;
      }

      let principal_hallado = false;
      let principal_es_moral = false;
      let principal_moral_id = 0;
      let representante_hallado = false;

      this.notificacion.solicitantes.forEach(element => {
        if (element.principal) {
          principal_hallado = true;

          if (element.persona === 'Moral') {
            principal_es_moral = true;
            principal_moral_id = element.nombreCompleto;
          }
        }
      });

      if (!principal_hallado) {
        this.$toast.warning("Por favor seleccione 1 solicitante principal.", {
          timeout: 4000,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true,
        });

        return;
      };

      if (!principal_es_moral) {
        this.notificacion.currentComponentValues.Solicitantes = true;
        this.currentComponentBody = "Ubicacion"
      }

      if (principal_es_moral) {
        this.notificacion.solicitantes.forEach(solicitante => {
          if (solicitante.persona === "Física") {
            if (solicitante.representadanombre === principal_moral_id) {
              representante_hallado = true;
              solicitante.signatario = 1;
            }
          }
        });

        if (!representante_hallado) {
          this.$toast.warning("Por favor agregue una persona Física representante del solicitante principal.", {
            timeout: 4000, //false,
            hideProgressBar: false,
            position: "top-right",
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            closeButton: "button",
            icon: true,
          });

          return;
        }

        this.notificacion.currentComponentValues.Solicitantes = true;
        this.currentComponentBody = "Ubicacion"
      }
    },

    saveDocumentos() {
      if (this.notificacion.documentos.length === 0) {
        this.$toast.warning("Debe ingresar al menos 1 documento.", {
          timeout: 4000,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true,
        });

        return;
      }

      this.notificacion.contenido_digital = this.notificacion.documentos.some(d => d.digital);

      this.notificacion.currentComponentValues.Documentos = true;
      this.currentComponentBody = "Cotizacion"
    },

    confirmaCotizacion() {
      this.$refs["modal-confirm-cotizacion"].show();
    },

    async generaCotizacion() {
      this.loading = true;

      let cliente_id = 0;

      try {
        const clienteEmail = storage.getItem("user");
        const { id } = await ClientesApi.clienteId(clienteEmail);

        cliente_id = id;
      } catch (error) {
        this.loading = false;

        console.log(error);
        this.$toast.error("Sucedió un error al obtener información del cliente, intente más tarde", {
          timeout: 4000, //false,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true,
        });

        return;
      }

      this.notificacion.currentComponentValues.Cotizacion = true;

      // Genera Notificacion en BD
      try {
        var fad_api = 0;
        if (this.notificacion.fad) {
          fad_api = 1;
        }
        var acta_express_extra = 0;
        if (this.notificacion.acta_express) {
          acta_express_extra = 1;
        }
        var envio_domicilio_api = 0;
        if (this.notificacion.envio_domicilio) {
          envio_domicilio_api = 1;
        }
        var contenido_digital_api = 0;
        if (this.notificacion.contenido_digital) {
          contenido_digital_api = 1;
        }
        const { id, id_cotizaciones } = await NotificacionesApi.create({
          id_clientes: cliente_id,
          fad: fad_api,
          acta_express_extra: acta_express_extra,
          envio: envio_domicilio_api,
          contenido_digital: contenido_digital_api,
          corredurias_id: 1
        });

        this.notificacion.id = id;
        this.notificacion.id_cotizaciones = id_cotizaciones;
      } catch (error) {
        this.loading = false;

        console.log(error);
        this.$toast.error("Sucedió un error al generar la notificación, intente más tarde", {
          timeout: 4000, //false,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true,
        });

        return;
      }

      // Agrega notificado en BD
      try {
        const { id, id_domicilios } = await NotificadosApi.create({
          id_notificaciones: this.notificacion.id,
        });
        this.notificado.id = id;
        this.notificado.id_domicilios = id_domicilios;
      } catch (error) {
        this.loading = false;

        console.log(error);
        this.$toast.error("Sucedió un error al generar notificado, intente más tarde", {
          timeout: 4000, //false,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true,
        });

        return;
      }

      // Editar Domicilio en BD
      try {
        const { id } = await DomiciliosApi.edit(this.notificado.id_domicilios, {
          calle: this.currentDomicilio.calle,
          num_exterior: this.currentDomicilio.num_exterior,
          num_interior: this.currentDomicilio.num_interior,
          colonia: this.currentDomicilio.colonia,
          delegacion_municipio: this.currentDomicilio.delegacion_municipio,
          ciudad: this.currentDomicilio.ciudad,
          entidad: this.currentDomicilio.entidad,
          pais: this.currentDomicilio.pais,
          codigo_postal: this.currentDomicilio.codigo_postal,
          extranjero: 0,
          tipo: 'Personal',
          tieneExtranjero: null,
          tieneTipo: null,
          lat: this.currentDomicilio.lat,
          lng: this.currentDomicilio.lng,
          valido: 1,
          comentario: null,
          validador: null
        });
        this.currentDomicilio.id = id;
      } catch (error) {
        this.loading = false;

        console.log(error);
        this.$toast.error("Sucedió un error al editar domicilio, intente más tarde", {
          timeout: 4000, //false,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true,
        });

        return;
      }

      // Modifica monto de cotizacion
      try {
        const { id } = await CotizacionesApi.edit(this.notificacion.id_cotizaciones, {
          precio_total: this.notificacion.costo_total,
          activo: 1,
          cupones_id: this.notificacion.cotizacion.cupones_id,
        });
      } catch (error) {
        this.loading = false;
        this.$toast.error("Sucedió un error al crear cotización, intente más tarde", {
          timeout: 4000, //false,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true,
        });
        console.log(error);
        return;
      }

      // Agrega solicitantes
      this.notificacion.solicitantes.forEach(solicitante => {
        if (!('cliente_id' in solicitante)) {
          solicitante.cliente_id = this.cliente_id;
        }
        if (!('signatario' in solicitante)) {
          solicitante.signatario = 0;
        }

        solicitante.id_notificaciones = this.notificacion.id;
      });

      try {
        const { id } = await SolicitantesApi.postClonados({
          solicitantes: this.notificacion.solicitantes
        });
      } catch (error) {
        this.loading = false;

        console.log(error);
        this.$toast.error("Sucedió un error al crear cotización, intente más tarde", {
          timeout: 4000, //false,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true,
        });

        return;
      }

      // Agrega documentos
      await this.agregaDocumentos();

      this.$router.push({
        path: `/dashboard/clienttasks/editnotificacion/${this.notificacion.id}`,
      });
    },

    async agregaDocumentos() {
      let documento_id = 41;

      if (this.notificacion.solicitantes[0].persona == 'Moral') {
        documento_id = 42;
        const poderRepresentante = {
          catDocumentos_id: 40,
          paginas: 1
        }
        this.notificacion.documentos.push(poderRepresentante);
      }
      let inePrincipal = {
        catDocumentos_id: documento_id,
        paginas: 1,
      }
      this.notificacion.documentos.push(inePrincipal);

      for (const documento of this.notificacion.documentos) {
        if (!documento.anexoDe) {
          await this.postDocumento(documento);
        }
      }
    },

    async postDocumento(documento) {
      try {
        this.loading = true;

        let descripcion = null;

        if (documento.catDocumentos_id == 36) {
          descripcion = documento.tipo;
        }

        const { id } = await DocumentosApi.create({
          documento: null,
          cat_documentos_id: documento.catDocumentos_id,
          auto_referencia: documento.anexoDe_nuevo,
          descripcion: descripcion,
          paginas: parseInt(documento.paginas),
          copias_certificadas: null,
          nombre: null
        });

        documento.nuevo_id = id;

        const index = this.notificacion.documentos.findIndex(o => o.id === documento.id);

        if (this.notificacion.documentos[index + 1]) {
          if (this.notificacion.documentos[index + 1].anexoDe) {
            this.notificacion.documentos[index + 1].anexoDe_nuevo = documento.nuevo_id;
            await this.postDocumento(this.notificacion.documentos[index + 1]);
          }
        }

        await DocumentosXServiciosApi.create({
          documentos_id: documento.nuevo_id,
          cat_servicios_id: 2,
          proceso_id: this.notificacion.id
        });
      } catch (error) {
        this.$toast.error("Sucedió un error al crear los documentos, intente más tarde", {
          timeout: 4000, //false,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true,
        });
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
  }
};
</script>

<style scoped></style>
