<template>
  <div>

    <div class="row">
      <div class="col-md-12">
        <ValidationProvider name="catDocumentos_id" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'El tipo de documento es obligatorio',
        }">
          <label for="">Tipo de documento</label>
          <select class="custom-select" v-model="currentDocumento.catDocumentos_id">
            <option v-for="option in options_principal_documento" :key="option.value" :value="option.value">
              {{ option.text }}
            </option>
          </select>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
        <br>
        <!-- <a v-if="currentDocumento.catDocumentos_id !== id_otro" href="https://www.ejemplo.com"
          style="text-decoration: underline;">¿Qué documentos puedo cotejar?</a> -->
      </div>
    </div>

    <div class="row mt-4" v-if="currentDocumento.catDocumentos_id == id_otro">
      <div class="col-md-12">
        <ValidationProvider name="descripcion" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'La descripción del documento es obligatoria',
        }">
          <base-input type="text" label="Descripción" placeholder="Descripción" v-model="currentDocumento.descripcion">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
        <!-- <a href="https://www.ejemplo.com" style="text-decoration: underline;">¿Qué documentos NO puedo cotejar?</a> -->
      </div>
    </div>


    <div class="row mt-4">
      <div class="col-md-12">
        <ValidationProvider name="descripcion" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'La cantidad de copias es obligatoria',
        }">
          <label for="">Número de copias certificadas</label>
          <base-input class="custom-number" type="number" required placeholder="1" :min="0"
            v-model="currentDocumento.copias_certificadas">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-md-12">
        <ValidationProvider name="descripcion" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'La cantidad de páginas es obligatoria',
        }">
          <label for="">Número de páginas del documento sin anexos</label>
          <base-input class="custom-number" type="number" required placeholder="1" :min="0"
            v-model="currentDocumento.paginas">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>



    <div class="row mt-4">
      <div class="col-md-12">
        <ValidationProvider name="anexo" v-slot="{ errors }">
          <!-- <label class="control-label">El documento contiene anexo</label> -->
          <b-form-checkbox v-model="currentDocumento.tiene_anexo">
            <span>El documento contiene anexo</span>
          </b-form-checkbox>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>

    <div class="row mt-4" v-if="currentDocumento.tiene_anexo">
      <div class="col-md-12">
        <ValidationProvider name="descripcion" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'La cantidad de páginas es obligatoria',
        }">
          <label for="">Número de páginas del anexo</label>
          <base-input class="custom-number" type="number" required placeholder="1" :min="1"
            v-model="currentDocumento.paginas_anexo">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>

    <div class="mt-4 text-center">
      <button type="submit" class="btn btn-fill float-right orange-btn">
        Guardar
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    currentDocumento: { type: Object },
    optionsDocumentos: { type: Array },
  },

  created() {
    this.sortOptionsDocumentos();
    this.getIdOtro();
    this.deleteAnexoFromDocuments();
  },

  data() {
    return {
      id_otro: null,
      id_anexo: null,
      options_principal_documento: [],
      is_anexo: false
    };
  },

  methods: {
    sortOptionsDocumentos() {
      const otroIndex = this.optionsDocumentos.findIndex((d) => d.text === 'Otro');
      const otro = this.optionsDocumentos[otroIndex];

      this.optionsDocumentos.splice(otroIndex, 1);
      this.optionsDocumentos.sort((a, b) => {
        if (a.text > b.text) return 1;
        if (a.text < b.text) return -1;
        return 0;
      });
      this.optionsDocumentos.push(otro);
    },

    getIdOtro() {
      this.optionsDocumentos.forEach(element => {
        if (element.text === 'Otro') {
          this.id_otro = element.value;
        }
      })
    },

    deleteAnexoFromDocuments() {
      if (this.currentDocumento.anexoDe) {
        this.options_principal_documento = this.optionsDocumentos;
        this.is_anexo = true;
      } else {
        this.optionsDocumentos.forEach(element => {
          const excludeDocumentos = ['Anexo', 'FAD', 'Carta Responsiva', 'Acta PDF', 'Acuse']
          if (!excludeDocumentos.includes(element.text)) {
            this.options_principal_documento.push(element);
          }
        })
      }
    }
  }
}
</script>

<style scoped>
.control-label {
  position: absolute;
  display: inline-block;
  margin-left: 30px;
}

.custom-number {
  width: 100px !important;
  display: inline-block;
  margin-left: 30px;
}
</style>
