<template>
  <div class="content">
    <b-breadcrumb>
      <b-breadcrumb-item active>Procesos Cliente</b-breadcrumb-item>
    </b-breadcrumb>

    <div class="container">
      <div v-if="loading">
        <div id="loader">
          <font-awesome-icon icon="spinner" spin />
        </div>
      </div>
    </div>

    <b-modal no-close-on-backdrop centered hide-footer hide-header id="facturacion-modal" ref="facturacion-modal"
      size="lg" @close.prevent>
      <FacturacionComponent :datosFacturacion="datosFacturacion" @closedFacturacionEvent="closedFacturacion($event)"
        @canceledFacturacionEvent="canceledFacturacion()" />
    </b-modal>

    <b-modal no-close-on-backdrop centered id="estatus-modal" ref="estatus-modal" size="xl" ok-only
      ok-variant="success">
      <!-- <b-img :src="statusImgUrl" fluid-grow alt="Status image"></b-img> -->
      <div>
        <b-carousel id="carousel-notificaciones-1" :interval="0" indicators background="#ababab">
          <b-carousel-slide :img-src="statusImgUrl">
          </b-carousel-slide>
        </b-carousel>
      </div>
    </b-modal>

    <div class="container-fluid">
      <div>
        <b-card no-body>
          <div class="p-4">
            <div class="row mb-1">
              <b-form-group label="Buscar" label-for="search-input" class="col-4">
                <b-input-group>
                  <b-form-input id="search-input" v-model="searchData" placeholder="Ingrese un término de búsqueda" />
                  <b-input-group-append>
                    <b-button class="orange-btn" :disabled="isTableBusy" @click="handleSearchData">Buscar</b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </div>

            <div class="row">
              <!-- <div class="col-12">
                <base-input v-model="searchData" placeholder="Buscar">
                  <template #label>
                    <label class="control-label">Buscar</label>
                  </template>
</base-input>
</div> -->
              <!-- INFO: Selects for filter data -->
              <div class="col-4">
                <b-form-select class="custom-select" :options="optionsCatServicios" v-model="selectedProceso">
                  <template #first>
                    <b-form-select-option :value="null">Procesos...</b-form-select-option>
                  </template>
                </b-form-select>
              </div>
              <!-- TODO: checar esto -->
              <!-- <div class="col-4">
                <b-form-select class="custom-select" :options="optionsStatus" v-model="selectedStatus">
                  <template #first>
                    <b-form-select-option :value="null">Estatus...</b-form-select-option>
                  </template>
                </b-form-select>
              </div>  -->
            </div>

            <b-table hover :fields="fieldsProcesos" :items="procesos" :busy="isTableBusy" :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc" @sort-changed="sortingChanged">

              <template #cell(index)="{ index }">
                {{ getIndex(index + 1) }}
              </template>

              <template #cell(estatus)="{ item }">
                <b-button id="btn-estatus" variant="success" size="sm" @click="openStatusModal(item)">
                  <b-icon-eye />
                </b-button>
                <b-tooltip target="btn-estatus" triggers="hover">
                  {{ item.estatus }}
                </b-tooltip>
              </template>

              <template #cell(acciones)="{ item }">
                <div class="row align-middle">
                  <router-link :to="{ path: '/dashboard/clienttasks/editconstitucion/' + item.id, }"
                    v-if="item.proceso === 'Constitución de sociedades'">
                    <b-button variant="warning" size="sm" v-b-tooltip.hover title="Editar" class="editBtn">
                      <b-icon-pencil />
                    </b-button>
                  </router-link>
                  <router-link :to="{ path: '/dashboard/clienttasks/editformalizacion/' + item.id, }"
                    v-if="item.proceso === 'Formalización de actas'">
                    <b-button variant="warning" size="sm" v-b-tooltip.hover title="Editar" class="editBtn">
                      <b-icon-pencil />
                    </b-button>
                  </router-link>
                  <router-link :to="{ path: '/dashboard/clienttasks/editnotificacion/' + item.id, }"
                    v-if="item.proceso === 'Notificaciones'">
                    <b-button variant="warning" size="sm" v-b-tooltip.hover title="Editar" class="editBtn">
                      <b-icon-pencil />
                    </b-button>
                  </router-link>
                  <router-link :to="{ path: '/dashboard/clienttasks/editratificacion/' + item.id, }"
                    v-if="item.proceso === 'Ratificación de firmas'">
                    <b-button variant="warning" size="sm" v-b-tooltip.hover title="Editar" class="editBtn">
                      <b-icon-pencil />
                    </b-button>
                  </router-link>
                  <router-link :to="{ path: '/dashboard/clienttasks/editratificaciondigital/' + item.id, }"
                    v-if="item.proceso === 'Firma Digital de Documentos'">
                    <b-button variant="warning" size="sm" v-b-tooltip.hover title="Editar" class="editBtn">
                      <b-icon-pencil />
                    </b-button>
                  </router-link>
                  <router-link :to="{ path: '/dashboard/clienttasks/editcotejo/' + item.id }"
                    v-if="item.proceso === 'Copias certificadas'">
                    <b-button variant="warning" size="sm" v-b-tooltip.hover title="Editar" class="editBtn">
                      <b-icon-pencil />
                    </b-button>
                  </router-link>
                  <div v-if="puedeFacturar(item)">
                    <b-button variant="primary" size="sm" v-b-tooltip.hover title="Consultar factura"
                      @click.prevent="facturacionButtonClicked(item)">
                      <b-icon-receipt />
                    </b-button>
                  </div>
                </div>
              </template>
            </b-table>

            <div class="d-flex my-2">
              <span>
                Ordenado por: <b>{{ sortBy }}</b>, en dirección:
                <b>{{ sortDesc ? "descendente" : "ascendente" }}</b>
              </span>
            </div>
            <div class="d-flex justify-content-between pages">
              <b-pagination v-model="currentPage" :per-page="perPage" :total-rows="totalProcesos" />
              <span>Mostrando {{ procesos.length }} registros de
                {{ totalProcesos }}</span>
            </div>
          </div>
        </b-card>
      </div>
    </div>
  </div>
</template>
<script>
import FacturacionComponent from "@/views/openpay/FacturacionComponent.vue";
import Stepper from "src/views/clients/tasks/Stepper.vue";

import { debounce } from "lodash";

import {
  ClientesApi,
  ConstitucionSociedadesApi,
  CotejosApi,
  CotizacionesApi,
  EstatusApi,
  FacturasApi,
  FormalizacionActasApi,
  FuncionesApi,
  NotificacionesApi,
  RatificacionesApi
} from "@/api";

import Storage from "@/utils/storage";

const storage = new Storage();

export default {
  name: "Tasks",

  components: {
    Stepper,
    FacturacionComponent,
  },

  data() {
    return {
      loading: false,
      clientes_id: null,
      currentPage: 1,
      perPage: 10,
      totalProcesos: 0,
      searchData: "",
      perPageTerminados: 10,
      procesos: [],
      optionsCatServicios: [],
      selectedProceso: null,
      optionsStatus: [],
      selectedStatus: null,
      datosFacturacion: {
        cotizacionId: null,
        alreadyInvoiced: false,
      },

      // Table
      isTableBusy: false,
      sortBy: "creado",
      sortDesc: true,
      sortDirection: "desc",
      total: 0,
      performSearch: debounce(() => {
        this.getData();
      }, 300),

      //Estatus
      statusImgUrl: null,
    };
  },

  watch: {
    currentPage: "getData",
    // searchData: {
    //   handler: "performSearch",
    //   immediate: true,
    // },
    selectedProceso: "getData",
    //selectedStatus: "getData",
  },

  async created() {
    try {
      await this.getData();
      await this.getCatServicios();
      //await this.getEstatus();
    } catch (error) {
      console.log(error);
    }

  },

  computed: {
    fieldsProcesos() {
      let fieldsProcesos = [
        { key: "index", label: "#", class: "text-center" },
        { key: "folio", label: "Folio", class: "text-center", sortable: true },
        { key: "proceso", label: "Proceso", class: "text-center" },
        { key: "cliente", label: "Cliente", class: "text-center", sortable: true },
        { key: "descripcion", label: "Descripción", class: "text-center" },
        { key: "creado", label: "Fecha creación", class: "text-center", sortable: true, sortDirection: "asc" },
        { key: "estatus", label: "Estatus", class: "text-center", sortable: true },
        { key: "acciones", label: "Acciones", class: "text-center" },
      ];
      return fieldsProcesos;
    },
  },

  methods: {
    async handleSearchData() {
      await this.getData();
    },

    async getCatServicios() {
      /*
      Trae la data que llena el select de procesos:
      DATA:
        "Constitución de sociedades",
        "Copias certificadas",
        "Formalización de actas",
        "Notificaciones",
        "Ratificación de firmas",
        "Ratificación de firmas digital",
      */
      try {
        const result = await EstatusApi.procesos({});
        this.optionsCatServicios = result;
      } catch (error) {
        console.log(error);
      }
    },

    async getEstatus() {
      /*
      Trae la data que llena el select de estatus,
      depende del proceso que se seleccione
      */
      try {
        this.optionsStatus = [];
        this.selectedStatus = null;

        this.getData();

        const { value } = await EstatusApi.list({
          page: 0,
          registersPerPage: 0,
          proceso: this.selectedProceso
        });

        value.forEach(element => {
          this.optionsStatus.push({ value: element.tipo, text: element.tipo });
        });

      } catch (error) {
        console.log(error);
      }
    },

    getIndex(index) {
      // return index + (1 - 1) * this.procesos.length;
      return index + (this.currentPage - 1) * this.perPage;
    },

    async getData() {
      this.isTableBusy = true;
      this.loading = true;

      if (!this.clientes_id) {
        // Get the client id if it is not set
        var clienteEmail = storage.getItem("user");
        const { id } = await ClientesApi.clienteId(clienteEmail);
        this.clientes_id = id;
      }

      try {
        const {
          odata_page: page,
          odata_total: total,
          odata_perpage: perPage,
          value,
        } = await FuncionesApi.procesos("Todos", {
          clientes_id: this.clientes_id,
          page: this.currentPage,
          data: this.searchData,
          proceso: this.selectedProceso,
          registersPerPage: this.perPage,
          sort: `${this.sortBy} ${this.sortDirection}`,
        });

        this.currentPage = page;
        this.totalProcesos = total;
        this.procesos = value;
        this.loading = false;
      } catch {
        this.loading = false;
      }
      this.isTableBusy = false;
    },

    puedeFacturar(item) {
      if (item.estatus === "Iniciado") {
        return false;
      }

      return true;
    },

    async facturacionButtonClicked(item) {
      try {
        this.loading = true;

        let proceso = null;

        switch (item.cat_servicios_id) {
          case 1:
            proceso = await ConstitucionSociedadesApi.detail(item.id);
            break;
          case 2:
            proceso = await NotificacionesApi.detail(item.id);
            proceso.cotizaciones_id = proceso.id_cotizaciones;
            break;
          case 3:
            proceso = await FormalizacionActasApi.detail(item.id);
            break;
          case 4:
            proceso = await CotejosApi.detail(item.id);
            proceso.cotizaciones_id = proceso.id_cotizaciones;
            break;
          case 6:
            proceso = await RatificacionesApi.detail(item.id);
            break;
          default:
            return;
        }

        const cotizacion = await CotizacionesApi.detail(proceso.cotizaciones_id);

        // if (cotizacion.forma_pago === 3) {
        //   const msg = "No es posible facturar un servicio pagado mediante monedero. Para mayor información, ponerse en contacto con el equipo de Correduría Digital.";
        //   this.$toast.warning(msg, {
        //     timeout: 10000,
        //     hideProgressBar: false,
        //     position: "top-right",
        //     closeOnClick: true,
        //     pauseOnHover: true,
        //     draggable: false,
        //     closeButton: "button",
        //     icon: true
        //   });

        //   return;
        // }

        if (!cotizacion.pagado) {
          const msg = "El pago de este servicio no se ha efectuado aún.";
          this.$toast.warning(msg, {
            timeout: 10000,
            hideProgressBar: false,
            position: "top-right",
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            closeButton: "button",
            icon: true
          });

          return;
        }

        if (!cotizacion.fecha_pago) {
          const msg = "No es posible determinar ciertos datos necesarios para la facturación, le recomendamos comunicarse con el equipo de Correduría Digital para una mejor asistencia.";
          this.$toast.error(msg, {
            timeout: 10000,
            hideProgressBar: false,
            position: "top-right",
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            closeButton: "button",
            icon: true
          });

          return;
        }

        const fechaPago = new Date(cotizacion.fecha_pago);
        const currentDate = new Date();
        const pastDateTimestamp = currentDate.getTime() - (72 * 60 * 60 * 1000);
        const pastDate = new Date(pastDateTimestamp);

        if (fechaPago < pastDate) {
          const msg = "No es posible realizar la facturación de una pago después de 72 horas.";
          this.$toast.error(msg, {
            timeout: 10000,
            hideProgressBar: false,
            position: "top-right",
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            closeButton: "button",
            icon: true
          });

          return;
        }

        const currentMonthIndex = currentDate.getMonth();
        const paymentMonthIndex = fechaPago.getMonth();

        if (currentMonthIndex > paymentMonthIndex) {
          const msg = "No es posible realizar la facturación de un mes anterior.";
          this.$toast.error(msg, {
            timeout: 10000,
            hideProgressBar: false,
            position: "top-right",
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            closeButton: "button",
            icon: true
          });

          return;
        }

        this.datosFacturacion.alreadyInvoiced = await this.existeFacturaDeCotizacion(cotizacion.id);
        this.datosFacturacion.cotizacionId = cotizacion.id;

        this.$refs["facturacion-modal"].show();
      } catch (error) {
        console.log(error);
        const msg = "Existe un error con el servicio de facturación. Póngase en contacto con el equipo de Correduría Digital para una mejor asistencia.";
        this.$toast.error(msg, {
          timeout: 10000,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true
        });
      } finally {
        this.loading = false;
      }
    },

    async existeFacturaDeCotizacion(cotizacionId) {
      try {
        await FacturasApi.getFacturaByCotizacionId(cotizacionId);

        return true;
      } catch (error) {
        if (error.status === 404) {
          return false;
        }

        console.log(error);

        throw error;
      }
    },

    canceledFacturacion() {
      this.$refs["facturacion-modal"].hide();
      this.loading = false;
    },

    closedFacturacion() {
      this.$refs["facturacion-modal"].hide();
      this.loading = false;
    },

    async sortingChanged(ctx) {
      this.sortBy = ctx.sortBy;
      this.currentPage = 1;
      this.sortDesc = ctx.sortDesc;
      this.sortDirection = ctx.sortDesc ? "DESC" : "ASC";
      this.isTableBusy = true;

      await this.getData();

      this.isTableBusy = false;
    },

    openStatusModal(proceso) {
      // console.log(proceso);
      const success = this.getStatusBySelectedService(proceso.cat_servicios_id, proceso.estatus);

      if (!success) {
        return;
      }

      this.$refs["estatus-modal"].show();
    },

    closeStatusModal() {
      this.$refs["estatus-modal"].hide();
    },

    getStatusBySelectedService(cat_servicios_id, estatus) {
      if (!cat_servicios_id || !estatus) {
        return false;
      }

      let servicio = "";
      let estatusNumber = 0;

      switch (cat_servicios_id) {
        case 1:
          servicio = "constitutiva";

          switch (estatus) {
            case "Iniciado":
              estatusNumber = 1;
              break;
            case "Pagado":
              estatusNumber = 3;
              break;
            case "Documentos entregados":
              estatusNumber = 4;
              break;
            case "Proceso interno":
              estatusNumber = 5;
              break;
            case "Documento listo":
              estatusNumber = 6;
              break;
            case "Finalizado":
              estatusNumber = 7;
              break;
            default:
              break;
          }

          break;
        case 2:
          servicio = "notificaciones";

          switch (estatus) {
            case "Iniciado":
              estatusNumber = 1;
              break;
            case "Pagado":
              estatusNumber = 2;
              break;
            case "Información Completada":
              estatusNumber = 3;
              break;
            case "Proceso interno":
              estatusNumber = 4;
              break;
            case "Documentos entregados":
            case "Documento listo":
            case "Documentos Firmados":
              estatusNumber = 5;
              break;
            case "Diligencia Realizada":
              estatusNumber = 6;
              break;
            case "Finalizado":
              estatusNumber = 7;
              break;
            default:
              break;
          }

          break;
        case 3:
          servicio = "formalizaciones";

          switch (estatus) {
            case "Iniciado":
              estatusNumber = 1;
              break;
            case "Pagado":
              estatusNumber = 3;
              break;
            case "Documentos entregados":
              estatusNumber = 4;
              break;
            case "Proceso interno":
              estatusNumber = 5;
              break;
            case "Segundo proceso interno":
              estatusNumber = 6;
              break;
            case "Documento listo":
              estatusNumber = 7;
              break;
            case "Finalizado":
              estatusNumber = 8;
              break;
            default:
              break;
          }

          break;
        case 4:
          servicio = "cotejos";

          switch (estatus) {
            case "Iniciado":
              estatusNumber = 1;
              break;
            case "Pagado":
              estatusNumber = 2;
              break;
            case "Documentos entregados":
              estatusNumber = 3;
              break;
            case "Proceso de revisión":
              estatusNumber = 4;
              break;
            case "En espera de Firma":
              estatusNumber = 5;
              break;
            case "Documentos firmados":
              estatusNumber = 6;
              break;
            case "Finalizado":
              estatusNumber = 7;
              break;
            default:
              break;
          }

          break;
        case 6:
        case 8:
          servicio = "ratificaciones";

          // switch (estatus) {
          //   case "Iniciado":
          //     estatusNumber = 1;
          //     break;
          //   case "Pagado":
          //     estatusNumber = 2;
          //     break;
          //   case "Documentos entregados":
          //     estatusNumber = 3;
          //     break;
          //   case "Proceso de revisión":
          //     estatusNumber = 4;
          //     break;
          //   case "Pago Complementario":
          //     estatusNumber = 5;
          //     break;
          //   case "Proceso Interno":
          //     estatusNumber = 6;
          //     break;
          //   case "Documento listo":
          //     estatusNumber = 7;
          //     break;
          //   case "Finalizado":
          //     estatusNumber = 7;
          //     break;
          //   case "Información Completada":
          //     estatusNumber = 7;
          //     break;
          //   case "Pagado complementario":
          //     estatusNumber = 7;
          //     break;
          //   case "Documentos firmados":
          //     estatusNumber = 7;
          //     break;
          //   case "En espera de firma":
          //     estatusNumber = 7;
          //     break;
          //   default:
          //     break;
          // }
          break;
        default:
          break;
      }

      if (!servicio || !estatusNumber) {
        return false;
      }

      this.statusImgUrl = require(`@/assets/img/${servicio}/estatus/${estatusNumber}.webp`);

      return true;
    }
  },
};
</script>
