<template>
  <div class="contact-us full-screen mt-4">
    <div v-if="loading">
      <div id="loader">
        <font-awesome-icon icon="spinner" spin />
      </div>
    </div>
    <div class="container">
      <br>
      <h4 slot="header" class="card-title text-warning">Crear cuenta</h4>
      <div class="row">
        <div class="col-12">
          <card id="card">
            <ValidationObserver v-slot="{ handleSubmit }">
              <form @submit.prevent="handleSubmit(register)">
                <Form :user="user" @submit.prevent />
                <button type="submit" :class="loading ? 'disabled' : ''" class="btn orange-btn float-right">
                  Continuar
                </button>
              </form>
            </ValidationObserver>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Card from "src/components/Cards/Card.vue";
import Form from "@/pages/signin/Form.vue";
import { ClientesApi } from "@/api";
import { showToast } from "@/utils/showToast";

export default {
  name: "SignIn",

  components: {
    Card,
    Form
  },

  data() {
    return {
      loading: false,
      user: {
        firstName: null,
        lastNameP: null,
        lastNameM: null,
        email: null,
        password: { password: null, confirm_password: null }
      }
    };
  },

  methods: {
    register() {
      this.loading = true;
      var data = {
        nombre: this.user.firstName,
        paterno: this.user.lastNameP,
        materno: this.user.lastNameM,
        email: this.user.email,
        contraseña: this.user.password.password,
        roles_id: 3
      };
      ClientesApi.create(data)
        .then(response => {
          showToast(
            this,
            "Registro completo, por favor revise su correo electrónico y verifique su cuenta para poder iniciar sesión", {
            timeout: 5000,
          },
            "success"
          );
          this.loading = false;
          this.$router.push("/login");
        })
        .catch(error => {
          this.loading = false;
          console.log(error.status);
          if (error.status == "409") {
            showToast(
              this,
              "Este correo ya está registrado. Si no recuerda su contraseña, dé clic en Olvidé mi contraseña en el apartado Iniciar sesión", {
              timeout: 8000,
            },
              "error"
            );
          } else {
            showToast(this, "Sucedió un error, intente más tarde", "error");
          }
        });
    }
  }
};
</script>

<style scoped>
#card {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  /* color: #9a9a9a; */
  color: #ea640b;
  font-size: 13px;
}
</style>
