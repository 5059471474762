var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('ValidationObserver',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4"},[_c('ValidationProvider',{attrs:{"name":"firstName","rules":"required","custom-messages":{ required: 'El nombre es obligatorio' }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('base-input',{attrs:{"type":"text","label":"Nombre(s)","placeholder":"Nombre(s)"},model:{value:(_vm.user.firstName),callback:function ($$v) {_vm.$set(_vm.user, "firstName", $$v)},expression:"user.firstName"}}),_c('span',{staticClass:"cardErrors"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"col-md-5"},[_c('ValidationProvider',{attrs:{"name":"lastNameP","rules":"required","custom-messages":{
          required: 'El apellido paterno es obligatorio',
        }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('base-input',{attrs:{"type":"text","label":"Apellido Paterno","placeholder":"Apellido Paterno"},model:{value:(_vm.user.lastNameP),callback:function ($$v) {_vm.$set(_vm.user, "lastNameP", $$v)},expression:"user.lastNameP"}}),_c('span',{staticClass:"cardErrors"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"col-md-3"},[_c('base-input',{attrs:{"type":"text","label":"Apellido Materno","placeholder":"Apellido Materno"},model:{value:(_vm.user.lastNameM),callback:function ($$v) {_vm.$set(_vm.user, "lastNameM", $$v)},expression:"user.lastNameM"}})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('ValidationProvider',{attrs:{"name":"email","rules":"required|email","custom-messages":{
          required: 'El email es obligatorio',
          email: 'Debe ser un email válido',
        }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('base-input',{attrs:{"type":"email","label":"Email","placeholder":"Email"},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}}),_c('span',{staticClass:"cardErrors"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"password","rules":{ required: true, min: 8, max: 20, regex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^a-zA-Z\d]).+$/ },"custom-messages":{
            required: 'La contraseña es obligatoria',
            min: 'La contraseña debe tener al menos 8 caracteres',
            max: 'La contraseña debe tener máximo 20 caracteres',
            regex: 'La contraseña debe tener al menos una letra mayúscula, una minúscula, un número y un caracter especial',
          }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('base-input',{attrs:{"type":"password","label":"Contraseña","placeholder":"Contraseña"},model:{value:(_vm.user.password.password),callback:function ($$v) {_vm.$set(_vm.user.password, "password", $$v)},expression:"user.password.password"}}),_c('span',{staticClass:"cardErrors"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"confirm_password","rules":"required|confirmed:password","custom-messages":{
            required: 'La confirmación de contraseña es obligatoria',
            confirmed: 'La contraseña no coincide',
          }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('base-input',{attrs:{"type":"password","label":"Confirmar Contraseña","placeholder":"Confirmar Contraseña"},model:{value:(_vm.user.password.confirm_password),callback:function ($$v) {_vm.$set(_vm.user.password, "confirm_password", $$v)},expression:"user.password.confirm_password"}}),_c('span',{staticClass:"cardErrors"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }