import { render, staticRenderFns } from "./ConstitucionSociedades.vue?vue&type=template&id=61f164ea&scoped=true"
import script from "./ConstitucionSociedades.vue?vue&type=script&lang=js"
export * from "./ConstitucionSociedades.vue?vue&type=script&lang=js"
import style0 from "./ConstitucionSociedades.vue?vue&type=style&index=0&id=61f164ea&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61f164ea",
  null
  
)

export default component.exports