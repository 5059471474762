<template>
  <div>
    <div class="row">
      <div class="col-md-6">
        <ValidationProvider
          name="nombre"
          rules="required"
          v-slot="{ errors }"
          :custom-messages="{ required: 'El nombre es obligatorio' }"
        >
          <base-input
            type="text"
            label="Nombre"
            placeholder="Nombre"
            v-model="currentCupon.nombre"
          >
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="col-md-6">
        <ValidationProvider
          name="tipo"
          rules="required"
          v-slot="{ errors }"
          :custom-messages="{
            required: 'El tipo es obligatorio'
          }"
        >
          <b-form-group id="input-group-tipo-cupon" label="Tipo:" label-for="input-tipo-cupon">
            <b-form-select id="input-tipo-cupon" v-model="currentCupon.tipo" :options="options"></b-form-select>
          </b-form-group>
          <!-- <label for=""> Tipo</label>
          <select
            v-model="currentCupon.tipo"
            class="custom-select"
            :options="options"
            @change="setTipoLetra($event)"
          >
            <option v-for="option in options" :key="option.value">
              {{ option.text }}
            </option>
          </select> -->
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-md-6">
        <ValidationProvider
          name="descuento"
          rules="required"
          v-slot="{ errors }"
          :custom-messages="{
            required: 'El descuento es obligatorio'
          }"
        >
          <base-input
            type="number"
            label="Descuento"
            placeholder="Descuento"
            v-model="currentCupon.descuento"
            :min="0"
          ></base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="col-6">
        <base-input
          type="number"
          label="Número de aplicaciones máximas"
          placeholder="Número de aplicaciones máximas"
          v-model="currentCupon.limiteAplicaciones"
          :min="0"
        ></base-input>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-md-6">
        <label for="fechaInicio">Fecha de inicio</label>
        <b-form-datepicker
          id="fechaInicio"
          reset-button
          v-model="currentCupon.fechaInicio"
          class="mb-2"
        ></b-form-datepicker>
      </div>
      <div class="col-6">
        <label for="fechaFin">Fecha de fin</label>
        <b-form-datepicker
          id="fechaFin"
          reset-button
          v-model="currentCupon.fechaFin"
          class="mb-2"
        ></b-form-datepicker>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-6">
        <b-form-checkbox
          v-model="habilitado"
          name="check-button"
          switch
          @click="habilitaChange($event)"
        >
          Habilitado
        </b-form-checkbox>
      </div>
    </div>

    <div class="text-center mt-3">
      <button type="submit" class="btn btn-fill float-right orange-btn" @click.prevent="validateFields">
        Guardar
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModalCupon",

  props: {
    isEmbedded: {
      type: Boolean,
      default: false,
      required: false
    },

    currentCupon: {
      type: Object,
      default: () => {
        return {
          nombre: String,
          tipo: Number,
          tipo_letra: String,
          descuento: Number,
          habilitado: Number,
          fechaInicio: String,
          fechaFin: String,
          limiteAplicaciones: Number,
          numberoAplicaciones: Number,
          activo: Number,
          creado: String,
          actualizado: String
        }
      },
     }
  },

  watch: {
    habilitado: "habilitaChange"
  },

  created() {
    this.habilitadoCupon();
  },

  data() {
    return {
      descuentoLabel: "Descuento",
      habilitado: true,
      options: [
        // { text: "Seleccione una opción", value: null },
        { text: "Porcentaje", value: 1 },
        { text: "Monto", value: 2 }
      ]
    };
  },

  methods: {
    habilitadoCupon() {
      if (this.currentCupon.habilitado == 0) {
        this.habilitado = false;
      }
    },

    setTipoLetra(event) {
      // debugger;
      return;
      if (event.target.value == "Porcentaje") this.currentCupon.tipo = 1;
      else this.currentCupon.tipo = 2;
    },

    habilitaChange() {
      if (!this.habilitado) this.currentCupon.habilitado = 0;
      else this.currentCupon.habilitado = 1;
    },

    validateFields() {
      if (!this.currentCupon.nombre) {
        this.showError("El nombre del cupón no puede estar vacío.");
        return;
      }
      if (!this.currentCupon.tipo) {
        this.showError("Seleccione el tipo de descuento.");
        return;
      }
      if (!this.currentCupon.descuento) {
        this.showError("Ingrese un monto de descuento válido.");
        return;
      }
      if (!this.currentCupon.limiteAplicaciones && (!this.currentCupon.fechaInicio || !this.currentCupon.fechaFin)) {
        this.showError("Debe ingresar una cantidad máxima de aplicaciones o en su caso fechas de inicio y fin válidas.");
        return;
      }
      if (this.currentCupon.fechaInicio && this.currentCupon.fechaFin) {
        const startDate = new Date(this.currentCupon.fechaInicio);
        const finishDate = new Date(this.currentCupon.fechaFin);

        if (startDate > finishDate) {
          this.showError("La fecha de inicio no puede ser menor a la fecha de fin.");
          return;
        }
      }

      this.$emit("putCupon", true);
    },

    showError(errorMessage = "Error") {
      this.$toast.error(errorMessage, {
        timeout: 4000, //false,
        hideProgressBar: false,
        position: "top-right",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        closeButton: "button",
        icon: true
      });
    },
  }
};
</script>
