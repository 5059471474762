<template>
  <div>
    <div class="row justify-content-center p-2">
      <b-img :src="require('@/assets/img/documentos_entregados.webp')" alt="Documentos entregados" fluid></b-img>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EsperaValidacion'
}
</script>
