<template>
  <div class="wrapper">
    <side-bar>
      <mobile-menu slot="content"></mobile-menu>

      <sidebar-link to="/dashboard">
        <i class="nc-icon nc-notes"></i>
        <p>Procesos</p>
      </sidebar-link>
      <sidebar-link to="/dashboard/staffaccount">
        <i class="nc-icon nc-circle-09"></i>
        <p>Mi cuenta</p>
      </sidebar-link>
      <sidebar-link to="/dashboard/clientes" v-if="corredurias_id == 1">
        <i class="nc-icon nc-settings-tool-66"></i>
        <p>Clientes</p>
      </sidebar-link>
      <sidebar-link to="/dashboard/staff">
        <i class="nc-icon nc-badge"></i>
        <p class="orange-text">Staff</p>
      </sidebar-link>
      <sidebar-link to="/dashboard/comparecientesStf" v-if="corredurias_id == 1">
        <i class="nc-icon nc-grid-45"></i>
        <p>Comparecientes</p>
      </sidebar-link>
      <sidebar-link to="/dashboard/settings" v-if="corredurias_id == 1">
        <i class="nc-icon nc-settings-gear-64"></i>
        <p>Configuraciones</p>
      </sidebar-link>
      <sidebar-link to="/dashboard/corredurias" v-if="corredurias_id == 1">
        <i class="nc-icon nc-paper-2"></i>
        <p>Corredurías</p>
      </sidebar-link>

      <hr />
      <div>
        <li class="nav-item">
          <a href="/" class="nav-link" @click="logout"> Cerrar sesión </a>
        </li>
      </div>
    </side-bar>

    <div class="main-panel">
      <dashboard-content @click="toggleSidebar"> </dashboard-content>
      <content-footer> </content-footer>
    </div>
  </div>
</template>

<script>
import ContentFooter from "@/layout/ContentFooter.vue";
import MobileMenu from "@/layout/MobileMenu.vue";
import DashboardContent from "@/views/staff/dashboard_content/DashboardContent.vue";

import Storage from "@/utils/storage";

const storage = new Storage();

export default {
  name: "DashboardStaff",

  components: {
    ContentFooter,
    DashboardContent,
    MobileMenu
  },

  created() {
    this.corredurias_id = storage.getItem("corredurias_id");
  },

  data: () => ({
    corredurias_id: null
  }),

  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },

    logout() {
      //   storage.removeItem("user");
      storage.removeItem("role");
      storage.removeItem("logged");
      storage.removeItem("acessToken");
      // storage.saveItem("logged", false);
      // storage.saveItem("role", null);
      this.role = null;
      this.logged = false;
      this.$router.push("/"); //"/"  { name: "Home" }
    }
  }
};
</script>

<style scoped>
#logOu:hover {
  box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset,
    rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset,
    rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px,
    rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px,
    rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
}
</style>
