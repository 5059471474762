<template>
  <div class="content text-center">
    <div class="container-fluid mt-5 mb-5">
      <ModalPreviewVideo :current-documento="videoObject"></ModalPreviewVideo>

      <!-- <div class="col-10 ">
        <b-card>
          <div class="typo-line">
            <h4 class="text-warning">
              Somos una plataforma pensada en acercar servicios de fe pública
              mercantil y valuación a empresarios y emprendedores, sea cual
              sea el tamaño de su idea o negocio.
              <br />
            </h4>

            <h4 class="mt-4 descripcion">
              Correduría Digital ha sido imaginada y puesta en marcha, como
              una herramienta que permitirá hacer más cercanos a la gente,
              emprendedores, comerciantes y empresarios, los servicios del
              Corredor Público, en cualquier etapa de la vida comercial. A
              través de esta plataforma, podrás: crear tu empresa; solicitar
              copias certificadas de una forma rápida y accesible; agendar una
              fe de hechos o solicitar una notificación o requerimiento, que
              requiera tu negocio; pedir ratificaciones de firmas o
              certificación de documentos a un Corredor Público; o bien,
              solicitar que valuemos tu negocio o alguno de sus activos.
            </h4>

            <h4 class="descripcion">
              En fin, lo que buscamos es participar de forma más directa y
              cercana contigo.
            </h4>

            <h4 class="descripcion">
              Hoy en día, gracias a Internet y a las aplicaciones y
              plataformas digitales, los negocios no conocen tiempos ni
              fronteras. Nos hemos esforzado para estar a la vanguardia en
              innovación tecnológica aplicable a cuestiones legales y fe
              pública, siempre actuando dentro del margen que permiten
              nuestras leyes. No olvidemos que lo más importante es brindar
              certeza y seguridad jurídica a nuestros usuarios.
            </h4>

            <h4 class="descripcion">
              Esperamos que tu idea de negocio se aterrice, que puedas crear o
              hacer crecer tu empresa, y logres tus objetivos empresariales.
              Nosotros te acompañaremos en cada paso, dándote las herramientas
              que requieres para “blindar” tus operaciones. Esperamos que nos
              consideres como una opción competitiva y accesible.
            </h4>

            <h4 class="descripcion">
              Correduría Digital está pensada para todos: pequeños y grandes
              comerciantes, nuevos y antiguos empresarios, cualquier navegante
              en aguas mercantiles.
            </h4>

            <h4 class="text-warning">
              ¡Que disfrutes el viaje de los negocios!
            </h4>
          </div>

        </b-card> 
      </div> -->
    </div>
  </div>
</template>

<script>
import ModalPreviewVideo from "@/views/clients/procesos/constitucion/entregadocumentos/ModalPreviewVideo.vue";

import config from "@/config";

export default {
  components: {
    ModalPreviewVideo
  },

  data() {
    return {
      videoObject: {
        documento: config.STG_BASE_URL + "/assets/videos/correduria_digital_landing_page.mp4" + config.STG_ACCESS_KEY
      }
    }
  },
};
</script>

<style>
.descripcion {
  font-family: Verdana, Geneva, Tahoma, sans-serif !important;
  /* color: #9a9a9a; */
  /* color: #ea640b; */
  font-size: 18px;
}
</style>
