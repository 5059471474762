<template>
  <div>
    <div class="row">
      <div class="col-md-6">
        <ValidationProvider name="servicio" rules="required" v-slot="{ errors }"
          :custom-messages="{ required: 'El servicio es obligatorio' }">
          <base-input type="text" label="Servicio" placeholder="Servicio" v-model="currentGasto.servicio">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="col-md-6">
        <ValidationProvider name="costo" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'El costo es obligatorio'
        }">
          <base-input type="number" label="Costo" placeholder="Costo" v-model="currentGasto.costo">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <ValidationProvider name="proceso" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'El proceso es obligatorio'
        }">
          <label for=""> Proceso</label>
          <select v-model="currentGasto.proceso" class="custom-select" :options="optionsProcesos">
            <option v-for="option in optionsProcesos" :key="option.value">
              {{ option.text }}
            </option>
          </select>

          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>

    <div class="text-center mt-3">
      <button type="submit" class="btn btn-fill float-right orange-btn">
        Guardar
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    currentGasto: { type: Object }
  },

  data() {
    return {
      optionsProcesos: [
        { value: "1", text: "Constitución de sociedades" },
        { value: "2", text: "Notificaciones" },
        { value: "3", text: "Formalización de actas" },
        { value: "4", text: "Copias certificadas" },
        { value: "5", text: "Fes de hechos" },
        { value: "6", text: "Ratificación de firmas" },
        { value: "7", text: "Avalúos de mejoras" }
      ],

      // form: {
      //   servicio: null,
      //   costo: null,
      //   proceso: null
      // }
    };
  },

  methods: {}
};
</script>
