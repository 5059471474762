<template>
  <div>
    <h4 class="cardD" v-if="currentDomicilio.compareciente">
      Es necesario registrar un domicilio en México para correspondencia
    </h4>

    <h4 class="cardD" v-if="currentDomicilio.notificaciones">
      Verificar disponibilidad de Notificado
    </h4>

    <h4 class="cardD" v-if="currentDomicilio.cotejo || currentDomicilio.ratificacion">
      Domicilio de envío de los documentos.
    </h4>

    <div class="row">
      <div class="col-12">
        <div class="row">
          <div class="col-md-6">
            <ValidationProvider name="calle" rules="required" v-slot="{ errors }"
              :custom-messages="{ required: 'La calle es obligatoria', }">
              <base-input type="text" label="Calle / Avenida / Vía" placeholder="Calle"
                v-model="currentDomicilio.calle">
              </base-input>
              <span class="cardErrors">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
          <div class="col-md-3">
            <ValidationProvider name="num_exterior" rules="required" v-slot="{ errors }" :custom-messages="{
              required: 'El número exterior es obligatorio',
            }">
              <base-input type="text" label="Número exterior" placeholder="Número exterior"
                v-model="currentDomicilio.num_exterior">
              </base-input>
              <span class="cardErrors">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
          <div class="col-md-3">
            <base-input type="text" label="Número interior" placeholder="Número interior"
              v-model="currentDomicilio.num_interior">
            </base-input>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <ValidationProvider name="colonia" rules="required" v-slot="{ errors }" :custom-messages="{
              required: 'La colonia es obligatoria',
            }">
              <base-input type="text" label="Colonia o Urbanización" placeholder="Colonia o Urbanización"
                v-model="currentDomicilio.colonia">
              </base-input>
              <span class="cardErrors">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
          <div class="col-md-6">
            <ValidationProvider name="codigo_postal" rules="required|numeric" v-slot="{ errors }" :custom-messages="{
              required: 'El código postal es obligatorio',
              numeric: 'El código postal debe ser un número',
            }">
              <base-input type="text" label="Código Postal" placeholder="Código Postal"
                v-model="currentDomicilio.codigo_postal">
              </base-input>
              <span class="cardErrors">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
        </div>

        <!-- <div class="row">
          <div class="col-md-3">
            <ValidationProvider name="pais" rules="required" v-slot="{ errors }" :custom-messages="{
              required: 'El país es obligatorio',
            }">
              <label>País</label>
              <select class="form-control" v-model="country" :disabled="disableFields.disablePais">
                <option v-for="option in options" :key="option.value">
                  {{ option.text }}
                </option>
              </select>
              <span class="cardErrors">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
          <div class="col-md-3">
            <ValidationProvider name="entidad" rules="required" v-slot="{ errors }" :custom-messages="{
              required: 'La entidad es obligatoria',
            }">
              <base-input type="text" label="Entidad Federativa" placeholder="Entidad Federativa"
                v-model="currentDomicilio.entidad">
              </base-input>
              <span class="cardErrors">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
          <div class="col-md-3">
            <ValidationProvider name="delegacion_municipio" rules="required" v-slot="{ errors }" :custom-messages="{
              required: 'La demarcación territorial es obligatoria',
            }">
              <base-input type="text" label="Demarcación Territorial o Municipio"
                placeholder="Demarcación Territorial o Municipio" v-model="currentDomicilio.delegacion_municipio">
              </base-input>
              <span class="cardErrors">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
          <div class="col-md-3">
            <ValidationProvider name="ciudad" rules="required" v-slot="{ errors }" :custom-messages="{
              required: 'La ciudad o población es obligatoria',
            }">
              <base-input type="text" label="Ciudad o Población" placeholder="Ciudad o Población"
                v-model="currentDomicilio.ciudad">
              </base-input>
              <span class="cardErrors">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
        </div> -->

        <div class="row">
          <div class="col-md-4">
            <ValidationProvider name="pais" rules="required" v-slot="{ errors }"
              :custom-messages="{ required: 'El país es obligatorio' }">
              <label>País</label>
              <select @change="fetchStatesByCountry" v-model="selectCountry" class="form-control"
                :disabled="disableFields.disablePais">
                <option v-for="option in countries" :key="option.id">
                  {{ option.name }}
                </option>
              </select>
              <span class="cardErrors">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
          <div class="col-md-4">
            <ValidationProvider name="entidad" rules="required" v-slot="{ errors }" :custom-messages="{
              required: 'La entidad es obligatoria',
            }">
              <label>Entidad Federativa</label>
              <select @change="fetchCitiesByState" v-model="selectState" class="form-control" :disabled="false">
                <option v-if="isEmptyStates" value="noStates" selected>
                  {{ noStatesText }}
                </option>
                <option v-for="option in states" :key="option.id">
                  {{ option.name }}
                </option>
              </select>
              <span class="cardErrors">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
          <div class="col-md-4">
            <ValidationProvider name="delegacion_municipio" rules="required" v-slot="{ errors }"
              :custom-messages="{ required: 'La demarcación territorial es obligatoria' }">
              <label>Demarcación Territorial o Municipio</label>
              <select v-model="selectCity" class="form-control" :disabled="false">
                <option v-if="isEmptyCities" value="noCities" selected>
                  {{ noCitiesText }}
                </option>
                <option v-for="option in cities" :key="option.id">
                  {{ option.name }}
                </option>
              </select>
              <span class="cardErrors">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
        </div>

        <div class="row">
          <div class="col-md-3" v-if="currentDomicilio.tieneExtranjero">
            <label for="">Domicilio en el extranjero</label>
            <b-form-checkbox v-model="currentDomicilio.extranjero">
            </b-form-checkbox>
          </div>
          <div v-if="currentDomicilio.tieneTipo" class="col-md-3">
            <ValidationProvider name="tipo" rules="required" v-slot="{ errors }" :custom-messages="{
              required: 'El tipo es obligatorio',
            }">
              <label v-if="currentDomicilio.tieneTipo" for="">Tipo</label>
              <select v-model="currentDomicilio.tipo" class="custom-select" v-if="currentDomicilio.tieneTipo">
                <option value="Particular y Fiscal">Particular y Fiscal</option>
                <option value="Particular">Particular</option>
                <option value="Fiscal">Fiscal</option>
                <option value="Extranjero">Extranjero</option>
                <!-- <option value="Personal" disabled>Personal</option> -->
              </select>

              <base-input type="text" placeholder="Tipo" v-model="currentDomicilio.tipo"
                v-if="currentDomicilio.tipo === 'Personal'" disabled>
              </base-input>
              <span class="cardErrors">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
        </div>
      </div>

      <div class="col-12 mt-3">
        <span class="warningData">Puede colocar el marcador en el mapa indicando el domicilio exacto</span>
        <DrawGoogleMap :lat="latitude" :lng="longitude" @location="updateCoordinates" />
      </div>
    </div>

    <div class="row" v-if="
      !currentDomicilio.staff && (currentDomicilio.notificaciones || currentDomicilio.cotejo || currentDomicilio.ratificacion)
    ">
      <div class="col-12 mt-3" id="form-checkbox">
        <ValidationProvider name="validacionDatos" :rules="{ required: { allowFalse: false } }" v-slot="{ errors }"
          :custom-messages="{
            required: 'La confirmación es obligatoria',
          }">
          <b-form-checkbox id="checkbox-validacionDatos" v-model="validacionDatos" class="orange">
            Confirmo que los datos proporcionados y la ubicación mostrada en el mapa son correctos
          </b-form-checkbox>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>
    <!-- INFO:  :disabled="currentDomicilio.disabled" -->
    <!-- TODO: Revisar que pasa con el currentDomicilio.disabled -->
    <div class="text-center" v-if="!hideSaveButton">
      <b-button type="submit" class="btn btn-fill float-right orange-btn">Guardar</b-button>
    </div>
    <!-- <div class="text-center">
      <button
        type="submit"
        class="btn btn-fill float-right orange-btn"
        :disabled="(currentDomicilio.notificaciones && currentDomicilio.staff && currentDomicilio.disabled) ||
                    (currentDomicilio.ratificacion && currentDomicilio.disabled)"
      >
        Guardar
      </button>
    </div> -->
  </div>
</template>

<script>
import DrawGoogleMap from "@/views/clients/comparecientes/DrawGoogleMap.vue";
import { mapState, mapGetters, mapActions } from "vuex";

// currente domicilio
export default {
  props: {
    currentDomicilio: { type: Object },
    disableFields: {
      // Objeto para desactivar o poner campos como readonly
      type: Object,
      default: () => {
        return {
          disablePais: false,
        };
      },
    },
    hideSaveButton: {
      type: Boolean,
      default: () => false,
    }
  },

  components: {
    DrawGoogleMap,
  },

  computed: {
    ...mapState([
      "countries",
      "states",
      "cities",
      "isEmptyStates",
      "isEmptyCities",
    ]),

    ...mapGetters([
      "getCountries",
      "getStatesByCountryId",
      "getCitiesByStateId",
    ]),

    latitude() {
      return parseFloat(this.currentDomicilio.lat) || 19.4320605;
    },
    longitude() {
      return parseFloat(this.currentDomicilio.lng) || -99.1335938;
    },
  },

  watch: {
    "validacionDatos": {
      handler(newValue) {
        this.currentDomicilio.validacionDatos = this.validacionDatos;
      },
      immediate: true,
    },
    "selectCountry": function (val, oldVal) {
      this.currentDomicilio.pais = this.selectCountry;
    },
    "selectState": function (val, oldVal) {
      this.currentDomicilio.entidad = this.selectState;
    },
    "selectCity": function (val, oldVal) {
      this.currentDomicilio.delegacion_municipio = this.selectCity;
    }
  },

  async mounted() {
    await this.fetchCountries();

    if (this.currentDomicilio.pais && this.currentDomicilio.entidad && this.currentDomicilio.delegacion_municipio) {
      this.selectCountry = this.currentDomicilio.pais;

      if (this.currentDomicilio.pais) {
        await this.getExistCountry(this.currentDomicilio.pais);
      }
      if (this.currentDomicilio.entidad) {
        await this.getExistState(this.currentDomicilio.entidad);
      }
      if (this.currentDomicilio.delegacion_municipio) {
        await this.getExistCity(this.currentDomicilio.delegacion_municipio);
      }
    } else {
      this.country = "México";
      this.selectCountry = this.country;
      this.currentDomicilio.pais = this.country;

      await this.fetchStatesByCountry();
    }

    // Se coloca para que se muestre en la b-table como row details
    this.currentDomicilio._showDetails = true;
  },

  data() {
    return {
      options: [{ text: "México", value: "México" }],
      validacionDatos: false,
      form: {
        calle: null,
        num_exterior: null,
        num_interior: null,
        colonia: null,
        delegacion_municipio: null,
        ciudad: null,
        entidad: null,
        pais: null,
        codigo_postal: null,
        extranjero: null,
        tieneExtranjero: true,
        tipo: null,
        tieneTipo: true,

        compareciente: null,
        // mapUrl: null,
        lat: null,
        lng: null,
      },
      country: null,

      noStatesText: "No hay estados disponibles",
      noCitiesText: "No hay ciudades disponibles",
      selectCountry: "",
      selectState: "",
      selectCity: "",
    };
  },

  methods: {
    ...mapActions(["fetchCountries", "fetchStates", "fetchCities"]),

    async fetchStatesByCountry() {
      /**
       * Esta fx se llama solo si ya se tiene un país seleccionado
       */
      this.selectState = "";
      const pais = this.selectCountry;
      this.selectCity = "";
      // change store state cities to empty
      this.$store.commit("setCities", []);
      // Obtengo el id del país
      const paisId = await this.getIdCountry(pais);
      // obtengo el código de area del país
      const phoneCode = await this.getPhoneCode(pais);
      this.phoneKey = phoneCode;
      // this.currentCompareciente.telefonos[0].clave = phoneCode;
      // obtengo los estados del país
      await this.fetchStates(paisId);
      if (this.isEmptyStates) {
        this.selectState = "noStates";
        this.selectCity = "noCities";
      }
    },

    async fetchCitiesByState() {
      /**
       * Se llama para obtener las ciudades de un estado seleccionado
       */
      this.selectCity = "";
      const estado = this.selectState
      const estadoId = await this.getIdState(estado);
      await this.fetchCities(estadoId);
    },

    async getIdCountry(countryName) {
      /**
       * Obtiene el id del país seleccionado
       */
      const country = this.countries.find((c) => c.name === countryName);
      return country ? country.id : null;
    },

    async getIdState(stateName) {
      /**
       * Obtiene el id del estado seleccionado
       */
      const state = this.states.find((s) => s.name === stateName);
      return state ? state.id : null;
    },

    async getPhoneCode(countryName) {
      /**
       * Obtiene el código de área del país seleccionado
       */
      const country = this.countries.find((c) => c.name === countryName);
      return country ? country.phone_code : null;
    },

    async getExistCountry(countryName) {
      /**
       * Se llama si ya se tiene un país seleccionado
       */
      const currentCountry = countryName === "Mexico" ? "México" : this.currentDomicilio.pais;
      this.selectCountry = currentCountry;
    },

    async getExistState(stateName) {
      /**
       * Se llama si ya se tiene un estado seleccionado
       */
      const currentState = stateName
      this.selectState = currentState;
      const paisId = await this.getIdCountry(this.selectCountry);
      await this.fetchStates(paisId);
    },

    async getExistCity(cityName) {
      /**
       * Se llama si ya se tiene una ciudad seleccionada
       */
      const currentCity = cityName;
      this.selectCity = currentCity;
      const estadoId = await this.getIdState(this.selectState);
      await this.fetchCities(estadoId);
    },

    // changePais(pais) {
    //   this.$emit("pais", pais);
    // },

    updateCoordinates(coordinates) {
      // console.log("updateCoordModalDom ");
      // console.log(coordinates.lat);
      // console.log(coordinates.lng);
      this.currentDomicilio.lat = coordinates.lat;
      this.currentDomicilio.lng = coordinates.lng;
      //estos datos solo se guardan, no se llevan a direccion
    },
  },
};
</script>
