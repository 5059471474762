<template>
  <div class="container">
    <h4 class="cardD">Finalización de proceso</h4>

    <div class="mt-4">
      <div class="row ">
        <div class="col-12">
          <b-button type="submit" block class="btn-fill orange-btn bottom2" size="m"
            :disabled="notificacion.id_estatus == 13">Dar proceso por terminado</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "Terminado",

  props: {
    notificacion: { type: Object },
  },
}
</script>

<style scoped>
.bottom1 {
  float: left;
}

.green-btn {
  background-color: green;
}

.container>>>.validacionDatosLabel {
  position: absolute;
  margin-left: 30px;
  top: 25%;
  max-width: 1500px;
}
</style>