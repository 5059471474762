export function showToast(vm, message, options, type) {
  const toastOptions = {
    timeout: options.timeout || 4000,
    hideProgressBar: options.hideProgressBar || false,
    position: options.position || "top-right",
    closeOnClick: options.closeOnClick || true,
    pauseOnHover: options.pauseOnHover || true,
    draggable: options.draggable || false,
    closeButton: options.closeButton || "button",
    icon: options.icon || true,
  };

  // Agregar tipo de notificación al toastOptions
  if (type === "success") {
    return vm.$toast.success(message, toastOptions);
  } else if (type === "error") {
    return vm.$toast.error(message, toastOptions);
  } else if (type === "info") {
    return vm.$toast.info(message, toastOptions);
  } else if (type === "warning") {
    return vm.$toast.warning(message, toastOptions);
  } else {
    // Por defecto, mostrar como success si no se especifica un tipo válido
    return vm.$toast.success(message, toastOptions);
  }
}
