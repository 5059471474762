<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <ValidationProvider name="nombre" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'El nombre del documento es obligatorio',
        }">
          <label for="">Título del documento</label>
          <base-input type="text" placeholder="Nombre" v-model="currentDocumento.nombre">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-md-12">
        <ValidationProvider name="paginas" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'La cantidad de páginas es obligatoria',
        }">
          <label for="">Número de páginas del documento</label>
          <base-input class="custom-number" type="number" required placeholder="1" :min="1"
            v-model="currentDocumento.paginas">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-md-12">
        <ValidationProvider name="anexo" v-slot="{ errors }">
          <b-form-checkbox id="chkTieneAnexo" v-model="currentDocumento.tiene_anexo">
            <label for="chkTieneAnexo" class="">El documento contiene anexo</label>
          </b-form-checkbox>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>

    <div class="row mt-4" v-if="currentDocumento.tiene_anexo">
      <div class="col-md-12">
        <ValidationProvider name="numero_anexos" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'La cantidad de páginas es obligatoria',
        }">
          <label for="">Número de anexos</label>
          <base-input class="custom-number" type="number" required placeholder="1" :min="1"
            v-model="currentDocumento.numero_anexos">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>

    <div v-if="currentDocumento.tiene_anexo">
      <div class="row mt-4" v-for="(anexo, index) in currentDocumento.numero_anexos" :key="index">
        <div class="col-md-12">
          <ValidationProvider name="paginas_anexo" rules="required" v-slot="{ errors }" :custom-messages="{
            required: 'La cantidad de páginas es obligatoria',
          }">
            <label for="">Número de páginas del anexo {{ anexo }}</label>
            <base-input class="custom-number" type="number" required placeholder="1" :min="1"
              v-model="currentDocumento.paginas_anexos[index]">
            </base-input>
            <span class="cardErrors">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
      </div>
    </div>

    <div class="mt-4 text-center">
      <button type="submit" class="btn btn-fill float-right orange-btn">
        Guardar
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    currentDocumento: { type: Object },
    optionsDocumentos: { type: Array },
  },

  created() {
  },

  data() {
    return {
    };
  },

  watch: {
    'currentDocumento.numero_anexos': {
      handler(newValue) {
        this.currentDocumento.numero_anexos = parseInt(newValue);
        this.initializePaginasAnexos(this.currentDocumento.numero_anexos);
      },
      immediate: true,
    },
  },
  methods: {
    initializePaginasAnexos(num) {
      const currentPaginas = this.currentDocumento.paginas_anexos;
      console.log(currentPaginas);
      this.currentDocumento.paginas_anexos = Array(num);
      for (let i = 0; i < currentPaginas.length; i++) {
        this.currentDocumento.paginas_anexos[i] = currentPaginas[i];
      }
    },
  },
}
</script>

<style scoped>
.control-label {
  position: absolute;
  display: inline-block;
  margin-left: 30px;
}

.custom-number {
  width: 100px !important;
  display: inline-block;
  margin-left: 30px;
}
</style>
