<template>
  <div>
    <div id="doc">
      <video id="my-player" class="video-js" controls preload="auto" data-setup="{}">
        <source :src="currentDocumento.documento" type="video/mp4" />
      </video>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModalPreviewVideo",

  props: {
    currentDocumento: { type: Object }
  }
};
</script>

<style scoped>
#doc {
  width: 80%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

#my-player {
  text-align: center;
  margin: 0 auto;
  width: 100%;
}
</style>
