import { render, staticRenderFns } from "./ModalRepresentante.vue?vue&type=template&id=484d312e&scoped=true"
import script from "./ModalRepresentante.vue?vue&type=script&lang=js"
export * from "./ModalRepresentante.vue?vue&type=script&lang=js"
import style0 from "./ModalRepresentante.vue?vue&type=style&index=0&id=484d312e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "484d312e",
  null
  
)

export default component.exports