<template>
  <footer class="footer">
    <div class="container-fluid">
      <div class="copyright">
        &copy; {{ new Date().getFullYear() }} Correduría Digital
      </div>
      <div class="copyright">
        <a :href="urlTerminosYCondiciones" target="_blank">Términos y Condiciones</a>
      </div>
      <div class="copyright">
        <a :href="urlAvisoDePrivacidad" target="_blank">Aviso de Privacidad</a>
      </div>
    </div>
  </footer>
</template>

<script>
import config from '@/config';

export default {
  name: "Footer",

  data: () => ({
    urlAvisoDePrivacidad: config.STG_BASE_URL + "/assets/documents/aviso_de_privacidad_cd_v1.pdf" + config.STG_ACCESS_KEY,
    urlTerminosYCondiciones: config.STG_BASE_URL + "/assets/documents/terminos_y_condiciones_cd_v2.pdf" + config.STG_ACCESS_KEY,
  }),
};
</script>

<style>
#copyright {
  margin-bottom: 0;
}
</style>
