<template>
  <div class="content">
    <b-breadcrumb>
      <b-breadcrumb-item active>Clientes</b-breadcrumb-item>
    </b-breadcrumb>

    <div class="container">
      <div v-if="loading">
        <div id="loader">
          <font-awesome-icon icon="spinner" spin />
        </div>
      </div>
    </div>

    <div class="container-fluid">
      <div class="row">
        <div class="col-4">
          <base-input v-model="searchCliente" placeholder="Buscar cliente"></base-input>
        </div>
        <div class="col-4">
          <select v-model="rolSearch" @change="getClientesByRol($event)" class="custom-select">
            <option value="0">Todos los roles</option>
            <option value="3">Cliente Administrador</option>
            <option value="4">Cliente Usuario</option>
          </select>
        </div>
        <div class="col-4">
          <select v-model="personaSearch" @change="getClientesByPersona($event)" class="custom-select">
            <option value="null2">Todas las personas</option>
            <option value="Moral">Moral</option>
            <option value="Física">Física</option>
          </select>
        </div>

        <div class="col-12">
          <card class="strpied-tabled-with-hover" body-classes="table-full-width table-responsive">
            <template slot="header">
              <h4 class="card-title">Clientes</h4>
            </template>

            <!-- <vue-confirm-dialog></vue-confirm-dialog> -->
            <b-modal no-close-on-backdrop centered ref="modal-confirm-dialog" hide-footer id="modal-confirm-dialog">
              <h4 class="text-center">
                ¿Seguro que quiere eliminar al cliente?
              </h4>
              <h5 class="text-center">Se eliminarán todos los datos.</h5>
              <b-button class="mt-3" variant="danger" @click="$bvModal.hide('modal-confirm-dialog')">Cancelar</b-button>
              <b-button class="mt-3 float-right" variant="primary" @click="deleteCliente()">OK</b-button>
            </b-modal>

            <div class="text-center">
              <b-table :fields="fields" :items="clientes" hover>
                <template #cell(index)="{ index }">
                  {{ getIndex(index + 1) }}
                </template>
                <template #cell(acciones)="{ item }">
                  <router-link :to="{ path: '/dashboard/clientes/editcliente/' + item.id }">
                    <b-button variant="warning" size="sm" v-b-tooltip.hover title="Editar" class="editBtn">
                      <b-icon-pencil />
                    </b-button>
                  </router-link>

                  <b-button variant="danger" @click="predeleteCliente(item.id)" size="sm" v-b-tooltip.hover
                    title="Eliminar">
                    <b-icon-trash />
                  </b-button>
                </template>
              </b-table>
            </div>

            <div class="d-flex justify-content-between pages">
              <b-pagination v-model="currentPage" :per-page="perPage" :total-rows="totalClientes" />

              <span>Mostrando {{ clientes.length }} registros de
                {{ totalClientes }}</span>
            </div>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import LTable from "src/components/Table.vue";
// import Card from "src/components/Cards/Card.vue";
import { ClientesApi } from "@/api";
import { RolesApi } from "@/api";

export default {
  name: "Clientes",

  data: () => ({
    loading: false,
    currentPage: 1,
    perPage: 10,
    rolSearch: null,
    personaSearch: null,
    // totalItems: 0,
    totalClientes: 0,
    searchCliente: null,

    roles: [],
    clientes: [],

    currentClienteId: null,
  }),

  computed: {
    fields() {
      let fields = [
        { key: "index", label: "", class: "text-center" },
        {
          key: "nombre_comercial",
          label: "Nombre comercial",
          class: "text-center",
        },
        { key: "nombre", label: "Nombre", class: "text-center" },
        { key: "paterno", label: "Paterno", class: "text-center" },
        { key: "materno", label: "Materno", class: "text-center" },
        { key: "email", label: "Email", class: "text-center" },
        { key: "rol", label: "Rol", class: "text-center" },
        { key: "acciones", label: "Acciones", class: "text-center" },
      ];
      return fields;
    },
  },

  watch: {
    currentPage: "getClientes",
    searchCliente: "getClientes",
  },
  //  data() {
  //   return {
  //     table1: {
  //       columns: [...tableColumns],
  //       data: [...tableData1],
  //     },
  //   };
  // },

  // components: {
  //   // LTable,
  //   Card,
  // },

  created() {
    // this.getRoles();
    this.getClientes();
  },

  methods: {
    getIndex(index) {
      return index + (this.currentPage - 1) * this.perPage;
    },

    async getClientes() {
      try {
        this.loading = true;
        const {
          odata_page: page,
          odata_total: total,
          odata_perpage: perPage,
          value,
        } = await ClientesApi.list({
          page: this.currentPage,
          data: this.searchCliente,
          registersPerPage: this.perPage,
          rol: this.rolSearch,
          persona: this.personaSearch,
          // usuario: this.usuario,
        });
        // this.clientes = value;
        this.currentPage = page;
        this.totalClientes = total;

        await this.getRoles();

        for (let i = 0; i < value.length; i++) {
          var role_id = value[i].roles_id;
          var obj = this.roles.find((o) => o.id === role_id);
          value[i].rol = obj.rol;
        }
        this.clientes = value;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    async getClientesByRol(event) {
      let value = event.target.value;
      this.rolSearch = value;
      if (value === 0) {
        this.rolSearch = null;
      }
      this.getClientes();
    },

    async getClientesByPersona(event) {
      let value = event.target.value;
      this.personaSearch = value;
      if (value === "null2") {
        this.personaSearch = null;
      }
      this.getClientes();
    },

    async getRoles() {
      try {
        const { value } = await RolesApi.list({
          page: 0,
          registersPerPage: 0,
        });
        this.roles = value;
      } catch (error) {
        // this.$swal({
      }
    },

    predeleteCliente(id) {
      this.currentClienteId = id;
      this.$refs["modal-confirm-dialog"].show();
      // this.$confirm({
      //   title: "¿Seguro que quiere eliminar al cliente?",
      //   message: "Se eliminarán todos los datos.",
      //   button: {
      //     yes: "OK",
      //     no: "Cancelar",
      //   },
      //   callback: (confirm) => {
      //     if (confirm) {
      //       this.deleteCliente(id);
      //       // self.$vueConfirm.close();
      //     }
      //   },
      // });
    },

    async deleteCliente() {
      this.loading = true;
      try {
        let id = this.currentClienteId;

        await ClientesApi.delete(id);
        var index = this.clientes.findIndex(function (o) {
          return o.id === id;
        });
        this.clientes.splice(index, 1);
        this.totalClientes--;

        this.$toast.success("Cliente eliminado", {
          timeout: 4000, //false,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true,
        });
        // this.$vueConfirm.close();

        // .then(() => {
        //   this.$swal({
        //     title: "Cotización eliminada",
        //     text: "",
        //     type: "success",
        //     showCloseButton: true,
        //   }).then(() => {
        //     var index = this.items.findIndex(function (o) {
        //       return o.id === id;
        //     });
        //     this.items.splice(index, 1);
        //     this.totalItems--;
        //   });
        // })
        // .catch(() => {
        //   this.$swal({
        //     title: "No fue posible eliminar al cliente",
        //     text: "No se puede eliminar a un cliente que ya tiene al menos un proceso",
        //     type: "warning",
        //     showCloseButton: true,
        //   });
        // });
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.$toast.error("Sucedió un error, intente más tarde", {
          timeout: 4000, //false,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true,
        });
      }
      this.$refs["modal-confirm-dialog"].hide();
    },
  },
};

// const tableData1 = [
//   {
//     id: 1,
//     nombre: "Dakota",
//     paterno: "Flores",
//     materno: "Nieto",
//     email: "dakota@example.com",
//     rol: "Cliente Administrador",
//     acciones: "✎",
//   },
//   {
//     id: 2,
//     nombre: "Karen",
//     paterno: "Luján",
//     materno: "Cedeño",
//     email: "karen@example.com",
//     rol: "Cliente Usuario",
//     acciones: "✎",
//   },
//   {
//     id: 3,
//     nombre: "Uriel",
//     paterno: "Ramírez",
//     materno: "Luna",
//     email: "uriel@example.com",
//     rol: "Cliente Usuario",
//     acciones: "✎",
//   },
//   {
//     id: 4,
//     nombre: "Adán",
//     paterno: "Vidal",
//     materno: "Sánchez",
//     email: "adan@example.com",
//     rol: "Cliente Usuario",
//     acciones: "✎",
//   },
//   {
//     id: 5,
//     nombre: "Leonela",
//     paterno: "Gloria",
//     materno: "Garza",
//     email: "leo@example.com",
//     rol: "Cliente Administrador",
//     acciones: "✎",
//   },
// ];
</script>

<style>
/* #all {
  color: #dddddf !important;
} */
</style>
