<template>
  <div class="container">
    <form @change="getAddressInMap(null)" @submit.prevent="sendAllChecksPassed()">
      <ValidationObserver v-slot="{ handleSubmit }">
        <ModalDomicilio :currentDomicilio="currentDomicilio" @submit.prevent="handleSubmit" />
      </ValidationObserver>
    </form>
  </div>
</template>

<script>
import ModalDomicilio from "@/views/clients/comparecientes/ModalDomicilio.vue";

import { getGoogleMapUrl } from "@/utils/google-maps-utils";

export default ({
  name: "Domicilio",

  components: {
    ModalDomicilio
  },

  props: {
    ratificacion: { type: Object },
  },

  data: () => ({
    currentDomicilio: {
      tieneExtranjero: false,
      tieneTipo: false,
      compareciente: false,
      extranjero: null,
      ratificacion: true,
      lat: 19.4320605,
      lng: -99.1335938
    },
  }),

  created() {
    if (!this.ratificacion.staff && this.ratificacion.estatus_id != 48 ||
      (this.ratificacion.staff)) {
      this.currentDomicilio = this.ratificacion.domicilio;
      this.currentDomicilio.ratificacion = true;
      const submitBtns = document.getElementsByClassName("btn");
      const submitBtn = submitBtns[submitBtns.length - 1];
      if (this.ratificacion.validaDomicilio == 1 || (!this.ratificacion.staff && this.ratificacion.estatus_id > 48)) {
        this.currentDomicilio.disabled = true;
        submitBtn.disabled = true;
      }
    }
  },

  methods: {
    showErrorToast(msg) {
      this.$toast.error(msg, {
        timeout: 4000, //false,
        hideProgressBar: false,
        position: "top-right",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        closeButton: "button",
        icon: true,
      });
    },

    async getAddressInMap(direcciones_id) {
      if (direcciones_id === null) {
        const address = `${this.currentDomicilio.calle},
                      ${this.currentDomicilio.num_exterior},
                      ${this.currentDomicilio.colonia},
                      ${this.currentDomicilio.delegacion_municipio},
                      ${this.currentDomicilio.codigo_postal},
                      ${this.currentDomicilio.ciudad},
                      ${this.currentDomicilio.entidad}
                      `;
        const mapUrl = await getGoogleMapUrl(address);
        if (mapUrl === null) {
          return
        }
        // this.currentDomicilio.mapUrl = mapUrl;
        this.currentDomicilio.lat = mapUrl.lat.toString(); //parseFloat(mapUrl.lat);
        this.currentDomicilio.lng = mapUrl.lng.toString(); //parseFloat(mapUrl.lng);
        var a = Object.assign({}, this.currentDomicilio);
        this.currentDomicilio = a;
      }
    },

    sendAllChecksPassed() {
      const camposValidos = ['calle', 'num_exterior', 'colonia', 'delegacion_municipio', 'ciudad', 'entidad', 'pais', 'codigo_postal', 'validacionDatos']
      const camposCompletos = camposValidos.every(campo => (this.currentDomicilio[campo] != null && this.currentDomicilio[campo] != ''))
      if (!camposCompletos) {
        this.showErrorToast('Por favor ingresa todos los campos');
        return;
      }
      this.ratificacion.domicilio = this.currentDomicilio;
      this.currentDomicilio.disabled = true;
      const submitBtns = document.getElementsByClassName("btn");
      const submitBtn = submitBtns[submitBtns.length - 1];
      submitBtn.disabled = true;
      this.$emit('allChecksPassed');
    }
  }

})
</script>
