<template>
  <div class="container">

    <!-- Modales -->
    <b-modal no-close-on-backdrop centered ref="pago-modal" title="" hide-footer id="modalC" size="xl">
      <p id="titulo">Forma de pago</p>
      <b-tabs fill>
        <b-tab title="Tarjeta de crédito o débito" active>
          <div class="mt-3 center">
            Correduría Digital no guarda nigún dato de la compra. Todo se
            efectúa a través de la plataforma de OpenPay.
          </div>
          <br />
          <ValidationObserver v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(payCotizacion)">
              <Openpay :cotizacion_id="ratificacion.cotizaciones_id" :precio_total="ratificacion.precio_total"
                @submit.prevent @payCotizacion="payCotizacion($event)" />
            </form>
          </ValidationObserver>
        </b-tab>
        <b-tab title="SPEI/Transferencia" class="modalPay">
          <ValidationObserver v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(payCotizacionSpei)">
              <Spei :cotizacion_id="ratificacion.cotizaciones_id" :precio_total="ratificacion.precio_total"
                :proceso_id="ratificacion.ratificaciones_id" :tipo="'ratificacion'" @submit.prevent
                @payCotizacionSpei="payCotizacionSpei($event)" />
            </form>
          </ValidationObserver>
        </b-tab>
        <b-tab title="Monedero" class="modalPay">
          <ValidationObserver v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(payCotizacion)">
              <Monedero :cotizacion_id="ratificacion.cotizaciones_id" :precio_total="ratificacion.precio_total"
                :clientes_id="ratificacion.clientes_id" :servicio="'Ratificacion ' + ratificacion.cotizaciones_id"
                @submit.prevent @payCotizacion="payCotizacionMonedero($event)" />
            </form>
          </ValidationObserver>
        </b-tab>
      </b-tabs>
    </b-modal>

    <b-modal no-close-on-backdrop id="confirmar-facturacion-modal" ref="confirmar-facturacion-modal" centered
      hide-footer>
      <h4 class="text-center">
        ¿Desea facturar su pago?
      </h4>
      <h5 class="text-center"></h5>
      <b-button class="mt-3" variant="danger" @click="confirmGenerarFactura(false)">
        Cancelar
      </b-button>
      <b-button class="mt-3 float-right" variant="primary" @click="confirmGenerarFactura(true)">
        OK
      </b-button>
    </b-modal>

    <b-modal no-close-on-backdrop centered hide-footer hide-header id="datos-fiscales-modal" ref="datos-fiscales-modal"
      size="lg" @close.prevent>
      <FacturacionComponent :datosFacturacion="datosFacturacion" @closedFacturacionEvent="closedFacturacion($event)"
        @canceledFacturacionEvent="canceledFacturacion()" />
    </b-modal>

    <!-- Template -->
    <!-- <h4 class="cardD">Pago</h4>
    <card class="blue">
      <i class="fa fa-info"></i>
      Aquí va la leyenda de que el pago lo procesa FAD.
      <p></p>
    </card>
    <div class="mt-4">
      <div class="">
        <b-button class="btn-fill orange-btn bottom2" size="m" @click="openPagoModal()">Pagar</b-button>
      </div>
    </div> -->

    <h4 class="cardD">Pago</h4>
    <div>
      <p>
        Su pago será procesado a través de <a href="https://www.openpay.mx" target="_blank">Openpay</a>, una plataforma
        segura y confiable para realizar transacciones electrónicas.
        <a href="https://www.openpay.mx" target="_blank">Openpay</a> garantiza la protección de sus datos y la
        integridad de la transacción. Para más información sobre Openpay y sus servicios, puede visitar su
        <a href="https://www.openpay.mx/servicios" target="_blank">página de servicios</a> o leer más sobre su
        <a href="https://www.openpay.mx/nosotros" target="_blank">empresa</a>.
      </p>
      <div>
        <div class="">
          <b-button class="btn-fill orange-btn bottom2" size="m" @click="openPagoModal()">
            Continuar al pago
          </b-button>
        </div>
      </div>
    </div>
    <div>
      <div v-if="forma_pago == 2">
        <p>
          Se envío un correo con la información de pago a la dirección de correo electrónico registrada en el sistema.
          Por favor revise su bandeja de entrada y siga las instrucciones para completar el pago.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import FacturacionComponent from "@/views/openpay/FacturacionComponent.vue";
import Monedero from "@/views/openpay/Monedero.vue";
import Openpay from "@/views/openpay/Openpay.vue";
import Spei from "@/views/openpay/Spei.vue";

import { RatificacionesApi } from "@/api";

export default ({
  name: "Pago",

  components: {
    Openpay,
    Spei,
    Monedero,
    FacturacionComponent
  },

  props: {
    ratificacion: { type: Object },
  },

  created() {
  },

  data() {
    return {
      datosFacturacion: {
        cotizacionId: 0,
        alreadyInvoiced: false
      },
    }
  },

  methods: {
    showErrorToast(msg) {
      this.$toast.error(msg, {
        timeout: 4000, //false,
        hideProgressBar: false,
        position: "top-right",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        closeButton: "button",
        icon: true,
      });
    },

    openPagoModal() {
      this.$refs['pago-modal'].show()
    },

    async payCotizacion(payed) {
      if (payed == true) {
        this.loading = true;
        this.generaPago()
          .then(() => {
            this.$refs['pago-modal'].hide();
            this.$refs['confirmar-facturacion-modal'].show();
          })
          .catch((error) => {
            this.showErrorToast('Sucedió un error, intente más tarde');
            console.log(error);
          })
          .finally(() => { this.loading = false; })
      }
    },

    async payCotizacionMonedero(payed) {
      // if (payed == true) {
      //   this.loading = true;
      //   this.generaPago()
      //     .then(() => this.emitPago())
      //     .catch((error) => {
      //       this.showErrorToast('Sucedió un error, intente más tarde');
      //       console.log(error);
      //     })
      //     .finally(() => { this.loading = false; })
      // }
      if (payed == true) {
        this.loading = true;
        this.generaPago()
          .then(() => {
            this.$refs['pago-modal'].hide();
            this.$refs['confirmar-facturacion-modal'].show();
          })
          .catch((error) => {
            this.showErrorToast('Sucedió un error, intente más tarde');
            console.log(error);
          })
          .finally(() => { this.loading = false; })
      }
    },

    async payCotizacionSpei(payed) {
      if (payed == true) {
        this.loading = true;
        this.$refs["pago-modal"].hide(s);
        this.$toast.warning("Recibirá la información para la transferencia en su correo electrónico", {
          timeout: 10000, //false,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true
        });
      } else {
        this.$toast.error("Sucedió un error, intente más tarde", {
          timeout: 4000, //false,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true
        });
        this.loading = false;
      }
    },

    async generaPago() {
      this.ratificacion.estatus_id = 46;
      delete this.ratificacion.actualizado;
      this.ratificacion = {
        ...this.ratificacion,
        ...await RatificacionesApi.edit(this.ratificacion.ratificaciones_id, this.ratificacion)
      };
    },

    emitPago() {
      this.$refs['pago-modal'].hide();
      this.$emit('allChecksPassed');
    },

    confirmGenerarFactura(crearFactura) {
      this.$refs['confirmar-facturacion-modal'].hide();

      if (!crearFactura) {
        this.$emit('allChecksPassed');

        return;
      }

      this.datosFacturacion.cotizacionId = this.ratificacion.cotizaciones_id;

      this.$refs["datos-fiscales-modal"].show();
    },

    closedFacturacion(event) {
      this.$refs['datos-fiscales-modal'].hide();
      this.$emit('allChecksPassed');
    },

    canceledFacturacion() {
      this.$refs['datos-fiscales-modal'].hide();
      this.$emit('allChecksPassed');
    },
  }
})
</script>

<style scoped>
.bottom1 {
  float: left;
}

.green-btn {
  background-color: green;
}

.container>>>.validacionDatosLabel {
  position: absolute;
  margin-left: 30px;
  top: 25%;
  max-width: 1500px;
}
</style>
