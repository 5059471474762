<template>
  <div class="container">
    <h4 class="cardD">Realización de Diligencia</h4>

    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(formatDiligencia)">

        <div class="row mt-1">
          <ValidationProvider name="hora_llegada" rules="required" v-slot="{ errors }" :custom-messages="{
            required: 'Este campo es obligatorio.'
          }">
            <label for="hora_llegada" class="label">1. Fecha y hora de llegada</label>
            <base-input id="fecha_llegada" class="date-input" :disabled="diligencia_realizada" type="date"
              v-model="fecha_llegada">
            </base-input>
            <base-input id="hora_llegada" class="time-input" :disabled="diligencia_realizada" type="time"
              v-model="hora_llegada">
            </base-input>
            <span class="cardErrors">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>

        <div class="row mt-1">
          <ValidationProvider name="forma_cerciorarse" rules="required" v-slot="{ errors }" :custom-messages="{
            required: 'Este campo es requerido.',
          }">
            <label for="forma_cerciorarse" class="label">2. ¿Cuál fue la forma de cerciorarse del domicilio?</label>
            <b-form-select v-model="forma_cerciorarse" class="custom-select" id="forma_cerciorarse"
              :disabled="diligencia_realizada">
              <option value="inmueble_marcado">El inmueble tenía marcado el número del domicilio en su exterior</option>
              <option value="dicho_persona">El dicho de la persona con quien se entendió la diligencia</option>
              <option value="gps">Aplicación GPS</option>
              <option value="otro">Otro</option>
            </b-form-select>
            <span class="cardErrors">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>

        <div class="row mt-4" v-if="forma_cerciorarse == 'otro'">
          <div class="col-12">
            <ValidationProvider rules="required" v-slot="{ errors }" :custom-messages="{
              required: 'Este campo es requerido.',
            }">
              <base-input type="text" :disabled="diligencia_realizada" placeholder="Otro"
                v-model="forma_cerciorarse_otro">
              </base-input>
              <span class="cardErrors">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
        </div>

        <div class="row mt-1">
          <ValidationProvider name="tipo_lugar" rules="required" v-slot="{ errors }" :custom-messages="{
            required: 'Este campo es requerido.',
          }">
            <label for="tipo_lugar" class="label">3. Tipo de lugar en que se practicó la diligencia</label>
            <b-form-select v-model="tipo_lugar" class="custom-select" id="tipo_lugar" :disabled="diligencia_realizada">
              <option value="edificio_corporativo">Edificio corporativo o de oficinas</option>
              <option value="casa_habilitada">Casa habilitada como oficina</option>
              <option value="casa_particular">Casa particular</option>
              <option value="edificio_departamentos">Edificio de departamentos</option>
              <option value="lugar_comercial">Lugar comercial</option>
              <option value="exterior_condominio">Exterior de un condominio maestro</option>
              <option value="terreno">Terreno</option>
              <option value="otro">Otro</option>
            </b-form-select>
            <span class="cardErrors">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>

        <div class="row mt-4" v-if="tipo_lugar == 'otro'">
          <div class="col-12">
            <ValidationProvider rules="required" v-slot="{ errors }" :custom-messages="{
              required: 'Este campo es requerido.',
            }">
              <base-input type="text" :disabled="diligencia_realizada" placeholder="Otro" v-model="tipo_lugar_otro">
              </base-input>
              <span class="cardErrors">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
        </div>

        <div class="row mt-2">
          <ValidationProvider name="momento_clave_atendido" rules="required" v-slot="{ errors }" :custom-messages="{
            required: 'Este campo es requerido.',
          }">
            <label for="momento_clave_atendido" class="label">4. En el momento clave ¿fue atendido por alguna
              persona?</label>
            <b-form-select v-model="momento_clave_atendido" class="custom-select" id="momento_clave_atendido"
              :disabled="diligencia_realizada">
              <option value="1">Sí</option>
              <option value="0">No</option>
            </b-form-select>
            <span class="cardErrors">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>

        <div class="row mt-2">
          <ValidationProvider name="varios_lugares" rules="required" v-slot="{ errors }" :custom-messages="{
            required: 'Este campo es requerido.',
          }">
            <label for="varios_lugares" class="label">5. ¿La diligencia se realizó en más de un lugar?</label>
            <b-form-select v-model="varios_lugares" class="custom-select" id="varios_lugares"
              :disabled="diligencia_realizada">
              <option value="0">No</option>
              <option value="1">Sí</option>
            </b-form-select>
            <span class="cardErrors">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>

        <div class="row mt-4" v-if="varios_lugares == '1'">
          <div class="col-12">
            <ValidationProvider rules="required" v-slot="{ errors }" :custom-messages="{
              required: 'Este campo es requerido.',
            }">
              <base-input type="text" :disabled="diligencia_realizada" placeholder="¿Cuál?" v-model="otro_lugar">
              </base-input>
              <span class="cardErrors">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
        </div>

        <div class="row mt-2">
          <ValidationProvider name="abierto_publico" rules="required" v-slot="{ errors }" :custom-messages="{
            required: 'Este campo es requerido.',
          }">
            <label for="abierto_publico" class="label">6. ¿El inmueble se encontraba abierto al público?</label>
            <b-form-select v-model="abierto_publico" class="custom-select" id="abierto_publico"
              :disabled="diligencia_realizada">
              <option value="si_ingreso">Sí, por lo que se ingresó</option>
              <option value="si_no_acceso">Sí, pero no se le permitió el acceso</option>
              <option value="no_atendido_exterior">No, pero fue atendido en el exterior del inmueble</option>
              <option value="no_no_atendido">No, y no fue atendido</option>
            </b-form-select>
            <span class="cardErrors">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>

        <div class="row mt-2">
          <ValidationProvider name="ingreso_numeros_interiores" rules="required" v-slot="{ errors }" :custom-messages="{
            required: 'Este campo es requerido.',
          }">
            <label for="ingreso_numeros_interiores" class="label">7. ¿Se ingresó a los números interiores del
              inmueble?</label>
            <b-form-select v-model="ingreso_numeros_interiores" class="custom-select" id="ingreso_numeros_interiores"
              :disabled="diligencia_realizada">
              <option value="si_registro">Sí, por medio de un registro</option>
              <option value="si_gafete">Sí, por medio de la entrega de un gafete o control de acceso</option>
              <option value="si_sin_registro">Sí, sin registro, pero autorizado por quien controlaba el acceso</option>
              <option value="si_libre_transito">Sí, por libre tránsito</option>
              <option value="no_atendido_recepcion">No, pero fue atendido en la recepción general en PB</option>
              <option value="no_no_atendido">No, y no fue atendido</option>
            </b-form-select>
            <span class="cardErrors">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>

        <div class="row mt-2">
          <ValidationProvider name="lugar_final_persona_atendio" rules="required" v-slot="{ errors }" :custom-messages="{
            required: 'Este campo es requerido.',
          }">
            <label for="lugar_final_persona_atendio" class="label">8. En el lugar final, fue atendido por:</label>
            <b-form-select v-model="lugar_final_persona_atendio" class="custom-select" id="lugar_final_persona_atendio"
              :disabled="diligencia_realizada">
              <option value="recepcionista">Una recepcionista</option>
              <option value="guardia">Un guardia de seguridad</option>
              <option value="femenino">Una persona de sexo femenino y posteriormente por otra persona</option>
              <option value="masculino">Una persona de sexo masculino y posteriormente por otra persona</option>
            </b-form-select>
            <span class="cardErrors">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>

        <div class="row mt-3">
          <label for="persona_atendio_sexo" class="label-long">9. Características de la persona con quien, en última
            instancia, se entendió la diligencia</label>
        </div>
        <div class="row mt-2">
          <ValidationProvider name="persona_atendio_sexo" rules="required" v-slot="{ errors }" :custom-messages="{
            required: 'Este campo es requerido.',
          }">
            <label for="persona_atendio_sexo" class="label">Sexo</label>
            <base-input class="custom-text" type="text" :disabled="diligencia_realizada" placeholder="Sexo"
              v-model="persona_atendio_sexo">
            </base-input>
            <span class="cardErrors">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
        <div class="row mt-2">
          <ValidationProvider name="persona_atendio_edad" rules="required" v-slot="{ errors }" :custom-messages="{
            required: 'Este campo es requerido.',
          }">
            <label for="persona_atendio_edad" class="label">Edad</label>
            <base-input class="custom-text" type="number" :disabled="diligencia_realizada" :min="1" placeholder="1"
              v-model="persona_atendio_edad">
            </base-input>
            <span class="cardErrors">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
        <div class="row mt-2">
          <ValidationProvider name="persona_atendio_estatura" rules="required" v-slot="{ errors }" :custom-messages="{
            required: 'Este campo es requerido.',
          }">
            <label for="persona_atendio_estatura" class="label">Estatura (cm)</label>
            <base-input class="custom-text" type="number" :disabled="diligencia_realizada" :min="1" placeholder="1"
              v-model="persona_atendio_estatura">
            </base-input>
            <span class="cardErrors">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
        <div class="row mt-2">
          <ValidationProvider name="persona_atendio_cabello" rules="required" v-slot="{ errors }" :custom-messages="{
            required: 'Este campo es requerido.',
          }">
            <label for="persona_atendio_cabello" class="label">Cabello</label>
            <base-input class="custom-text" type="text" :disabled="diligencia_realizada" placeholder="Cabello"
              v-model="persona_atendio_cabello">
            </base-input>
            <span class="cardErrors">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
        <div class="row mt-2">
          <ValidationProvider name="persona_atendio_complexion" rules="required" v-slot="{ errors }" :custom-messages="{
            required: 'Este campo es requerido.',
          }">
            <label for="persona_atendio_complexion" class="label">Complexión</label>
            <base-input class="custom-text" type="text" :disabled="diligencia_realizada" placeholder="Complexión"
              v-model="persona_atendio_complexion">
            </base-input>
            <span class="cardErrors">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>

        <div class="row mt-2">
          <ValidationProvider name="momento_clave_persona_atendio" rules="required" v-slot="{ errors }"
            :custom-messages="{
              required: 'Este campo es requerido.',
            }">
            <label for="momento_clave_persona_atendio" class="label">10. ¿Quién atendió al Corredor Público?</label>
            <b-form-select v-model="momento_clave_persona_atendio" class="custom-select"
              id="momento_clave_persona_atendio" :disabled="momento_clave_atendido == '0' || diligencia_realizada">
              <option value="recepcionista">Una recepcionista</option>
              <option value="guardia">Un guardia de seguridad</option>
              <option value="conserje">Conserje/Portero</option>
              <option value="trabajador">Trabajador</option>
              <option value="familiar">Familiar del notificado</option>
              <option value="mismo">El mismo notificado</option>
              <option value="otro">Otro</option>
            </b-form-select>
            <span class="cardErrors">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>

        <div class="row mt-4" v-if="momento_clave_persona_atendio == 'otro'">
          <div class="col-12">
            <ValidationProvider rules="required" v-slot="{ errors }" :custom-messages="{
              required: 'Este campo es requerido.',
            }">
              <base-input type="text" :disabled="diligencia_realizada" placeholder="Otro"
                v-model="momento_clave_persona_atendio_otro">
              </base-input>
              <span class="cardErrors">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
        </div>

        <div class="row mt-2">
          <ValidationProvider name="domicilio_señalado" rules="required" v-slot="{ errors }" :custom-messages="{
            required: 'Este campo es requerido.',
          }">
            <label for="domicilio_señalado" class="label">11. La persona que atendió la diligencia: ¿Confirmó que el
              lugar fuera el domicilio señalado?</label>
            <b-form-select v-model="domicilio_señalado" class="custom-select" id="domicilio_señalado"
              :disabled="diligencia_realizada">
              <option value="1">Sí</option>
              <option value="0">No</option>
            </b-form-select>
            <span class="cardErrors">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>

        <div class="row mt-2">
          <ValidationProvider name="domicilio_persona_buscada" rules="required" v-slot="{ errors }" :custom-messages="{
            required: 'Este campo es requerido.',
          }">
            <label for="domicilio_persona_buscada" class="label">12. La persona que atendió la diligencia: ¿Confirmó que
              el lugar fuera el domicilio de la persona buscada?</label>
            <b-form-select v-model="domicilio_persona_buscada" class="custom-select" id="domicilio_persona_buscada"
              :disabled="diligencia_realizada">
              <option value="1">Sí</option>
              <option value="0">No</option>
            </b-form-select>
            <span class="cardErrors">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>

        <div class="row mt-2">
          <ValidationProvider name="declaracion_domicilio_negativo" rules="required" v-slot="{ errors }"
            :custom-messages="{
              required: 'Este campo es requerido.',
            }">
            <label for="declaracion_domicilio_negativo" class="label">13. En caso negativo, ¿qué declaración
              realizó?</label>
            <b-form-select v-model="declaracion_domicilio_negativo" class="custom-select"
              id="declaracion_domicilio_negativo" :disabled="domicilio_persona_buscada == '1' || diligencia_realizada">
              <option value="otro_domicilio">Señaló que la persona buscada tiene otro domicilio</option>
              <option value="otra_persona">Señaló que el domicilio se encontraba ocupado por otra persona</option>
              <option value="desocupado">Señaló que el domicilio estaba desocupado/vacío</option>
              <option value="no_existia">Señaló que el domicilio no existía</option>
              <option value="anteriormente">Señaló que anteriormente el lugar fue domicilio de la persona buscada, pero
                que ya no lo es</option>
              <option value="sin_registro">No tener registro de las personas</option>
              <option value="otro">Otro</option>
            </b-form-select>
            <span class="cardErrors">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>

        <div class="row mt-4" v-if="declaracion_domicilio_negativo == 'otro'">
          <div class="col-12">
            <ValidationProvider rules="required" v-slot="{ errors }" :custom-messages="{
              required: 'Este campo es requerido.',
            }">
              <base-input type="text" :disabled="diligencia_realizada" placeholder="Otro"
                v-model="declaracion_domicilio_negativo_otro">
              </base-input>
              <span class="cardErrors">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
        </div>

        <div class="row mt-2">
          <ValidationProvider name="recibio_comunicacion" rules="required" v-slot="{ errors }" :custom-messages="{
            required: 'Este campo es requerido.',
          }">
            <label for="recibio_comunicacion" class="label">14. ¿La persona que atendió la diligencia recibió la
              comunicación?</label>
            <b-form-select v-model="recibio_comunicacion" class="custom-select" id="recibio_comunicacion"
              :disabled="diligencia_realizada">
              <option value="1">Sí</option>
              <option value="0">No</option>
            </b-form-select>
            <span class="cardErrors">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>

        <div class="row mt-2">
          <ValidationProvider name="instructivo_firmado" rules="required" v-slot="{ errors }" :custom-messages="{
            required: 'Este campo es requerido.',
          }">
            <label for="instructivo_firmado" class="label">15. ¿La persona que atendió la diligencia firmó el
              instructivo?</label>
            <b-form-select v-model="instructivo_firmado" class="custom-select" id="instructivo_firmado"
              :disabled="diligencia_realizada">
              <option value="1">Sí</option>
              <option value="0">No</option>
            </b-form-select>
            <span class="cardErrors">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>

        <div class="row mt-2">
          <ValidationProvider name="persona_atendio_nombre_proporcionado" rules="required" v-slot="{ errors }"
            :custom-messages="{
              required: 'Este campo es requerido.',
            }">
            <label for="persona_atendio_nombre_proporcionado" class="label">16. ¿La persona que atendió la diligencia
              proporcionó su nombre?</label>
            <b-form-select v-model="persona_atendio_nombre_proporcionado" class="custom-select"
              id="persona_atendio_nombre_proporcionado" :disabled="diligencia_realizada">
              <option value="1">Sí</option>
              <option value="0">No</option>
            </b-form-select>
            <span class="cardErrors">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>

        <div class="row mt-4" v-if="persona_atendio_nombre_proporcionado == '1'">
          <div class="col-12">
            <ValidationProvider rules="required" v-slot="{ errors }" :custom-messages="{
              required: 'Este campo es requerido.',
            }">
              <base-input type="text" :disabled="diligencia_realizada" placeholder="Nombre"
                v-model="persona_atendio_nombre">
              </base-input>
              <span class="cardErrors">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
        </div>

        <div class="row mt-2">
          <ValidationProvider name="persona_atendio_identificacion" rules="required" v-slot="{ errors }"
            :custom-messages="{
              required: 'Este campo es requerido.',
            }">
            <label for="persona_atendio_identificacion" class="label">17. ¿La persona que atendió la diligencia se
              identificó?</label>
            <b-form-select v-model="persona_atendio_identificacion" class="custom-select"
              id="persona_atendio_identificacion" :disabled="diligencia_realizada">
              <option value="1">Sí</option>
              <option value="0">No</option>
            </b-form-select>
            <span class="cardErrors">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>

        <div class="row mt-4" v-if="persona_atendio_identificacion == '1'">
          <div class="col-12">
            <ValidationProvider rules="required" v-slot="{ errors }" :custom-messages="{
              required: 'Este campo es requerido.',
            }">
              <base-input type="text" :disabled="diligencia_realizada" placeholder="Tipo de Documento"
                v-model="persona_atendio_identificacion_tipo_documento">
              </base-input>
              <span class="cardErrors">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
        </div>

        <div class="row mt-4" v-if="persona_atendio_identificacion == '1'">
          <div class="col-12">
            <ValidationProvider rules="required" v-slot="{ errors }" :custom-messages="{
              required: 'Este campo es requerido.',
            }">
              <base-input type="text" :disabled="diligencia_realizada" placeholder="Número de identificación"
                v-model="persona_atendio_identificacion_numero">
              </base-input>
              <span class="cardErrors">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
        </div>

        <div class="row mt-2">
          <ValidationProvider name="procedimiento_notificacion" rules="required" v-slot="{ errors }" :custom-messages="{
            required: 'Este campo es requerido.',
          }">
            <label for="procedimiento_notificacion" class="label">18. ¿Cómo se procedió a realizar la
              notificación?</label>
            <b-form-select v-model="procedimiento_notificacion" class="custom-select" id="procedimiento_notificacion"
              :disabled="diligencia_realizada">
              <option value="entregado_instructivo_firmado">El documento fue entregado y el instructivo firmado de
                recibido</option>
              <option value="entregado_sin_firma">El documento fue entregado, pero sin firma de recibido</option>
              <option value="poder">El documento se dejó en poder de la persona que atendió la diligencia</option>
              <option value="dejo_interior">Se dejó en el interior del inmueble</option>
              <option value="deposito_interior">Se depositó en el interior del inmueble</option>
              <option value="fijo_exterior">Se fijó en el exterior del inmueble</option>
              <option value="otro">Otro</option>
            </b-form-select>
            <span class="cardErrors">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>

        <div class="row mt-4" v-if="procedimiento_notificacion == 'otro'">
          <div class="col-12">
            <ValidationProvider rules="required" v-slot="{ errors }" :custom-messages="{
              required: 'Este campo es requerido.',
            }">
              <base-input type="text" :disabled="diligencia_realizada" placeholder="Otro"
                v-model="procedimiento_notificacion_otro">
              </base-input>
              <span class="cardErrors">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
        </div>

        <div class="row mt-1">
          <ValidationProvider name="hora_notificacion" rules="required" v-slot="{ errors }" :custom-messages="{
            required: 'Este campo es obligatorio.'
          }">
            <label for="hora_notificacion" class="label">19. Fecha y hora de la notificación</label>
            <base-input id="fecha_notificacion" class="date-input" :disabled="diligencia_realizada" type="date"
              v-model="fecha_notificacion" :min="fecha_llegada">
            </base-input>
            <base-input id="hora_notificacion" class="time-input" :disabled="diligencia_realizada" type="time"
              v-model="hora_notificacion" :min="hora_llegada">
            </base-input>
            <span class="cardErrors">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>

        <div class="row mt-2">
          <ValidationProvider name="persona_atendio_manifestaciones_realizadas" rules="required" v-slot="{ errors }"
            :custom-messages="{
              required: 'Este campo es requerido.',
            }">
            <label for="persona_atendio_manifestaciones_realizadas" class="label">20. La persona que atendió realizó
              manifestaciones</label>
            <b-form-select v-model="persona_atendio_manifestaciones_realizadas" class="custom-select"
              id="persona_atendio_manifestaciones_realizadas" :disabled="diligencia_realizada">
              <option value="1">Sí</option>
              <option value="0">No</option>
            </b-form-select>
            <span class="cardErrors">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>

        <div class="row mt-4" v-if="persona_atendio_manifestaciones_realizadas == '1'">
          <div class="col-12">
            <ValidationProvider rules="required" v-slot="{ errors }" :custom-messages="{
              required: 'Este campo es requerido.',
            }">
              <base-input type="text" :disabled="diligencia_realizada" placeholder="¿Cuáles?"
                v-model="persona_atendio_manifestaciones">
              </base-input>
              <span class="cardErrors">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
        </div>

        <div class="row mt-2">
          <ValidationProvider name="reporte_fotografico" rules="required" v-slot="{ errors }" :custom-messages="{
            required: 'Este campo es requerido.',
          }">
            <label for="reporte_fotografico" class="label">21. ¿Hay reporte fotográfico?</label>
            <b-form-select v-model="reporte_fotografico" class="custom-select" id="reporte_fotografico"
              :disabled="diligencia_realizada">
              <option value="1">Sí</option>
              <option value="0">No</option>
            </b-form-select>
            <span class="cardErrors">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>

        <br><br>
        <div class="mt-4">
          <b-button type="submit" class="btn-fill orange-btn bottom2" size="m"
            :disabled="diligencia_realizada">Validar</b-button>
        </div>

      </form>
    </ValidationObserver>
  </div>
</template>

<script>
export default {
  name: "Diligencia",

  created() {
    this.loading = true;
    this.checkStatus()
      .catch((error) => {
        console.log(error);
        this.showErrorToast('Intente más tarde')
      })
      .finally(() => { this.loading = false; })
  },

  mounted() {
    this.loading = true;
    this.convertDataToForm()
      .catch((error) => {
        console.log(error);
        this.showErrorToast('Intente más tarde')
      })
      .finally(() => { this.loading = false; })
  },

  props: {
    notificacion: { type: Object },
  },

  data: () => ({
    diligencia_realizada: false,
    hora_llegada: null,
    forma_cerciorarse: null,
    tipo_lugar: null,
    momento_clave_atendido: null,
    varios_lugares: null,
    otro_lugar: null,
    abierto_publico: null,
    ingreso_numeros_interiores: null,
    lugar_final_persona_atendio: null,
    persona_atendio_sexo: null,
    persona_atendio_edad: null,
    persona_atendio_estatura: null,
    persona_atendio_cabello: null,
    persona_atendio_complexion: null,
    momento_clave_persona_atendio: null,
    domicilio_señalado: null,
    domicilio_persona_buscada: null,
    declaracion_domicilio_negativo: null,
    recibio_comunicacion: null,
    instructivo_firmado: null,
    persona_atendio_nombre_proporcionado: null,
    persona_atendio_nombre: null,
    persona_atendio_identificacion: null,
    persona_atendio_identificacion_tipo_documento: null,
    persona_atendio_identificacion_numero: null,
    procedimiento_notificacion: null,
    hora_notificacion: null,
    persona_atendio_manifestaciones_realizadas: null,
    persona_atendio_manifestaciones: null,
    reporte_fotografico: null,
    fecha_llegada: null,
    forma_cerciorarse_otro: null,
    tipo_lugar_otro: null,
    momento_clave_persona_atendio_otro: null,
    declaracion_domicilio_negativo_otro: null,
    procedimiento_notificacion_otro: null,
    fecha_notificacion: null,
  }),

  methods: {
    showErrorToast(msg) {
      this.$toast.error(msg, {
        timeout: 4000, //false,
        hideProgressBar: false,
        position: "top-right",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        closeButton: "button",
        icon: true,
      });
    },

    checkStatus() {
      return new Promise((resolve, reject) => {
        try {
          if ([13, 58].includes(this.notificacion.id_estatus)) {
            this.diligencia_realizada = true;
          }
          resolve()
        } catch (error) {
          reject(error)
        }
      })
    },

    convertDataToForm() {
      const list_otros = ['forma_cerciorarse', 'tipo_lugar', 'momento_clave_persona_atendio', 'declaracion_domicilio_negativo', 'procedimiento_notificacion']
      return new Promise((resolve, reject) => {
        try {
          for (let key in this) {
            if (this.notificacion.hasOwnProperty(key)) {
              this[key] = this.notificacion[key]
            }
            if (this.notificacion.hasOwnProperty(key) && list_otros.includes(key)) {
              let selectElement = document.getElementById(key);
              let optionValues = [];
              for (let i = 0; i < selectElement.options.length; i++) {
                // Add the current option's value to the array
                optionValues.push(selectElement.options[i].value);
              }
              if (!optionValues.includes(this.notificacion[key]))
                this[key] = 'otro';
              this[`${key}_otro`] = this.notificacion[key]
            }
            if (this.notificacion.hasOwnProperty(key) && this.notificacion[key] == '1') {
              this[key] = 1;
            }
            if (this.notificacion.hasOwnProperty(key) && this.notificacion[key] == '0') {
              this[key] = 0;
            }
          }
          let parts_llegada = this.notificacion.hora_llegada != null ? this.notificacion.hora_llegada.split('T') : [null, null]
          this.fecha_llegada = parts_llegada[0];
          this.hora_llegada = parts_llegada[1];
          let parts_notificacion = this.notificacion.hora_notificacion != null ? this.notificacion.hora_notificacion.split('T') : [null, null]
          this.fecha_notificacion = parts_notificacion[0];
          this.hora_notificacion = parts_notificacion[1];
          this.checkStatus()
          resolve()
        } catch (error) {
          reject(error)
        }
      })
    },

    formatDiligencia() {
      for (let key in this) {
        if (this.notificacion.hasOwnProperty(key)) {
          this.notificacion[key] = this[key]
        }
        if (this.notificacion.hasOwnProperty(key) && this[key] == 'otro') {
          this.notificacion[key] = this[`${key}_otro`]
        }
      }
      this.notificacion.hora_llegada = `${this.fecha_llegada} ${this.hora_llegada}`
      this.notificacion.hora_notificacion = `${this.fecha_notificacion} ${this.hora_notificacion}`
      this.diligencia_realizada = true;
      console.log("emit")
      this.$emit('proceedToSubmit');
    }

  }
}
</script>

<style scoped>
.bottom2 {
  /* position: fixed;
    right: 2%;
    bottom: 5%;
    overflow: visible;
    z-index: 100; */
  float: right;
}

.container>>>.label {
  width: 300px !important;
}

.container>>>.date-input {
  display: inline-block;
  margin-left: 100px;
  width: 250px;
}

.container>>>.time-input {
  display: inline-block;
  width: 250px;
}

.container>>>.custom-select {
  display: inline-block;
  margin-left: 100px;
  width: 500px;
}

.container>>>.custom-text {
  display: inline-block;
  margin-left: 100px;
  width: 500px;
}

.container>>>.mt-1 {
  text-align: left;
  vertical-align: middle;
  margin-top: 10px !important;
}

.container>>>.mt-2 {
  margin-top: 10px !important;
  vertical-align: middle;
}

.container>>>.label-long {
  width: 900px !important;
}

.container>>>.mt-4 {
  margin-top: 10px !important;
  margin-left: -30px !important;
  width: 930px;
}
</style>
