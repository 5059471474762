/**
 * Implement API to store items by keys on browser.
 */
export default class Storage {
  constructor() {
    this.storage = localStorage;
  }

  /**
   * Store item by key.
   */
  saveItem(key, item) {
    const itemStr = JSON.stringify(item);
    this.storage.setItem(key, itemStr);
  }

  /**
   * Retrieve item by key.
   */
  getItem(key) {
    const itemStr = this.storage.getItem(key);
    return JSON.parse(itemStr);
  }

  /**
   * Remove item by key.
   */
  removeItem(key) {
    this.storage.removeItem(key);
  }
}
