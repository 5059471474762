<template>
  <div class="d-flex justify-content-center align-items-center h-75">
    <div class="text-center">
      <img width="200" height="200" src="/img/cp_logooficial_color.png">
      <h1>Próximamente</h1>
      <p>Estamos trabajando en esta sección. Pronto estará disponible.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "SoonVuePage"
};
</script>