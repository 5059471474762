<template>
  <div class="container">

    <b-modal no-close-on-backdrop centered ref="modal-confirm-mail" hide-footer id="modal-confirm-mail">
      <h4 class="text-center">
        ¿Está seguro de enviar la Liga de Calendly? El cliente será notificado vía email.
      </h4>
      <h5 class="text-center"></h5>
      <b-button class="mt-3" variant="danger" @click="$bvModal.hide('modal-confirm-mail')">Cancelar</b-button>
      <b-button class="mt-3 float-right" variant="primary" @click="processCita()">OK</b-button>
    </b-modal>

    <h4 class="cardD">Sistema de citas</h4>

    <div class="mt-4">
      <ValidationProvider name="liga_zoom_firma" rules="required" v-slot="{ errors }" :custom-messages="{
        required: 'La liga de Calendly es requerida',
      }">
        <b-form-group label="Link cita Calendly" label-for="input-link-cita">
          <b-input-group class="mt-3">
            <b-input-group-append>
              <b-button :variant="isTextCopied ? 'success' : 'secondary'" squared v-b-tooltip.hover title="Copiar"
                @click="copyLinkToClipboard">
                <b-icon v-if="isTextCopied" icon="check"></b-icon>
                <b-icon v-else icon="clipboard"></b-icon>
              </b-button>
            </b-input-group-append>
            <b-form-input id="input-link-cita" type="link" v-model="ratificacion.liga_zoom_firma"
              :disabled="ratificacion.estatus_id == 71 || (ratificacion.estatus_id >= 41 && ratificacion.estatus_id <= 56)"
              placeholder="Link Calendly"></b-form-input>
          </b-input-group>
        </b-form-group>

        <!-- <base-input type="text" label="Liga de evento de Calendly" placeholder="" v-model="ratificacion.liga_zoom_firma"
          :disabled="ratificacion.estatus_id == 71 || (ratificacion.estatus_id >= 41 && ratificacion.estatus_id <= 56)">
        </base-input> -->
        <span class="cardErrors">{{ errors[0] }}</span>
      </ValidationProvider>
    </div>

    <div class="mt-4">
      <div class="">
        <b-button class="btn-fill orange-btn bottom2" size="m" @click="openConfirmCitaModal()"
          :disabled="ratificacion.estatus_id == 71 || (ratificacion.estatus_id >= 41 && ratificacion.estatus_id <= 56)">
          Enviar
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { FuncionesApi } from "@/api";
import loadingMixin from "@/mixins/loadingMixin";
export default ({
  name: "Cita",

  props: {
    ratificacion: { type: Object }
  },

  mixins: [loadingMixin],

  data: () => ({
    isTextCopied: false,
  }),

  methods: {
    copyLinkToClipboard() {
      if (!this.ratificacion.liga_zoom_firma) {
        return;
      }

      navigator.clipboard
        .writeText(this.ratificacion.liga_zoom_firma)
        .then(this.isTextCopied = true);
    },

    showErrorToast(msg) {
      this.$toast.error(msg, {
        timeout: 4000, //false,
        hideProgressBar: false,
        position: "top-right",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        closeButton: "button",
        icon: true,
      });
    },

    openConfirmCitaModal() {
      if (!this.ratificacion.liga_zoom_firma) {
        this.showErrorToast('Por favor ingrese una liga de evento de Calendly.');
        return;
      }
      this.$refs["modal-confirm-mail"].show();
    },

    processCita() {
      this.$refs["modal-confirm-mail"].hide();
      this.toggleOnLoading();
      this.sendMailCita()
        .then(() => this.submitCita())
        .catch((error) => {
          this.showErrorToast('Sucedió un error, intente más tarde');
          console.log(error);
        })
        .finally(() => { this.toggleOffLoading(); })
    },

    async sendMailCita() {
      const solicitante_o_representante = this.ratificacion.ratificantes.find(obj => obj.persona == 'Física');
      const email = solicitante_o_representante.email;

      await FuncionesApi.sendLinkCalendario({
        cliente_email: email,
        link_calendario: this.ratificacion.liga_zoom_firma
      });

      this.$toast.success('Se ha notificado al cliente correctamente.', {
        timeout: 40000, //false,
        hideProgressBar: false,
        position: "top-right",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        closeButton: "button",
        icon: true,
      });
    },

    submitCita() {
      this.toggleOffLoading();
      this.$emit('allChecksPassed');
    }
  }
})
</script>

<style scoped>
.bottom1 {
  float: left;
}

.green-btn {
  background-color: green;
}

.container>>>.validacionDatosLabel {
  position: absolute;
  margin-left: 30px;
  top: 25%;
  max-width: 1500px;
}
</style>
