<template>
  <div class="content">
    <b-breadcrumb>
      <b-breadcrumb-item :to="{ path: '/dashboard/settings' }">Configuraciones</b-breadcrumb-item>
      <b-breadcrumb-item active>Detalle de cupón</b-breadcrumb-item>
    </b-breadcrumb>

    <div class="container">
      <div v-if="loading">
        <div id="loader">
          <font-awesome-icon icon="spinner" spin />
        </div>
      </div>
    </div>

     <!-- <label for="">Aplicaciones de cupón</label> -->

     <div class="container-fluid">
      <b-card class="mt-3">
        <div class="mw-75 px-3 justify-content-center">
          <ModalCupon
            :isEmbedded="true"
            :currentCupon="cupon"
            @putCupon="putCupon($event)" />
        </div>
      </b-card>

      <b-card class="mt-3">
        <div>
          <b-table hover :fields="fieldsCupones" :items="aplicaciones"
            :busy="isTableBusy"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            @sort-changed="sortingChanged"
            >
            <template #table-busy>
              <div class="text-center my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Cargando...</strong>
              </div>
            </template>
            <template #cell(index)="{ index }">
              {{ getIndex(index + 1) }}
            </template>
            <template #cell(precioTotal)="data">
              {{ formatTotalPrice(data.value) }}
            </template>
            <!-- <template #cell(precioTotal)="data">
              {{ new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(data.value) }}
            </template> -->
          </b-table>
          <div class="d-flex my-2">
            <span>
              Ordenado por: <b>{{ sortBy }}</b>, en dirección: <b>{{ sortDesc ? 'descendente' : 'ascendente' }}</b>
            </span>
          </div>
          <div class="d-flex justify-content-between pages">
            <b-pagination
              v-model="currentPage"
              :per-page="perPage"
              :total-rows="total"
            />
            <span>Mostrando {{ perPage }} registros de {{ total }}</span>
          </div>
        </div>
      </b-card>

    </div>
  </div>
</template>

<script>
import ModalCupon from "./modales/ModalCupon.vue"

import { CuponesApi } from "@/api";

import { formatCurrency } from "@/utils/number-utils";

export default {
  name: "CuponDetalle",

  components: {
    ModalCupon
  },

  created() {
    this.getCupon();
    this.getAplicaciones();
  },

  computed: {
  },

  watch: {
    currentPage: "getAplicaciones",
  },

  data() {
    return {
      loading: true,
      isTableBusy: false,
      sortBy: "creado",
      currentPage: 1,
      perPage: 10,
      sortDesc: true,
      total: 0,

      cupon: {
        id: null,
        nombre: null,
        tipo: null,
        tipo_letra: null,
        descuento: null,
        habilitado: null,
        fechaInicio: null,
        fechaFin: null,
        limiteAplicaciones: null,
        numeroAplicaciones: null,
        activo: null,
        creado: null,
        actualizado: null,
        aplicaciones: null,
      },

      aplicaciones: [],

      currentCupon: null,
      currentCuponId: null,
      fieldsCupones: [
        { key: "index", label: "#", class: "text-center" },
        { key: "proceso", label: "Servicio", class: "text-center", sortable: true },
        { key: "descripcion", label: "Descripcion", class: "text-center" },
        { key: "creado", label: "Fecha", class: "text-center", sortable: true, sortDirection: "asc" },
        { key: "precioTotal", label: "Precio total", class: "text-center" },
        // { key: "acciones", label: "Acciones", class: "text-center" }
        //ToDO: checar si acciones tenga edit y se iria al proceso /edit
      ],
    };
  },

  methods: {
    formatTotalPrice: (price) => formatCurrency(price),

    getIndex(index) {
      return index + (this.currentPage - 1) * this.perPage;
    },

    async getCupon() {
      this.loading = true;

      const id = this.$route.params.id;

      try {
        this.cupon = await CuponesApi.detail(id);

      } catch(error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    async getAplicaciones() {
      this.isTableBusy = true;
      const id = this.$route.params.id;

      try {
        const pagination = {
          page: this.currentPage,
          registersPerPage: this.perPage,
          isDescOrder: this.sortDesc
        }

        const aplicaciones = await CuponesApi.getAplicaciones(id, pagination);

        this.aplicaciones = aplicaciones.value;
        this.total = aplicaciones.odata_total;
        this.currentPage = aplicaciones.odata_page;

        // console.log(aplicaciones);
      } catch(error) {
        console.log(error);
      } finally {
        this.isTableBusy = false;
      }
    },

    async sortingChanged(ctx) {
      // console.log(ctx);
      // ctx.sortBy;
      this.currentPage = 1;
      this.sortDesc = ctx.sortDesc;

      await this.getAplicaciones();
    },

    async putCupon(eventArgs) {
      this.loading = true;

      const data = {
        nombre: this.cupon.nombre,
        descuento: parseFloat(this.cupon.descuento),
        habilitado: this.cupon.habilitado,
        tipo: this.cupon.tipo,
        fechaInicio: this.cupon.fechaInicio,
        fechaFin: this.cupon.fechaFin,
        limiteAplicaciones: parseInt(this.cupon.limiteAplicaciones),
        activo: 1,
      };

      // this.cupon.tipo_letra = this.cupon.tipo === 1 ? 'Porcentaje' : 'Monto';

      try {
          await CuponesApi.edit(this.cupon.id, data);

          this.$toast.success("Cupón guardado", {
            timeout: 4000, //false,
            hideProgressBar: false,
            position: "top-right",
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            closeButton: "button",
            icon: true
          });
        } catch {
          this.$toast.error("Sucedió un error, intente más tarde", {
            timeout: 4000, //false,
            hideProgressBar: false,
            position: "top-right",
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            closeButton: "button",
            icon: true
          });
        } finally {
          this.loading = false;
        }
    },
  }
};
</script>
