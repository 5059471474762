<template>
  <div class="content">
    <b-breadcrumb>
      <b-breadcrumb-item :to="{ path: '/dashboard' }">Inicio</b-breadcrumb-item>
      <b-breadcrumb-item active>Nueva Constitución de Sociedad</b-breadcrumb-item>
    </b-breadcrumb>

    <div class="container">
      <div v-if="loading">
        <div id="loader">
          <font-awesome-icon icon="spinner" spin />
        </div>
      </div>
    </div>

    <b-modal no-close-on-backdrop centered ref="modal-confirm-dialog-route" hide-footer id="modal-confirm-dialog-route">
      <h4 class="text-center">
        ¿Seguro que quiere salir de la página?
      </h4>
      <h5 class="text-center"></h5>
      <b-button class="mt-3" variant="danger" @click="$bvModal.hide('modal-confirm-dialog-route')">Cancelar</b-button>
      <b-button class="mt-3 float-right" variant="primary" @click="beforeLeave(true)">OK</b-button>
    </b-modal>
    <b-modal no-close-on-backdrop centered ref="modal-confirm-dialog" hide-footer id="modal-confirm-dialog">
      <h4 class="text-center">
        ¿Seguro que quiere salir sin guardar los cambios?
      </h4>
      <h5 class="text-center"></h5>
      <b-button class="mt-3" variant="danger" @click="$bvModal.hide('modal-confirm-dialog')">Cancelar</b-button>
      <b-button class="mt-3 float-right" variant="primary" @click="selected('go')">OK</b-button>
    </b-modal>

    <!-- <b-modal
      centered
      ref="pago-modal"
      title=""
      hide-footer
      id="modalC"
      size="xl"
    >
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(saveConstitucion)">
          <Openpay
            :cotizacion_id="constitutiva.cotizacion.id"
            :precio_total="constitutiva.precio_total"
            @submit.prevent
            @payCotizacion="payCotizacion($event)"
          />
        </form>
      </ValidationObserver>
    </b-modal> -->
    <b-modal no-close-on-backdrop centered ref="pago-modal" title="" hide-footer id="modalC" size="xl">
      <p id="titulo">Forma de pago</p>
      <b-tabs fill>
        <b-tab title="Tarjeta de crédito o débito" active>
          <div class="mt-3 center">
            Correduría Digital no guarda nigún dato de la compra. Todo se
            efectúa a través de la plataforma de OpenPay.
          </div>
          <br />
          <ValidationObserver v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(payCotizacion)">
              <Openpay :cotizacion_id="constitutiva.cotizaciones_id" :precio_total="parseInt(constitutiva.precio_total)"
                @submit.prevent @payCotizacion="payCotizacion($event)" />
            </form>
          </ValidationObserver>
        </b-tab>
        <b-tab title="SPEI/Transferencia" class="modalPay">
          <ValidationObserver v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(payCotizacionSpei)">
              <Spei :cotizacion_id="constitutiva.cotizaciones_id" :precio_total="constitutiva.precio_total"
                :proceso_id="constitutiva.id" :tipo="'constitutiva'" @submit.prevent
                @payCotizacionSpei="payCotizacionSpei($event)" />
            </form>
          </ValidationObserver>
        </b-tab>
        <b-tab title="Monedero" class="modalPay">
          <ValidationObserver v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(payCotizacion)">
              <Monedero :cotizacion_id="constitutiva.cotizaciones_id" :precio_total="constitutiva.precio_total"
                :clientes_id="constitutiva.clientes_id" :servicio="'Constitución de Sociedades ' + constitutiva.id"
                @submit.prevent @payCotizacion="payCotizacionMonedero($event)" />
            </form>
          </ValidationObserver>
        </b-tab>
      </b-tabs>
    </b-modal>

    <b-modal no-close-on-backdrop id="confirmar-facturacion-modal" ref="confirmar-facturacion-modal" centered
      hide-footer>
      <h4 class="text-center">¿Desea facturar su pago?</h4>
      <h5 class="text-center"></h5>
      <b-button class="mt-3" variant="danger" @click="confirmGenerarFactura(false)">No en este momento</b-button>
      <b-button class="mt-3 float-right" variant="primary" @click="confirmGenerarFactura(true)">Sí</b-button>
    </b-modal>

    <b-modal no-close-on-backdrop id="datos-fiscales-modal" ref="datos-fiscales-modal" size="lg" centered hide-footer
      hide-header @close.prevent>
      <FacturacionComponent :datosFacturacion="datosFacturacion" @closedFacturacionEvent="closedFacturacion($event)"
        @canceledFacturacionEvent="canceledFacturacion()" />
    </b-modal>

    <b-modal no-close-on-backdrop id="modal-help-constitutiva-1" size="xl" centered hide-footer>
      <div>
        <b-carousel id="carousel-constitutiva-1" :interval="0" controls indicators background="#ababab">
          <div v-if="currentComponentBody === 'DatosGenerales'">
            <b-carousel-slide :img-src="require('@/assets/img/constitutiva/help/1_1.webp')"></b-carousel-slide>
            <b-carousel-slide :img-src="require('@/assets/img/constitutiva/help/1_2.webp')"></b-carousel-slide>
            <b-carousel-slide :img-src="require('@/assets/img/constitutiva/help/1_3.webp')"></b-carousel-slide>
          </div>
          <div v-if="currentComponentBody === 'DatosCapital'">
            <b-carousel-slide :img-src="require('@/assets/img/constitutiva/help/2_1.webp')"></b-carousel-slide>
            <b-carousel-slide :img-src="require('@/assets/img/constitutiva/help/2_2.webp')"></b-carousel-slide>
          </div>
          <div v-if="currentComponentBody === 'DatosOrganos'">
            <b-carousel-slide :img-src="require('@/assets/img/constitutiva/help/3_1.webp')"></b-carousel-slide>
          </div>
          <div v-if="currentComponentBody === 'RepresentantesYApoderados'">
            <b-carousel-slide :img-src="require('@/assets/img/constitutiva/help/4_1.webp')"></b-carousel-slide>
          </div>
        </b-carousel>
      </div>
    </b-modal>

    <div class="container-fluid">
      <b-row>
        <b-col class="col-3">
          <b-card>
            <TreeM @node="selected" :constitutiva="constitutiva" :selected-component="currentComponentBody"
              :user="'Client'" />
          </b-card>
          <div class="d-flex justify-content-center">
            <b-card no-body v-if="currentComponentBody === 'DatosGenerales'"
              :img-src="require('@/assets/img/constitutiva/help/1.webp')" img-alt="Descripción de los datos generales"
              tag="article" style="max-width: 20rem;">
              <b-button class="orange-btn" @click="$bvModal.show('modal-help-constitutiva-1')">
                Más información
              </b-button>
            </b-card>
            <b-card no-body v-if="currentComponentBody === 'DatosCapital'"
              :img-src="require('@/assets/img/constitutiva/help/2.webp')" img-alt="Descripción de los datos del capital"
              tag="article" style="max-width: 20rem;">
              <b-button class="orange-btn" @click="$bvModal.show('modal-help-constitutiva-1')">
                Más información
              </b-button>
            </b-card>
            <b-card no-body v-if="currentComponentBody === 'DatosOrganos'"
              :img-src="require('@/assets/img/constitutiva/help/3.webp')"
              img-alt="Descripción de los órganos corporativos" tag="article" style="max-width: 20rem;">
              <b-button class="orange-btn" @click="$bvModal.show('modal-help-constitutiva-1')">
                Más información
              </b-button>
            </b-card>
            <b-card no-body v-if="currentComponentBody === 'RepresentantesYApoderados'"
              :img-src="require('@/assets/img/constitutiva/help/4.webp')"
              img-alt="Descripción de los representantes y apoderados" tag="article" style="max-width: 20rem;">
              <b-button class="orange-btn" @click="$bvModal.show('modal-help-constitutiva-1')">
                Más información
              </b-button>
            </b-card>
            <b-card no-body v-if="currentComponentBody === 'CapitulosEspeciales'"
              :img-src="require('@/assets/img/constitutiva/help/5.webp')"
              img-alt="Descripción de los capítulos especiales" tag="article" style="max-width: 20rem;">
            </b-card>
          </div>
        </b-col>
        <b-col class="col-9">
          <card>
            <b-row>
              <component v-bind:is="currentComponentInfoHead" class="tab"></component>
            </b-row>
            <b-row>
              <b-col class="col-12">
                <!-- <component
                v-bind:is="currentComponentBody"
                class="tab"
                @node="selected"
              >
              </component> -->
                <div v-if="currentComponentBody === 'DatosGenerales'">
                  <ValidationObserver v-slot="{ handleSubmit }">
                    <form @submit.prevent="handleSubmit(saveConstitucion)">
                      <DatosGenerales :constitutiva="constitutiva" @submit.prevent @node="selected"
                        :optionsObjetoSocial="optionsObjetoSocial" />
                    </form>
                  </ValidationObserver>
                </div>
                <div v-if="currentComponentBody === 'DatosCapital'">
                  <ValidationObserver v-slot="{ handleSubmit }">
                    <form @submit.prevent="handleSubmit(saveConstitucion)">
                      <DatosCapital :constitutiva="constitutiva" @submit.prevent @node="selected" />
                    </form>
                  </ValidationObserver>
                </div>
                <div v-if="currentComponentBody === 'DatosOrganos'">
                  <ValidationObserver v-slot="{ handleSubmit }">
                    <form @submit.prevent="handleSubmit(saveConstitucion)">
                      <DatosOrganos :constitutiva="constitutiva" @submit.prevent @node="selected" />
                    </form>
                  </ValidationObserver>
                </div>
                <div v-if="currentComponentBody === 'RepresentantesYApoderados'">
                  <ValidationObserver v-slot="{ handleSubmit }">
                    <form @submit.prevent="handleSubmit(saveConstitucion)">
                      <RepresentantesYApoderados :constitutiva="constitutiva" @submit.prevent @node="selected"
                        :cat_facultades="cat_facultades" />
                    </form>
                  </ValidationObserver>
                </div>
                <div v-if="currentComponentBody === 'EntidadFederativa'">
                  <ValidationObserver v-slot="{ handleSubmit }">
                    <form @submit.prevent="handleSubmit(saveConstitucion)">
                      <EntidadFederativa :constitutiva="constitutiva" :catCorredurias="catCorredurias" @submit.prevent
                        @node="selected" />
                    </form>
                  </ValidationObserver>
                </div>
                <div v-if="currentComponentBody === 'CapitulosEspeciales'">
                  <ValidationObserver v-slot="{ handleSubmit }">
                    <form @submit.prevent="handleSubmit(saveConstitucion)">
                      <CapitulosEspeciales :constitutiva="constitutiva" @submit.prevent @node="selected" />
                    </form>
                  </ValidationObserver>
                </div>
                <div v-if="currentComponentBody === 'Cotizacion'">
                  <ValidationObserver v-slot="{ handleSubmit }">
                    <form @submit.prevent="handleSubmit(pagar)">
                      <Cotizacion :constitutiva="constitutiva" @submit.prevent @node="selected"
                        :cat_gastos="cat_gastos" />
                    </form>
                  </ValidationObserver>
                </div>
                <div v-if="currentComponentBody === 'ComplementaInformacion'">
                  <ValidationObserver v-slot="{ handleSubmit }">
                    <form @submit.prevent="handleSubmit(saveConstitucion)">
                      <ComplementaInformacion :constitutiva="constitutiva" @submit.prevent @node="selected"
                        :cat_facultades="cat_facultades" />
                    </form>
                  </ValidationObserver>
                </div>
                <div v-if="currentComponentBody === 'FirmaDigital'">
                  <ValidationObserver v-slot="{ handleSubmit }">
                    <form @submit.prevent="handleSubmit(saveConstitucion)">
                      <FirmaDigital :constitutiva="constitutiva" @submit.prevent @node="selected"
                        :cat_facultades="cat_facultades" />
                    </form>
                  </ValidationObserver>
                </div>
                <div v-if="currentComponentBody === 'EntregaDocumentos'">
                  <ValidationObserver v-slot="{ handleSubmit }">
                    <form @submit.prevent="handleSubmit(saveConstitucion)">
                      <EntregaDocumentos :constitutiva="constitutiva" @submit.prevent @node="selected" />
                    </form>
                  </ValidationObserver>
                </div>
                <div v-if="currentComponentBody === 'CitaFirma'">
                  <ValidationObserver v-slot="{ handleSubmit }">
                    <form @submit.prevent="handleSubmit(saveConstitucion)">
                      <CitaFirma :constitutiva="constitutiva" @submit.prevent @node="selected" />
                    </form>
                  </ValidationObserver>
                </div>
                <div v-if="currentComponentBody === 'DocListo'">
                  <!-- <ValidationObserver v-slot="{ handleSubmit }">
                  <form @submit.prevent="handleSubmit(saveConstitucion)"> -->
                  <DocListo :constitutiva="constitutiva" @submit.prevent @node="selected" />
                  <!-- </form>
                </ValidationObserver> -->
                </div>
              </b-col>
            </b-row>
          </card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import TreeM from "@/views/clients/procesos/constitucion/TreeM.vue";
import CitaFirma from "@/views/clients/procesos/constitucion/citafirma/CitaFirma.vue";
import ComplementaInformacion from "@/views/clients/procesos/constitucion/complementainformacion/ComplementaInformacion.vue";
import Cotizacion from "@/views/clients/procesos/constitucion/cotizacion/Cotizacion.vue";
import DocListo from "@/views/clients/procesos/constitucion/doclisto/DocListo.vue";
import EntregaDocumentos from "@/views/clients/procesos/constitucion/entregadocumentos/EntregaDocumentos.vue";
import FirmaDigital from "@/views/clients/procesos/constitucion/firmadigital/FirmaDigital.vue";
import CapitulosEspeciales from "@/views/clients/procesos/constitucion/infoBase/CapitulosEspeciales.vue";
import DatosCapital from "@/views/clients/procesos/constitucion/infoBase/DatosCapital.vue";
import DatosGenerales from "@/views/clients/procesos/constitucion/infoBase/DatosGenerales.vue";
import DatosOrganos from "@/views/clients/procesos/constitucion/infoBase/DatosOrganos.vue";
import EntidadFederativa from "@/views/clients/procesos/constitucion/infoBase/EntidadFederativa.vue";
import InfoBase from "@/views/clients/procesos/constitucion/infoBase/InfoBase.vue";
import RepresentantesYApoderados from "@/views/clients/procesos/constitucion/infoBase/RepresentantesYApoderados.vue";
import FacturacionComponent from "@/views/openpay/FacturacionComponent.vue";
import Monedero from "@/views/openpay/Monedero.vue";
import Openpay from "@/views/openpay/Openpay.vue";
import Spei from "@/views/openpay/Spei.vue";

import { CatFacultadesApi, CatFormulariosConstitucionSociedadesApi, CatGastosApi, CatObjetosSocialesApi, ClientesApi, ConstitucionSociedadesApi, CotizacionesApi, FuncionesApi } from "@/api";
import Storage from "@/utils/storage";

const storage = new Storage();

export default {
  name: "AddConstitucionSociedades",

  components: {
    // TreeNavigation,
    TreeM,
    InfoBase,
    DatosGenerales,
    DatosCapital,
    DatosOrganos,
    RepresentantesYApoderados,
    EntidadFederativa,
    CapitulosEspeciales,
    Cotizacion,

    EntregaDocumentos,
    CitaFirma,
    DocListo,
    ComplementaInformacion,
    // ModalPago,
    Openpay,
    Spei,
    Monedero,
    FirmaDigital,
    FacturacionComponent
  },

  created() {
    this.getCorredurias();
    this.getCatObjetosSociales();

    this.getFacultades();
    this.getGastos();
  },

  beforeRouteLeave(to, from, next) {
    if (to.name == "EditComparecientes") {
      this.next = next;
      this.beforeLeave(true);
    } else {
      this.$refs["modal-confirm-dialog-route"].show();
      this.next = next;
    }
  },

  data: () => ({
    next: null,
    prenode: null,

    loading: false,
    currentComponentInfoHead: "InfoBase",
    currentComponentBody: "DatosGenerales", // "DatosGenerales",
    cat_facultades: [],
    cat_gastos: [],
    optionsObjetoSocial: [],

    catCorredurias: [],

    constitutiva: {
      cambios: [],

      disabled: null,
      disabledComplementa: false,
      disabledDocumentos: false,
      disabledCita: false,

      validoGenerales: false,
      validoCapital: false,
      validoOrganos: false,
      validoRepresentantes: false,
      validoCapitulos: false,
      validoEntidad: false,
      validoCotizacion: false,
      validoComplementa: false,
      validoEntrega: false, //
      validoDocListo: false,

      accion: "nuevo",

      id: null,
      clientes_id: null,
      tipo_societario: null,
      denominaciones_sociales_1: null,
      denominaciones_sociales_2: null,
      denominaciones_sociales_3: null,
      tiene_capital_variable: false,
      autoriza_cd_denominacion: null,
      objeto_social: null,
      objeto_social_otro: null,
      objeto_actividad: null,

      capital_fijo: null,
      capital_variable: null,
      accionistas: [],
      capital_extranjero: null,

      tipo_administracion: null,
      administradores: [],
      presidente_voto: null,
      vigilancia: [],

      apoderados: [],
      cotizacion: {},
      link_calendario: null,

      corredurias_id: null,
      firma_oficina: true,
      selectedEntidad: null,
      selectedMunicipio: null,
      selectedCorreduria: null,
      optionsEntidades: [],
      optionsMunicipios: [],
      optionsColonias: [],

      reglas_control: null,
      reglas_asambleas: null,

      titulos_acciones: null,
      libros_corporativos: null,
      cotizaciones_id: null,

      cupones_id: null,
      cupones_referencia_id: null,
      precio_total: 0, //para guardarlo, convierte a float
      pagado: null,
      folio_transaccion: null,
      gastos: [],
      subtotal_titulos: 0,
      subtotal_libros: 0,

      documentos: [],
      formularios: [],

      direccion_firma: null, //estos dos es hasta despues del pago y de haber firmado los docs firtuales fad
      fecha_firma: null,
      clausula_extranjeros: null,
      acciones_valor_nominal: true,
      exclusion_accionistas: null,

      domicilios: [],
      pais: null,
      entidad: null,
      municipio: null,
      folio: null,
      nombre_beneficiario_pld: null,
      nombre_beneficiario_cff: null
    },

    facturarPago: false,
    datosFacturacion: {
      cotizacionId: 0,
      alreadyInvoiced: false,
    },
  }),

  methods: {
    async getCorredurias() {
      try {
        const { value } = await FuncionesApi.getCorreduriasDomicilios({
          entidad: null,
          municipio: null
        });
        this.catCorredurias = value;

        value.forEach(element => {
          const exists = this.constitutiva.optionsEntidades.findIndex(x => x.text === element.entidad);

          if (exists == -1) {
            const obj = {
              text: element.entidad,
              value: element.entidad
            };
            this.constitutiva.optionsEntidades.push(obj);
          }
        });
      } catch (error) {
        console.log(error);
      }
    },

    async getCatObjetosSociales() {
      try {
        const { value } = await CatObjetosSocialesApi.list();

        value.forEach(element => {
          const obj = {
            text: element.objeto_social,
            value: element.objeto_social
          };

          this.optionsObjetoSocial.push(obj);
        });

        const last = { text: "Otro", value: "Otro" };

        this.optionsObjetoSocial.push(last);
      } catch (error) {
        console.log(error);
      }
    },

    async getFacultades() {
      try {
        this.loading = true;

        const { value } = await CatFacultadesApi.list({
          page: 0,
          registersPerPage: 0
        });
        this.cat_facultades = value;

      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    async getGastos() {
      try {
        this.loading = true;

        const { value } = await CatGastosApi.list({
          page: 0,
          registersPerPage: 0,
          cat_servicios_id: 1
        });

        this.cat_gastos = value;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    async saveConstitucion(isMonedero = false) {
      console.log(this.constitutiva.cotizacion.id);
      console.log(this.constitutiva.cotizaciones_id);

      this.loading = true;
      let saved = false;

      if (!this.constitutiva.id) {
        saved = await this.newConstitucion();
      } else {
        saved = await this.editConstitucion();
      }

      if (saved && this.currentComponentBody === "Cotizacion" && !isMonedero) {
        this.$refs['confirmar-facturacion-modal'].show();
        this.loading = false;

        return;
      }

      this.loading = false;

      if (!saved) {

        return;
      }

      const nodos = [
        "DatosGenerales",
        "DatosCapital",
        "DatosOrganos",
        "RepresentantesYApoderados",
        "CapitulosEspeciales",
        "EntidadFederativa",
        "Cotizacion",
        "ComplementaInformacion",
        "FirmaDigital",
        // "EntregaDocumentos",
        "CitaFirma"
        // "DocListo"
      ];

      const index = nodos.findIndex(o => o === this.currentComponentBody);
      let nextIndex = parseInt(index);
      let node = null;

      if (nextIndex < nodos.length - 1) {
        node = nodos[nextIndex + 1];
      } else {
        //a ver a donde envían después de completar esto
        node = "CitaFirma";
      }

      this.selected(node, true);
    },

    async newConstitucion() {
      try {
        const emailUser = storage.getItem("user");
        const { id } = await ClientesApi.clienteId(emailUser);
        this.constitutiva.clientes_id = id;

        let capita_var = 0;
        if (this.constitutiva.tiene_capital_variable === true) {
          capita_var = 1;
        }
        let autoriza_cd = 0;
        if (this.constitutiva.autoriza_cd_denominacion === true) {
          autoriza_cd = 1;
        }
        let objeto_social = this.constitutiva.objeto_social;
        if (this.constitutiva.objeto_social === "Otro") {
          objeto_social = this.constitutiva.objeto_social_otro;
        }

        const denominaciones_sociales = [];

        denominaciones_sociales.push({
          denominacion_social: this.constitutiva.denominaciones_sociales_1,
        });
        denominaciones_sociales.push({
          denominacion_social: this.constitutiva.denominaciones_sociales_2,
        });
        denominaciones_sociales.push({
          denominacion_social: this.constitutiva.denominaciones_sociales_3,
        });

        const data = {
          clientes_id: this.constitutiva.clientes_id,
          tipo_societario: this.constitutiva.tipo_societario,
          tiene_capital_variable: capita_var,
          autoriza_cd_denominacion: autoriza_cd,
          objeto_social: objeto_social,
          objeto_actividad: this.constitutiva.objeto_actividad,
          denominaciones_sociales: denominaciones_sociales,
          estatus_id: 1, //"Iniciado",
          corredurias_id: 1, //para cd
          formularios: [
            { formulario: "DatosGenerales", estatus: 1, guardado: 1 },
            { formulario: "DatosCapital", estatus: 1 }
          ]
        };

        const constitucionSociedadesResponse = await ConstitucionSociedadesApi.create(data);

        // Se juntan las propiedades del objeto en memoria con el objeto regresado del back
        // this.constitutiva = {
        //   ...this.constitutiva,
        //   ...constitucionSociedadesResponse
        // }

        // this.constitutiva.accionistas.length = 0;

        this.constitutiva.id = constitucionSociedadesResponse.id;
        this.constitutiva.cotizacion = constitucionSociedadesResponse.cotizacion;
        this.constitutiva.cotizaciones_id = constitucionSociedadesResponse.cotizacion.id;

        this.datosFacturacion.cotizacionId = constitucionSociedadesResponse.cotizacion.id;
        this.constitutiva.validoGenerales = true;

        this.$toast.success("Información guardada", {
          timeout: 4000, //false,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true
        });
        // this.loading = false;
        return true;
      } catch (error) {
        console.log(error);
        this.$toast.error("Sucedió un error, intente más tarde", {
          timeout: 4000, //false,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true
        });
        // this.loading = false;
        return false;
      }
    },

    pagar() {
      this.$refs["pago-modal"].show();
    },

    async editConstitucion() {
      this.$refs["pago-modal"].hide();

      this.loading = true;

      let capita_var = 0;
      if (this.constitutiva.tiene_capital_variable === true) {
        capita_var = 1;
      }
      let autoriza_cd = 0;
      if (this.constitutiva.autoriza_cd_denominacion === true) {
        autoriza_cd = 1;
      }
      let objeto_social = this.constitutiva.objeto_social;
      if (this.constitutiva.objeto_social === "Otro") {
        objeto_social = this.constitutiva.objeto_social_otro;
      }

      const denominaciones_sociales = [];

      const obj = {};
      obj["denominacion_social"] = this.constitutiva.denominaciones_sociales_1;
      denominaciones_sociales.push(obj);
      const obj2 = {};
      obj2["denominacion_social"] = this.constitutiva.denominaciones_sociales_2;
      denominaciones_sociales.push(obj2);
      const obj3 = {};
      obj3["denominacion_social"] = this.constitutiva.denominaciones_sociales_3;
      denominaciones_sociales.push(obj3);

      const accionistasA = [];

      for (let i = 0; i < this.constitutiva.accionistas.length; i++) {
        let persona = "Física";
        if (this.constitutiva.accionistas[i].persona === "Persona Moral") {
          persona = "Moral";
        }

        //si tienen id ya existe compareciente
        if (this.constitutiva.accionistas[i].id) {
          const obj = {
            comparecientes_id: this.constitutiva.accionistas[i].id,
            porcentaje_capital: this.constitutiva.accionistas[i].porcentaje,
            constitucion_sociedades_id: this.constitutiva.accionistas[i].constitucion_sociedades_id,
            cat_servicio_id: this.constitutiva.accionistas[i].cat_servicio_id,
            servicio_id: this.constitutiva.accionistas[i].servicio_id
          };
          accionistasA.push(obj);
        } else {
          const obj = {
            cliente_id: this.constitutiva.clientes_id,
            nombre: this.constitutiva.accionistas[i].nombre,
            paterno: this.constitutiva.accionistas[i].paterno,
            materno: this.constitutiva.accionistas[i].materno,
            persona: persona,
            denominacion_social: this.constitutiva.accionistas[i].denominacion_social,
            porcentaje_capital: this.constitutiva.accionistas[i].porcentaje,
            visible: this.constitutiva.accionistas[i].visible
          };
          accionistasA.push(obj);
        }
      }

      let presidente_voto = 0;
      if (this.constitutiva.presidente_voto === true) {
        presidente_voto = 1;
      }

      let apoderados = [];
      let objA = {};
      for (let i = 0; i < this.constitutiva.apoderados.length; i++) {
        objA = {
          nombre: this.constitutiva.apoderados[i].nombre,
          paterno: this.constitutiva.apoderados[i].paterno,
          materno: this.constitutiva.apoderados[i].materno,
          rfc: this.constitutiva.apoderados[i].rfc
        };
        objA.facultades = [];
        // if (this.constitutiva.apoderados[i].facultades) {
        for (let j = 0; j < this.constitutiva.apoderados[i].facultades.length; j++) {
          let index = this.cat_facultades.findIndex(
            o => o.facultad === this.constitutiva.apoderados[i].facultades[j]
          );
          let objB = {};
          objB = { facultades_id: this.cat_facultades[index].id };
          objA.facultades.push(objB);
        }
        apoderados.push(objA);
        // }
      }

      let firma_oficina = 0;
      if (this.constitutiva.firma_oficina === true) {
        firma_oficina = 1;
      }

      let reglas_control = 0;
      if (this.constitutiva.reglas_control === true) {
        reglas_control = 1;
      }

      let reglas_asambleas = 0;
      if (this.constitutiva.reglas_asambleas === true) {
        reglas_asambleas = 1;
      }

      let capital_extranjero = 0;
      if (this.constitutiva.capital_extranjero === true) {
        capital_extranjero = 1;
      }

      const data = {
        clientes_id: this.constitutiva.clientes_id,
        activo: 1
      };

      if (this.currentComponentBody === "DatosGenerales") {
        // data["estatus"] = "Iniciado";
        data["tipo_societario"] = this.constitutiva.tipo_societario;
        data["tiene_capital_variable"] = capita_var;
        data["autoriza_cd_denominacion"] = autoriza_cd;
        data["objeto_social"] = objeto_social;
        data["objeto_actividad"] = this.constitutiva.objeto_actividad;
        data["denominaciones_sociales"] = denominaciones_sociales;
        data["formularios"] = [
          { formulario: "DatosGenerales", estatus: 1, guardado: 1 }
        ];
      }
      if (this.currentComponentBody === "DatosCapital") {
        // data["estatus"] = "Iniciado";
        data["capital_fijo"] = this.constitutiva.capital_fijo;
        data["capital_variable"] = this.constitutiva.capital_variable;
        data["accionistas"] = accionistasA;
        data["capital_extranjero"] = capital_extranjero;
        data["formularios"] = [
          { formulario: "DatosOrganos", estatus: 1 },
          { formulario: "DatosCapital", estatus: 1, guardado: 1 }
        ];
      }
      if (this.currentComponentBody === "DatosOrganos") {
        // data["estatus"] = "Iniciado";
        data["tipo_administracion"] = this.constitutiva.tipo_administracion;
        data["presidente_voto"] = presidente_voto;
        data["administradores"] = this.constitutiva.administradores;
        data["vigilancia"] = this.constitutiva.vigilancia;
        data["formularios"] = [
          { formulario: "RepresentantesYApoderados", estatus: 1 },
          { formulario: "DatosOrganos", estatus: 1, guardado: 1 }
        ];
      }
      if (this.currentComponentBody === "RepresentantesYApoderados") {
        // data["estatus"] = "Iniciado";
        data["apoderados"] = apoderados;
        data["beneficiario_pld"] = this.constitutiva.beneficiario_pld;
        data["beneficiario_cff"] = this.constitutiva.beneficiario_cff;
        data["formularios"] = [
          { formulario: "CapitulosEspeciales", estatus: 1 },
          { formulario: "RepresentantesYApoderados", estatus: 1, guardado: 1 }
        ];
        data.nombre_beneficiario_pld = this.constitutiva.nombre_beneficiario_pld;
        data.nombre_beneficiario_cff = this.constitutiva.nombre_beneficiario_cff;

      }
      if (this.currentComponentBody === "CapitulosEspeciales") {
        // data["estatus"] = "Iniciado";
        data["reglas_control"] = reglas_control;
        data["reglas_asambleas"] = reglas_asambleas;
        data["formularios"] = [
          { formulario: "EntidadFederativa", estatus: 1 },
          { formulario: "CapitulosEspeciales", estatus: 1, guardado: 1 }
        ];

        let exclusion_accionistas = 0;
        if (this.constitutiva.exclusion_accionistas === true) {
          exclusion_accionistas = 1;
        }
        data["exclusion_accionistas"] = exclusion_accionistas;
      }
      if (this.currentComponentBody === "EntidadFederativa") {
        // data["corredores"] = this.constitutiva.corredores;
        // data["corredurias_id"] = this.constitutiva.corredurias_id;
        data["firma_oficina"] = firma_oficina;
        data["entidad"] = this.constitutiva.selectedEntidad;
        data["delegacion_municipio"] = this.constitutiva.selectedMunicipio;
        data["formularios"] = [
          { formulario: "Cotizacion", estatus: 1 },
          { formulario: "EntidadFederativa", estatus: 1, guardado: 1 }
        ];
      }

      if (this.currentComponentBody === "Cotizacion") {
        data["estatus_id"] = "2"; //cuando paguen
        this.constitutiva.disabled = true;

        let titulos_acciones = 0;
        if (this.constitutiva.titulos_acciones === true) {
          titulos_acciones = 1;
        }
        data["titulos_acciones "] = titulos_acciones;

        let libros_corporativos = 0;
        if (this.constitutiva.libros_corporativos === true) {
          libros_corporativos = 1;
        }
        data["libros_corporativos"] = libros_corporativos;

        // const _cotizacion = CotizacionesApi.detail(this.constitutiva.cotizacion.id);

        // let objCoti = this.constitutiva.cotizacion;

        // let objCoti = {
        //   id: this.constitutiva.cotizacion.id,
        //   cupones_id: this.constitutiva.cotizacion.cupones_id,
        //   precio_total: this.constitutiva.precio_total,
        //   pagado: this.constitutiva.pagado
        //   // folio_transaccion: this.constitutiva.folio_transaccion
        // };

        // data["cotizacion"] = _cotizacion;

        let gastos = [];
        for (let j = 0; j < this.constitutiva.gastos.length; j++) {
          let objGastos = {
            cat_gastos_id: this.constitutiva.gastos[j].id,
            costo: this.constitutiva.gastos[j].costo
          };
          gastos.push(objGastos);
        }
        data["gastos"] = gastos;
        data["formularios"] = [
          { formulario: "ComplementaInformacion", estatus: 1 },
          { formulario: "Cotizacion", estatus: 1, guardado: 1 }
        ];
      }

      if (this.currentComponentBody === "ComplementaInformacion") {
        data["estatus_id"] = "3";
        data["administradores"] = this.constitutiva.administradores;
        data["vigilancia"] = this.constitutiva.vigilancia;
        data["apoderados"] = apoderados;
        data["formularios"] = [
          // { formulario: "EntregaDocumentos", estatus: 1 },
          { formulario: "FirmaDigital", estatus: 1 },
          { formulario: "ComplementaInformacion", estatus: 1, guardado: 1 }
        ];
        let clausula_extranjeros = 0;
        if (this.constitutiva.clausula_extranjeros === true) {
          clausula_extranjeros = 1;
        }
        data["clausula_extranjeros"] = clausula_extranjeros;

        let acciones_valor_nominal = 0;
        if (this.constitutiva.acciones_valor_nominal === true) {
          acciones_valor_nominal = 1;
        }
        data["acciones_valor_nominal"] = acciones_valor_nominal;

        let domicilios = [];
        let domicilio = {
          pais: this.constitutiva.pais,
          entidad: this.constitutiva.entidad,
          delegacion_municipio: this.constitutiva.municipio
        };
        domicilios.push(domicilio);
        data["domicilios"] = domicilios;

        storage.removeItem("constitutiva.entidadDomicilio");
        storage.removeItem("constitutiva.municipioDomicilio");
      }
      if (this.currentComponentBody === "EntregaDocumentos") {
        data["estatus_id"] = "3";

        var documentos = JSON.parse(
          JSON.stringify(this.constitutiva.documentos)
        );
        // for (let u = 0; u < documentos.length; u++) {
        //   if (documentos[u].tipo === "Otro") {
        //     documentos[u].tipo = documentos[u].tipoOtro;
        //   }
        // }
        data["documentos"] = documentos;
        data["formularios"] = [
          { formulario: "CitaFirma", estatus: 1 },
          { formulario: "EntregaDocumentos", estatus: 1, guardado: 1 }
        ];
      }
      if (this.currentComponentBody === "CitaFirma") {
        // data["estatus"] = "Pagado"; //??
        data["direccion_firma"] = this.constitutiva.direccion_firma;
        data["fecha_firma"] = this.constitutiva.fecha_firma;
        data["formularios"] = [
          { formulario: "DocListo", estatus: 1 },
          { formulario: "CitaFirma", estatus: 1, guardado: 1 }
        ];
      }
      // if (this.currentComponentBody === "DocListo") {
      // data["estatus"] = "Pagado";//??
      // }

      try {
        // Trae la cotizacion
        const _cotizacion = await CotizacionesApi.detail(this.constitutiva.cotizaciones_id);

        data.cotizacion = _cotizacion;

        const { accionistas } = await ConstitucionSociedadesApi.edit(this.constitutiva.id, data);

        if (accionistas != null && accionistas.length) {
          for (let i = 0; i < accionistas.length; i++) {
            this.constitutiva.accionistas[i] = {
              ...this.constitutiva.accionistas[i],
              ...accionistas[i],
              comparecientes_id: accionistas[i].id
            }
            // let newId = accionistas[i].id;
            // this.constitutiva.accionistas[i].id = newId;
            // const a = Object.assign({}, this.constitutiva.accionistas[i]);
            // this.constitutiva.accionistas.splice([i], 1, a);
          }
          // this.constitutiva.accionistas = accionistas;
        }

        if (this.currentComponentBody === "DatosGenerales") {
          this.constitutiva.validoGenerales = true;
        }
        if (this.currentComponentBody === "DatosCapital") {
          this.constitutiva.validoCapital = true;
        }
        if (this.currentComponentBody === "DatosOrganos") {
          this.constitutiva.validoOrganos = true;
        }
        if (this.currentComponentBody === "RepresentantesYApoderados") {
          this.constitutiva.validoRepresentantes = true;
        }
        if (this.currentComponentBody === "CapitulosEspeciales") {
          this.constitutiva.validoCapitulos = true;
        }
        if (this.currentComponentBody === "EntidadFederativa") {
          this.constitutiva.validoEntidad = true;
        }
        if (this.currentComponentBody === "Cotizacion") {
          this.constitutiva.validoCotizacion = true;
        }
        if (this.currentComponentBody === "ComplementaInformacion") {
          this.constitutiva.disabledComplementa = true;
          this.constitutiva.validoComplementa = true;
        }
        if (this.currentComponentBody === "EntregaDocumentos") {
          this.constitutiva.disabledEntrega = true;
          this.constitutiva.validoEntrega = true;
        }
        if (this.currentComponentBody === "CitaFirma") {
          this.constitutiva.disabledCita = true;
          this.constitutiva.constitutiva.validoCita = true;
        }

        this.$toast.success("Información guardada", {
          timeout: 4000, //false,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true
        });

        return true;
      } catch (error) {
        console.log(error);
        this.$toast.error("Sucedió un error, intente más tarde", {
          timeout: 4000, //false,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true
        });

        this.loading = false;
        return false;
      }
    },

    async payCotizacion(payed) {
      if (payed == true) {
        this.saveConstitucion();
      }
    },

    async payCotizacionMonedero(payed) {
      if (payed == true) {
        this.saveConstitucion();
      }
    },

    async payCotizacionSpei(payed) {
      if (payed == true) {
        this.constitutiva.disabled = true;
        this.$refs["pago-modal"].hide();
        this.$toast.warning("Recibirá la información para la transferencia en su correo electrónico", {
          timeout: 10000, //false,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true
        });
      } else {
        this.$toast.error("Sucedió un error, intente más tarde", {
          timeout: 4000, //false,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true
        });
        this.loading = false;
      }
    },

    async beforeLeave(answer) {
      if (answer) {
        this.next();
      }
    },

    async selected(node, automatic) {
      this.loading = true;
      // let estatus = 1; //esto se borra y descomenta lo siguiente
      let constitutiva_id = 0;
      if (this.constitutiva.id) {
        constitutiva_id = this.constitutiva.id;
      }
      let estatus = "";
      if (node === "go") {
        estatus = await this.getEstatusForm(this.prenode, constitutiva_id);
      } else {
        estatus = await this.getEstatusForm(node, constitutiva_id);
      }

      if (estatus === 0) {
        this.$toast.warning(
          "Complete el formulario actual para poder continuar",
          {
            timeout: 6000, //false,
            hideProgressBar: false,
            position: "top-right",
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            closeButton: "button",
            icon: true
          }
        );
      } else {
        if (automatic === true) {
          this.$refs["modal-confirm-dialog"].hide();
          // node = this.prenode;
          this.currentComponentBody = node;
          if (
            node === "DatosGenerales" ||
            node === "DatosCapital" ||
            node === "DatosOrganos" ||
            node === "RepresentantesYApoderados" ||
            node === "CapitulosEspeciales" ||
            node === "EntidadFederativa"
          ) {
            this.currentComponentInfoHead = "InfoBase";
          }

          if (
            node === "CitaFirma" ||
            node === "DocListo" ||
            node === "InfoBase" ||
            node === "Cotizacion" ||
            node === "EntregaDocumentos" ||
            node === "ComplementaInformacion" ||
            node === "FirmaDigital"
          ) {
            this.currentComponentInfoHead = null;
          }
        } else {
          if (node != "go") {
            this.prenode = node;
          }
          if (node != "go") {
            this.$refs["modal-confirm-dialog"].show();
          } else {
            this.$refs["modal-confirm-dialog"].hide();
            node = this.prenode;

            this.currentComponentBody = node;
            if (
              node === "DatosGenerales" ||
              node === "DatosCapital" ||
              node === "DatosOrganos" ||
              node === "RepresentantesYApoderados" ||
              node === "CapitulosEspeciales" ||
              node === "EntidadFederativa"
            ) {
              this.currentComponentInfoHead = "InfoBase";
            }

            if (
              node === "CitaFirma" ||
              node === "DocListo" ||
              node === "InfoBase" ||
              node === "Cotizacion" ||
              node === "EntregaDocumentos" ||
              node === "ComplementaInformacion" ||
              node === "FirmaDigital"
            ) {
              this.currentComponentInfoHead = null;
            }
          }
        }
      }
      this.loading = false;
    },

    async getEstatusForm(formulario, consitucion_sociedades_id) {
      try {
        let estatus = await CatFormulariosConstitucionSociedadesApi.getEstatusForm(
          formulario,
          consitucion_sociedades_id
        );
        return estatus;
      } catch (error) {
        console.log(error);
      }
    },

    confirmGenerarFactura(crearFactura) {
      this.$refs['confirmar-facturacion-modal'].hide();

      if (!crearFactura) {
        const nodos = [
          "DatosGenerales",
          "DatosCapital",
          "DatosOrganos",
          "RepresentantesYApoderados",
          "CapitulosEspeciales",
          "EntidadFederativa",
          "Cotizacion",
          "ComplementaInformacion",
          "FirmaDigital",
          // "EntregaDocumentos",
          "CitaFirma"
          // "DocListo"
        ];

        const index = nodos.findIndex(o => o === this.currentComponentBody);
        let nextIndex = parseInt(index);
        let node = null;
        if (nextIndex < nodos.length - 1) {
          node = nodos[nextIndex + 1];
        } else {
          //a ver a donde envían después de completar esto
          node = "CitaFirma";
        }

        this.selected(node, true);

        return;
      }

      this.$refs["datos-fiscales-modal"].show();
    },

    closedFacturacion() {
      const nodos = [
        "DatosGenerales",
        "DatosCapital",
        "DatosOrganos",
        "RepresentantesYApoderados",
        "CapitulosEspeciales",
        "EntidadFederativa",
        "Cotizacion",
        "ComplementaInformacion",
        "FirmaDigital",
        // "EntregaDocumentos",
        "CitaFirma"
        // "DocListo"
      ];

      const index = nodos.findIndex(o => o === this.currentComponentBody);
      let nextIndex = parseInt(index);
      let node = null;
      if (nextIndex < nodos.length - 1) {
        node = nodos[nextIndex + 1];
      } else {
        //a ver a donde envían después de completar esto
        node = "CitaFirma";
      }

      this.selected(node, true);

      this.$refs['datos-fiscales-modal'].hide();
    },

    canceledFacturacion() {
      const nodos = [
        "DatosGenerales",
        "DatosCapital",
        "DatosOrganos",
        "RepresentantesYApoderados",
        "CapitulosEspeciales",
        "EntidadFederativa",
        "Cotizacion",
        "ComplementaInformacion",
        "FirmaDigital",
        // "EntregaDocumentos",
        "CitaFirma"
        // "DocListo"
      ];

      const index = nodos.findIndex(o => o === this.currentComponentBody);
      let nextIndex = parseInt(index);
      let node = null;
      if (nextIndex < nodos.length - 1) {
        node = nodos[nextIndex + 1];
      } else {
        //a ver a donde envían después de completar esto
        node = "CitaFirma";
      }

      this.selected(node, true);

      this.$refs['datos-fiscales-modal'].hide();
    }
  }
};
</script>

<style scoped>
/* .fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s;
}

.fade-enter,
  .fade-leave-to
    /* .fade-lea ve-active in <2.1.8  {
  opacity: 0;
} */
/* .navigation  {
  margin-right: 0px !important;
} */
</style>
