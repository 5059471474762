export function formatCurrency(value, locale = 'es-MX', currency = 'MXN') {
  // Check if the value is a string and attempt to parse it to a number
  if (typeof value === 'string') {
    value = parseFloat(value);
  }

  // Validate if the value is a finite number; return an empty string if not
  if (isNaN(value) || !isFinite(value)) {
    return '';
  }

  // Format the number using Intl.NumberFormat
  return new Intl.NumberFormat(locale, { style: 'currency', currency }).format(value);
}
