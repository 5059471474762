<template>
  <div>
    <div v-if="loading">
      <div id="loader">
        <font-awesome-icon icon="spinner" spin />
      </div>
    </div>

    <div class="row">
      <div class="col-md-4">
        <ValidationProvider name="nombre" rules="required" v-slot="{ errors }"
          :custom-messages="{ required: 'El nombre es obligatorio' }">
          <base-input type="text" label="Nombre(s)" placeholder="Nombre(s)" v-model="currentApoderado.nombre">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="col-md-4">
        <ValidationProvider name="paterno" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'El apellido paterno es obligatorio',
        }">
          <base-input type="text" label="Apellido Paterno" placeholder="Apellido Paterno"
            v-model="currentApoderado.paterno">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="col-md-4">
        <!-- <ValidationProvider
          name="lastNameM"
          rules="required"
          v-slot="{ errors }"
          :custom-messages="{
            required: 'El apellido materno es obligatorio',
          }"
        > -->
        <base-input type="text" label="Apellido Materno" placeholder="Apellido Materno"
          v-model="currentApoderado.materno">
        </base-input>
        <!-- <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider> -->
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <b-form-group label="Facultades">
          <b-form-checkbox-group id="checkbox-group-1" v-model="currentApoderado.facultades"
            :options="options_facultades" name="flavour-1"></b-form-checkbox-group>
        </b-form-group>
      </div>
    </div>

    <div class="row">
      <div class="col-md-4" v-if="complete">
        <ValidationProvider name="rfc" rules="required|length:13" v-slot="{ errors }" :custom-messages="{
          required: 'El RFC es obligatorio',
          length: 'Debe tener 13 caracteres',
        }">
          <base-input type="text" label="RFC" placeholder="RFC" v-model="currentApoderado.rfc">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>

    <div class="text-center">
      <button type="submit" class="btn btn-fill float-right orange-btn">
        Guardar
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    currentApoderado: { type: Object },
    options_facultades: { type: Array },
    complete: { type: Boolean },
  },

  data() {
    return {
      loading: false,

      form: {
        nombre: null,
        paterno: null,
        materno: null,
        rfc: null,
        facultades: [],
      },
    };
  },

  watch: {
    "currentApoderado.rfc": function (value) {
      if (!value) {
        this.currentApoderado.rfc = "";

        return;
      }

      this.currentApoderado.rfc = this.currentApoderado.rfc.toUpperCase();
    },
  },

  methods: {
    addFacultad() {
      // if(){ //si esta en true lo add , si esta en false lo quito
      // }
    },
  },
};
</script>
