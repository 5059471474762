<template>
  <div>

    <div class="row">
      <div class="col-md-4">
        <ValidationProvider name="nombre" rules="required" v-slot="{ errors }"
          :custom-messages="{ required: 'El nombre es obligatorio' }">
          <base-input type="text" label="Nombre(s)" placeholder="Nombre(s)" v-model="currentAdministrador.nombre">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="col-md-4">
        <ValidationProvider name="paterno" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'El apellido paterno es obligatorio'
        }">
          <base-input type="text" label="Apellido Paterno" placeholder="Apellido Paterno"
            v-model="currentAdministrador.paterno">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="col-md-4">
        <base-input type="text" label="Apellido Materno" placeholder="Apellido Materno"
          v-model="currentAdministrador.materno">
        </base-input>
      </div>
    </div>

    <div class="row">
      <!-- v-if="complete" -->
    </div>

    <div class="row">
      <div class="col-md-5">
        <!--  pq puede ser 12-->
        <ValidationProvider name="rfc" rules="required|length:13" v-slot="{ errors }" :custom-messages="{
          required: 'El RFC es obligatorio',
          length: 'Debe tener 13 caracteres'
        }">
          <base-input type="text" label="RFC" placeholder="RFC" v-model="currentAdministrador.rfc">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="col-md-6">
        <ValidationProvider name="porcentaje" rules="required" v-slot="{ errors }"
          :custom-messages="{ required: 'El cargo es obligatorio' }">
          <label>Cargo</label>
          <select v-model="currentAdministrador.cargo" class="custom-select">
            <option value="Administrador único">Administrador único</option>
            <option value="Miembro del consejo">Miembro del consejo</option>
            <option value="Apoderado">Apoderado</option>
          </select>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>

    <div class="text-center">
      <button type="submit" class="btn btn-fill float-right orange-btn">
        Guardar
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModalAdmins",

  props: {
    currentAdministrador: { type: Object },
    complete: { type: Boolean }
  },

  watch: {
    "currentAdministrador.rfc": function (value) {
      if (!value) {
        this.currentAdministrador.rfc = "";

        return;
      }

      this.currentAdministrador.rfc = this.currentAdministrador.rfc.toUpperCase();
    },
  },
};
</script>
