import { render, staticRenderFns } from "./Actas.vue?vue&type=template&id=5c3a4159&scoped=true"
import script from "./Actas.vue?vue&type=script&lang=js"
export * from "./Actas.vue?vue&type=script&lang=js"
import style0 from "./Actas.vue?vue&type=style&index=0&id=5c3a4159&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c3a4159",
  null
  
)

export default component.exports